import React, { useEffect, useState } from "react";
import { Tree, message, Typography, Modal } from "antd";
import { DownOutlined, DeleteOutlined } from "@ant-design/icons";
import hierarchyService from "../services/hierarchyService";
import SpinLoading from "../components/MyOrderActions/SpinLoading";
import { catchError } from "../utils/errorHandler";
import axiosInstance from "../services/axiosInstance";

const { confirm } = Modal;

const UserHeirachyTeam = ({ postSuccess }) => {
  const [hierarchyData, setHierarchyData] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [loading, setLoading] = useState(true);

  const transformHierarchyData = (data, path = "0") => ({
    title: data.user || "Unknown User",
    key: path,
    hierarchy_uid: data.hierarchy_uid,
    children: data.hierarchy
      ? data.hierarchy.map((child, index) =>
          transformHierarchyData(child, `${path}-${index}`)
        )
      : [],
  });

  const fetchHierarchy = async () => {
    setLoading(true);
    try {
      const result = await axiosInstance.get("/analytics/team/hierarchy/");
      if (result.data.status === "success") {
        setHierarchyData(result.data.data);
      } else {
        message.error("Failed to fetch hierarchy data");
      }
    } catch (error) {
      message.error("Error fetching hierarchy data");
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchHierarchy();
  }, [postSuccess]);

  const showDeleteConfirm = (nodeUid, user) => {
    confirm({
      title: `Are you sure you want to delete : ${user}`,
      // content: `UID: ${nodeUid}`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      okButtonProps: {
        loading: deleteLoading,
      },
      onOk() {
        handleDelete(nodeUid);
      },
    });
  };

  const handleDelete = async (uid) => {
    setDeleteLoading(true);
    if (!hierarchyData) return;

    try {
      const response = await axiosInstance.delete(
        `/useraccount/v2/team-hierarchy-delete/${uid}/`
      );
      if (response.status && response.data.status == "success") {
        message.success(response.data.data);
        fetchHierarchy();
      }
    } catch (err) {
      await catchError(err);
    } finally {
      setDeleteLoading(false);
    }
  };

  const titleRender = (nodeData) => (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      {/* {console.log("nodeData", nodeData)} */}
      <span>{nodeData.title}</span>
      {nodeData?.hierarchy_uid && (
        <DeleteOutlined
          style={{ color: "red", marginLeft: "10px", cursor: "pointer" }}
          onClick={() =>
            showDeleteConfirm(nodeData.hierarchy_uid, nodeData.title)
          }
        />
      )}
    </div>
  );

  if (loading) {
    return <SpinLoading />;
  }

  if (!hierarchyData) {
    return <p>No data available</p>;
  }

  const treeData = [transformHierarchyData(hierarchyData)];

  return (
    <div style={{ padding: "10px" }}>
      <Tree
        showLine
        switcherIcon={<DownOutlined />}
        treeData={treeData}
        titleRender={titleRender}
      />
    </div>
  );
};

export default UserHeirachyTeam;
