import React, { useState, useEffect } from "react";
import { Row, Col, Button, Input, Modal, Typography, Flex } from "antd";
import WalletTable from "./WalletTable";
import CreditToWallet from "./CreditToWallte";
import { useMediaQuery } from "react-responsive";
import DebitToWallet from "./DebitToWallet";
import paymentOperationsService from "../../services/paymentOperationsService";
import usePermissions from "../../hooks/usePermissions";
function WalletParent() {
  const { Title } = Typography;
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const [visibleModal, setVisibleModal] = useState(null); // Tracks which modal to show
  const { hasPermission } = usePermissions();

  // Handlers for opening modals
  const showCreditModal = () => setVisibleModal("credit");
  const showDebitModal = () => setVisibleModal("debit");
  const closeModal = () => setVisibleModal(null);
  const [walletData, setWalletData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [search, setSearch] = useState(""); // State for search input

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  useEffect(() => {
    fetchWalletData(pagination.current, pagination.pageSize);
  }, [pagination.current, pagination.pageSize]);

  // Fetch main wallet data
  const fetchWalletData = async (page, pageSize, searchValue) => {
    setLoading(true);

    const response = await paymentOperationsService.getWalletList(
      page,
      pageSize,
      { search: searchValue }
    );
    setLoading(false);

    if (response.success) {
      setWalletData(response?.data?.data?.results || []);
      setPagination((prev) => ({
        ...prev,
        total: response.data.data.count,
      }));
    }
  };
  const handleSearch = (value) => {
    setSearch(value);
    setPagination((prev) => ({ ...prev, current: 1 })); // Reset to page 1 when searching
    fetchWalletData(1, pagination.pageSize, value); // Reset to page 1 for search
  };
  return (
    <div style={{ padding: "16px" }}>
      {/* Top Row with Search and Buttons */}
      <Flex dir={isMobile ? "flex-column" : "row"} justify={isMobile ? "end" :"center"} gap={isMobile ? 0 :10} style={{ padding: "10px 0" , justifyContent: isMobile ?"flex-end" : "flex-end" , flexDirection:isMobile ? "column" : "row" , alignItems:isMobile ? "flex-end" : "center" }}>
        <Col>
          <Input.Search
            placeholder="Search by username or email"
            style={{ width: 300 }}
            onSearch={handleSearch}
            allowClear
          />{" "}
        </Col>
      
      <Flex align="center" gap={6} style={{  marginTop:isMobile ? 16:0 ,}}>
      {hasPermission("can_debit_wallet") && (
          <Col>
            <Button
              type="primary"
              style={{ background: "#040724" }}
              onClick={showDebitModal}>
              Debit
            </Button>
          </Col>
        )}
        {hasPermission("can_credit_wallet") && (
          <Col>
            <Button
              type="primary"
              style={{ background: "#040724" }}
              onClick={showCreditModal}>
              Credit
            </Button>
          </Col>
        )}
      </Flex>

     
      </Flex>

      {/* Wallet Table */}
      <WalletTable
        walletData={walletData}
        fetchWalletData={fetchWalletData}
        loading={loading}
        pagination={pagination}
        setPagination={setPagination}
        
      />

      {/* Modals for Credit and Debit */}
      <Modal
        className="custom-modal"
        title={
          <Flex justify="space-between" className="custom-modal-header">
            <Title
              level={3}
              style={{
                color: "white",
                margin: 0,
                position: "relative",
                marginLeft: "10px",
              }}>
              Credit To Wallet
            </Title>
            {/* <CloseOutlined
            style={{ fontSize: "16px", marginRight: "10px" }}
            onClick={handleModalClose}
          /> */}
          </Flex>
        }
        visible={visibleModal === "credit"}
        onCancel={closeModal}
        footer={null}>
        <CreditToWallet closeModal={closeModal} />
      </Modal>

      <Modal
        className="custom-modal"
        title={
          <Flex justify="space-between" className="custom-modal-header">
            <Title
              level={3}
              style={{
                color: "white",
                margin: 0,
                position: "relative",
                marginLeft: "10px",
              }}>
              Debit From Wallet
            </Title>
            {/* <CloseOutlined
           style={{ fontSize: "16px", marginRight: "10px" }}
           onClick={handleModalClose}
         /> */}
          </Flex>
        }
        visible={visibleModal === "debit"}
        onCancel={closeModal}
        footer={null}>
        <DebitToWallet closeModal={closeModal} />
      </Modal>
    </div>
  );
}

export default WalletParent;
