import React, { useEffect, useState } from "react";
import { Form, Row, Col, Typography, Select, Input, Button, Spin, message } from "antd";
import { MessageOutlined } from "@ant-design/icons";
import orderService from "../../services/OrderLists";
import axiosInstance from "../../services/axiosInstance";
import { catchError } from "../../utils/errorHandler";

const { TextArea } = Input;

const SmsModalOrderDetails = ({ orderKey, handleModalClose, }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [twilioNumbers, setTwilioNumbers] = useState([]);

    const [smsForm, setSmsForm] = useState({
        phone_no: "",
        message: ""
    });


    const fetchTwilioNumbers = async () => {
        const response = await orderService.getTwilioNumberAndUid();
        if (response.success) {
            setTwilioNumbers(response?.data);
        }
    };

    useEffect(() => {
        fetchTwilioNumbers()
    }, [])

    const handleSms = async (k) => {
        setLoading(true);
        try {
            await form.validateFields();
            const res = await axiosInstance.post(`tc/sms/task/${orderKey}/`, smsForm);

            if (res?.status && res?.data?.status) {
                form.resetFields();
                message.success(res.data.data);
                handleModalClose()
            } else {
                console.error("Unexpected API response format:", res);
            }
        } catch (err) {
            await catchError(err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={{ borderRadius: "10px", backgroundColor: "white" }}>
            <Form form={form} layout="vertical">

                <Form.Item
                    style={{ width: "100%" }}
                    name="uid"
                    label="Select Phone Number"
                    rules={[{ required: true, message: "Please select a phone number" }]}
                >
                    <Select
                        value={smsForm.phone_no || undefined}
                        onChange={(value) => setSmsForm({ ...smsForm, phone_no: value })}
                        placeholder="Select a phone number"
                        style={{ width: "100%" }}
                    >
                        {twilioNumbers?.map((numbers) => (
                            <Select.Option key={numbers?.phone_no} value={numbers?.phone_no}>
                                {numbers?.phone_no}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    style={{ width: "100%" }}
                    name="message"
                    label="Message"
                    rules={[{ required: true, message: "Please enter a message" }]}
                >
                    <TextArea
                        value={smsForm.message}
                        onChange={(e) => setSmsForm({ ...smsForm, message: e.target.value })}
                        placeholder="Enter your message"
                        rows={4}
                    />
                </Form.Item>
                <Form.Item
                    style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                    <Button
                        onClick={handleSms}
                        style={{ backgroundColor: "#36c6d3" }}
                        type="primary"
                        size="medium"
                    >
                        {loading ? <Spin /> : "SMS"}
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default SmsModalOrderDetails;
