import React, { useState } from "react";
import "./Table.css";
import {
  Table,
  Tag,
  Button,
  Checkbox,
  Tooltip,
  Flex,
  Spin,
  message,
  Badge,
} from "antd";
import usePermissions from "../../hooks/usePermissions";
import {
  EditOutlined,
  EyeFilled,
  FacebookFilled,
  LinkedinFilled,
  InstagramFilled,
  TwitterCircleFilled,
} from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";
import ManageLeadContent from "./ManageLeadContent";
import LeadMobileView from "./LeadMobileView";
import LeadsList from "../../services/LeadsList";
import { render } from "@testing-library/react";
import moment from "moment";
import { div } from "framer-motion/client";
const LeadsTable = ({
  data,
  loading,
  pagination,
  setPagination,
  fetchData,
  selectedUids,
  setSelectedUids,
  leadType,
}) => {
  const [loadingDetails, setLoadingDetails] = useState({}); // Use an object to track loading state per uid

  // const [loadingDetails, setLoadingDetails] = useState(false); // Track loading state for a specific record
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const [leadDetails, setLeadDetails] = useState({});

  const { hasPermission } = usePermissions();
  const [expandedInfoKeys, setExpandedInfoKeys] = useState([]);
  const [managedRowKey, setManagedRowKey] = useState(null);
  const onCheckboxChange = (uid) => {
    setSelectedUids((prevUids) =>
      prevUids.includes(uid)
        ? prevUids.filter((id) => id !== uid)
        : [...prevUids, uid]
    );
  };

  const handleInfoExpand = async (record) => {
 
    if (!record) return;

    // If the record is already expanded, just toggle it
    const isAlreadyExpanded = expandedInfoKeys.includes(record.key);

    // Fetch data only if not already expanded
    if (!isAlreadyExpanded) {
      await handleViewDetails(record);
    }

    // Toggle the expanded state
    const newExpandedInfoKeys = isAlreadyExpanded
      ? expandedInfoKeys.filter((key) => key !== record.key)
      : [record.key]; // Only expand one row at a time

    setExpandedInfoKeys(newExpandedInfoKeys);
    setManagedRowKey(null);
  };

  const handleManageExpand = (record) => {
    setManagedRowKey(managedRowKey === record.key ? null : record.key);
    setExpandedInfoKeys(expandedInfoKeys.filter((key) => key !== record.key));
  };

  const handleViewDetails = async (record) => {
    if (!record) return;

    // Set the loading state for the specific record
    setLoadingDetails((prevState) => ({
      ...prevState,
      [record.uid]: true,
    }));

    try {
      const result = await LeadsList.getLeadDetail(record.uid);
      if (result.success) {
        setLoadingDetails((prevState) => ({
          ...prevState,
          [record.uid]: false,
        }));

        // message.success("Data fetched successfully");
        setLeadDetails((prevDetails) => ({
          ...prevDetails,
          [record.uid]: result.data,
        }));
      } else {
        setLoadingDetails(false);

        message.error("Failed to fetch lead details");
      }
    } catch (error) {
      setLoadingDetails((prevState) => ({
        ...prevState,
        [record.uid]: false,
      }));
      console.error(error);
      message.error("An error occurred while fetching lead details");
    } finally {
      // Reset loading state after fetching
      // setLoadingDetails(false);
    }
  };

  const expandedInfoRowRender = (record) => {
    const details = leadDetails[record.uid]?.data || {};
    const { social_links, tags } = details;
    const socialLinks = social_links || {};
    const tagList = tags || [];
    const isLoading = loadingDetails[record.uid]; // Check loading state for the current record

    return (
      <Spin spinning={isLoading} tip="Loading details...">
        <div>
          <Table
            dataSource={[details]}
            columns={[
              {
                title: "Email",
                dataIndex: "email",
                key: "email",
                render: (text) => text || "No Email Available",
              },
              {
                title: "Phone Number",
                dataIndex: "phone_no",
                key: "phone_no",
                render: (text) => text || "No Phone Number",
              },
              {
                title: "Type",
                dataIndex: "type",
                key: "type",
                render: (text) => text || "No Type Specified",
              },
              {
                title: "Author",
                dataIndex: "author",
                key: "author",
                render: (text) => text || "No Author",
              },
              {
                title: "Social Links",
                key: "social_links",
                render: (_, record) => {
                  const socialLinksArray = record.social_links || [];

                  // Transform the array into an object for easier access
                  const socialLinks = socialLinksArray.reduce((acc, item) => {
                    const key = Object.keys(item)[0]; // Get the key (e.g., 'Facebook')
                    const value = item[key]; // Get the URL (e.g., 'https://facebook.com/user')
                    acc[key] = value; // Add the key-value pair to the accumulator
                    return acc;
                  }, {});

                  // console.log(socialLinks, "social links transformed"); // Log the transformed socialLinks object

                  // Check if there are any social links available
                  const hasAnyLink =
                    socialLinks.Facebook ||
                    socialLinks.LinkedIn ||
                    socialLinks.Instagram ||
                    socialLinks.Twitter;

                  return (
                    <div>
                      {!hasAnyLink && <span style={{ opacity: 0.6 }}>N/A</span>}

                      {socialLinks.Facebook && (
                        <Tooltip title="Facebook">
                          <a
                            href={socialLinks.Facebook}
                            target="_blank"
                            rel="noopener noreferrer">
                            <FacebookFilled
                              style={{ fontSize: "20px", marginRight: "8px" }}
                            />
                          </a>
                        </Tooltip>
                      )}
                      {socialLinks.LinkedIn && (
                        <Tooltip title="LinkedIn">
                          <a
                            href={socialLinks.LinkedIn}
                            target="_blank"
                            rel="noopener noreferrer">
                            <LinkedinFilled
                              style={{ fontSize: "20px", marginRight: "8px" }}
                            />
                          </a>
                        </Tooltip>
                      )}
                      {socialLinks.Instagram && (
                        <Tooltip title="Instagram">
                          <a
                            href={socialLinks.Instagram}
                            target="_blank"
                            rel="noopener noreferrer">
                            <InstagramFilled
                              style={{ fontSize: "20px", marginRight: "8px" }}
                            />
                          </a>
                        </Tooltip>
                      )}
                      {socialLinks.Twitter && (
                        <Tooltip title="Twitter">
                          <a
                            href={socialLinks.Twitter}
                            target="_blank"
                            rel="noopener noreferrer">
                            <TwitterCircleFilled
                              style={{ fontSize: "20px", marginRight: "8px" }}
                            />
                          </a>
                        </Tooltip>
                      )}
                    </div>
                  );
                },
              },
              {
                title: "Tags",
                key: "tags",
                render: (_, record) => {
                  const tagList = record.tags || [];
                  return tagList.length > 0 ? (
                    <div>
                      {tagList.map((tag, index) => (
                        <Tag color="geekblue" key={index}>
                          {tag}
                        </Tag>
                      ))}
                    </div>
                  ) : (
                    <span style={{ opacity: 0.6 }}>No Tags</span>
                  );
                },
              },
            ]}
            pagination={false}
            rowKey={(record) => record.uid} // Use unique identifier
          />
        </div>
      </Spin>
    );
  };

  const onSelectAllChange = (e) => {
    const isChecked = e.target.checked;
    const allUids = data.map((item) => item.uid);
    setSelectedUids(isChecked ? allUids : []);
  };

  const columns = [
    {
      title: (
        <Checkbox
          indeterminate={
            selectedUids.length > 0 && selectedUids.length < data.length
          }
          checked={selectedUids.length === data.length}
          onChange={onSelectAllChange}
        />
      ),
      dataIndex: "select",
      key: "select",
      render: (_, record) => (
        <Checkbox
          checked={selectedUids.includes(record.uid)}
          onChange={() => onCheckboxChange(record.uid)}
        />
      ),
    },
    {
      title: "Full Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <Flex align="flex-start" gap={4}>
          {/* Conditionally render EyeFilled if the user has permission */}
          {hasPermission("can_view_lead_details") && (
            <EyeFilled
              onClick={() => handleInfoExpand(record)}
              style={{ cursor: "pointer", marginTop: "6px", color: "black" }}
            />
          )}
          <div>
            <div style={{ margin: 0 }}>{text}</div>
          
          </div>
        </Flex>
      ),
    },
    {
      title: "Source",
      dataIndex: "source",
      key: "source",
      render: (text, record) =>
        text ? (
          <div>
            {" "}
            <Tag typeof="#008000" color="magenta">
              {text}
            </Tag>
            {record.created && (
              <div
                style={{
                  fontSize: "12px",
                  color: "gray",
                  opacity: 0.7,
                  marginTop: "4px",
                }}>
                {moment(record.created).local().format("DD-MM-YYYY hh:mm A")}
              </div>
            )}
          </div>
        ) : (
          "N/A"
        ),
    },
    {
      title: "Assignee Active Till",
      dataIndex: "activeTill",
      key: "activeTill",
      render: (text) => {
        if (!text) return "Not updated"; // If activeTill does not exist, show "N/A"
        return <div>{moment(text).local().format("YYYY-MM-DD hh:mm A")}</div>;
      },
    },
    {
      title: "Next Activity",
      dataIndex: "nextActivity",
      key: "nextActivity",
      render: (text) => {
        if (!text) return "Not updated"; // If nextActivity does not exist, show "No activity"
        return <div>{moment(text).local().format("YYYY-MM-DD hh:mm A")}</div>;
      },
    },
    {
      title: "Assignee",
      dataIndex: "assignee",
      key: "assignee",
      render: (text, record) => (
        <div style={{ display: "flex", flexDirection: "column", width: '100%' }}>
          <div style={{ display: "block" }}>{text || "Not updated"}</div>
          {record.assignedOn && (
            <div
              style={{
                fontSize: "12px",
                color: "gray",
                opacity: 0.7,
                display: "block", // Ensures block display for date
              }}
            >
              {moment(record.assignedOn).local().format("DD-MM-YYYY hh:mm A")}
            </div>
          )}
        </div>
      ),
    },
    {
      title: "Manager",
      dataIndex: "manager",
      key: "manager",
      render: (text, record) => (
        <div style={{ display: "flex", flexDirection: "column", width: '100%' }}>
          <div style={{ display: "block" }}>{text || "Not updated"}</div>
          {record.managerAssignedOn && (
            <div
              style={{
                fontSize: "12px",
                color: "gray",
                opacity: 0.7,
                display: "block", // Ensures block display for date
              }}
            >
              {moment(record.managerAssignedOn)
                .local()
                .format("DD-MM-YYYY hh:mm A")}
            </div>
          )}
        </div>
      ),
    },
    
    
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) =>
        text ? (
          <Tag color="green">{text}</Tag>
        ) : (
          <Tag color="red">No Status</Tag>
        ),
    },
    // Conditionally include the Manage column if the user has permission
    ...(hasPermission("can_manage_leads")
      ? [
          {
            title: "Manage",
            key: "edit",
            render: (_, record) => (
              <Button
                type="link"
                onClick={() => handleManageExpand(record)}
                icon={<EditOutlined style={{ color: "#040724" }} />}
              />
            ),
          },
        ]
      : []),
  ];

  return (
    <Flex vertical style={{ width: "100%" }} align="flex-start">
      {isMobile ? (
        <LeadMobileView
          data={data}
          leadDetails={leadDetails}
          loading={loading}
          selectedUids={selectedUids}
          setSelectedUids={setSelectedUids}
          leadType={leadType}
          fetchData={fetchData}
          pagination={pagination}
          setPagination={setPagination}
        />
      ) : (
        <Table
          style={{ width: "100%" }}
          columns={columns}
          dataSource={data}
          rowClassName={(record) =>
            expandedInfoKeys.includes(record.key) ||
            managedRowKey === record.key
              ? "expanded-row"
              : ""
          }
          expandable={{
            expandedRowRender: (record) =>
              managedRowKey === record.key ? (
                <ManageLeadContent
                  record={record}
                  fetchData={fetchData}
                  leadType={leadType}
                />
              ) : (
                expandedInfoRowRender(record)
              ),
            expandedRowKeys: [...new Set([...expandedInfoKeys, managedRowKey])], // Ensures unique keys
            onExpand: async (expanded, record) => {
              if (expanded && !leadDetails[record.uid]) {
                // Fetch details only if expanding and details are not already loaded
                await handleViewDetails(record);
              }

              if (managedRowKey === record.key) {
                // Reset managedRowKey if collapsing the managed row
                setManagedRowKey(expanded ? record.key : null);
              }

              handleInfoExpand(record); // Toggle the expansion state
            },
            expandIconColumnIndex: -1, // Moves the expand icon to the last column
          }}
          loading={loading}
          pagination={{
            current: pagination.current,
            pageSize: pagination.pageSize,
            total: pagination.total,
            showSizeChanger: false,
            pageSizeOptions: ["10", "20", "50", "100"],
            onChange: (page, pageSize) => {
              setPagination({ ...pagination, current: page, pageSize });
            },
            onShowSizeChange: (current, size) => {
              setPagination({ ...pagination, current: 1, pageSize: size });
            },
          }}
        />
      )}
    </Flex>
  );
};

export default LeadsTable;
