import React from "react";
import { Popover, Tooltip, Typography } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";

const LeadsAnalytics = ({ analytics }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const renderOutcomes = (outcomes) =>
    outcomes.map((outcome, index) => (
      <div key={index}>
        <strong>{outcome.outcome}</strong>: {outcome.count}
      </div>
    ));

  const getRelevantCount = (item) => {
    switch (item.type) {
      case "Call":
        return (
          item.outcomes.find((outcome) => outcome.outcome === "Connected")
            ?.count || 0
        );
      case "Whatsapp":
        return (
          item.outcomes.find((outcome) => outcome.outcome === "Message sent")
            ?.count || 0
        );
      case "Mail":
        return (
          item.outcomes.find((outcome) => outcome.outcome === "Mail sent")
            ?.count || 0
        );
      case "Social Media":
        return (
          item.outcomes.find((outcome) => outcome.outcome === "Connected")
            ?.count || 0
        );
      default:
        return 0;
    }
  };

  const getLabel = (type) => {
    switch (type) {
      case "Call":
        return "Calls Connected";
      case "Whatsapp":
        return "WhatsApp Sent";
      case "Mail":
        return "Emails Sent";
      case "Social Media":
        return "Social Connections";
      default:
        return "";
    }
  };
  

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        gap: isMobile ? "14px" : "20px",
        background: "rgb(241 245 249)",
        padding: "20px",
        borderRadius: "8px",
        justifyContent: isMobile ? "flex-start" : "flex-start",
      }}>
      {/* <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          background: "#eceff1",

          padding: "10px 12px",
          minWidth: isMobile ? "170px" : "230px",
          borderRadius: "4px",
        }}>
        <Typography.Title
          level={3}
          style={{ color: "green", margin: 0, padding: 0 }}>
          {analytics?.totalCallsConnected || 0}
        </Typography.Title>
        <Typography.Text style={{ margin: 0, padding: 0 }}>
          Calls Connected
        </Typography.Text>
      </div> */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          background: "#eceff1",

          padding: "10px 12px",
          minWidth: isMobile ? "170px" : "230px",
          borderRadius: "4px",
          gap: "4px",
        }}>
        <Typography.Title
          level={3}
          style={{ color: "green", margin: 0, padding: 0 }}>
          {analytics?.totalCallsMade || 0}
        </Typography.Title>
        <Typography.Text style={{ margin: 0, padding: 0 ,color:'#4b5563'}}>
          Calls Made
        </Typography.Text>
      </div>

      {analytics?.outcomeAnalytics?.map((item, index) => (
        <div
          key={index}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            background: "#eceff1",

            padding: "10px 12px",
            minWidth: isMobile ? "170px" : "230px",
            borderRadius: "4px",
            gap: "4px",
          }}>
          <Typography.Title
            level={3}
            style={{ color: "green", margin: 0, padding: 0 }}>
            {getRelevantCount(item)}
          </Typography.Title>
          <Typography.Text style={{ margin: 0, padding: 0 , color:'#4b5563'}}>
            {getLabel(item.type)}
          </Typography.Text>
          <Tooltip title={`View ${item.type} Details`}>
            <Popover
              content={renderOutcomes(item.outcomes)}
              title={`${item.type} Details`}
              trigger="hover">
              <EyeOutlined
                style={{
                  fontSize: "15px",
       
                  cursor: "pointer",
                  color: "#4b5563",
                }}
              />
            </Popover>
          </Tooltip>
        </div>
      ))}
    </div>
  );
};

export default LeadsAnalytics;
