import React, { useState } from "react";
import SubscriberDeductionsList from "./SubscriberDeductionList";
import SubscriberDeductions from "./SubscriberDeductions";
import { Button, Flex, Space } from "antd";

function SubscriberDeductionForm({ selectedRecord  , hideSubscriberDeductionModal}) {
  const [isListVisible, setIsListVisible] = useState(false);

  const toggleListVisibility = () => {
    setIsListVisible((prev) => !prev);
  };

  return (
    <Flex vertical direction="vertical" align="flex-end"  style={{ width: "100%" }}>
      <Button type="link" onClick={toggleListVisibility}>
        {isListVisible ? "Hide Deductions List" : "View Deductions List"}
      </Button>
      {isListVisible && <SubscriberDeductionsList selectedRecord={selectedRecord} />}
      <SubscriberDeductions selectedRecord={selectedRecord}  hideSubscriberDeductionModal={hideSubscriberDeductionModal}/>
    </Flex>
  );
}

export default SubscriberDeductionForm;
