import React, { useEffect, useState } from "react";
import { Input, Button, Select, DatePicker, Spin } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import useIsMobile from "../../hooks/useIsMobile";

import orderService from "../../services/OrderLists";

const { Option } = Select;

const SearchFilters = ({
  onSearch,
  pagination,
  loadOrders,
  statusList,
  filtersList,
  fetchingStatusList,
  subscribers,
  setSubscribers
}) => {
  const isMobile = useIsMobile();
  // const initialFilters = filtersList.reduce((acc, filter) => {
  //   acc[filter] = "";
  //   return acc;
  // }, {});



  const initialFilters = filtersList.reduce((acc, filter) => {
    acc[filter] = filter === "moved_to_fl" ? undefined : "";
    return acc;
  }, {});

  const [filters, setFilters] = useState(initialFilters);

  // const handleSearch = () => {
  //   const formattedFilters = Object.keys(filters).reduce((acc, key) => {
  //     if (
  //       filters[key] &&
  //       (key === "deadline_hard" ||
  //         key === "allotted_deadline" ||
  //         key === "client_deadline" ||
  //         filters[key]._isAMomentObject)
  //     ) {
  //       acc[key] = dayjs(filters[key]).format("YYYY-MM-DD");
  //     } else {
  //       acc[key] = filters[key];
  //     }
  //     return acc;
  //   }, {});

  //   onSearch(formattedFilters);
  // };
  const handleSearch = () => {
    const formattedFilters = Object.keys(filters).reduce((acc, key) => {
      if (filters[key]) {
        if (
          key === "deadline_hard" ||
          key === "allotted_deadline" ||
          key === "client_deadline" ||
          filters[key]._isAMomentObject
        ) {
          acc[key] = dayjs(filters[key]).format("YYYY-MM-DD");
        } else if (key === "subscriber" && filters[key].length > 0) {
          // Join subscribers as a comma-separated string
          acc[key] = filters[key].join(",");
        } else if (key !== "subscriber") {
          acc[key] = filters[key];
        }
      }
      return acc;
    }, {});
   
    onSearch(formattedFilters);
  };

  const handleFilterChange = (key, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));
  };

  const handleCancel = async () => {
    const resetFilters = Object.keys(filters).reduce((acc, filter) => {
      if (filter === "subscriber") {
        acc[filter] = []; // Explicitly reset to empty array
      } else {
        acc[filter] = filter === "moved_to_fl" ? undefined : "";
      }
      return acc;
    }, {});
  
    setFilters(resetFilters); // Reset all filters
    await loadOrders(); // Reload orders after resetting filters
  };
  

  const renderFilter = (filter) => {
    // Default filter configuration
    const defaultConfig = {
      component: Input,
      props: {
        placeholder: `Filter by ${filter
          .split("_")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ")}`,
        style: { width: isMobile ? "80vw" : 300 },
        value: filters[filter],
        onChange: (e) => handleFilterChange(filter, e.target.value),
      },
    };

    // Special case configurations
    const filterConfigs = {
      key: {
        component: Input,
        props: {
          placeholder: "Filter by Key",
          style: { width: isMobile ? "80vw" : 300 },
          value: filters.key,
          onChange: (e) => handleFilterChange("key", e.target.value),
        },
      },
      payment_status: {
        component: Select,
        props: {
          placeholder: "Payment Status",
          style: {
            width: isMobile ? "80vw" : 148,
            textAlign: "left",
            position: "relative",
            left: isMobile ? "0" : "50px",
          },
          value: filters.payment_status || undefined,
          onChange: (value) => handleFilterChange("payment_status", value),
        },
        options: [
          { value: "Paid", label: "Full" },
          { value: "Unpaid", label: "Unpaid" },
          { value: "Partial", label: "Partial" },
        ],
      },
      status: {
        component: Select,
        props: {
          placeholder: "Status",
          style: {
            width: isMobile ? "80vw" : 148,
            textAlign: "left",
          },
          value: filters.status || undefined,
          onChange: (value) => handleFilterChange("status", value),
          showSearch: true, // Enable search
          optionFilterProp: "children", // Filter options by their label
        },
        options: Object.entries(statusList || {}).map(([label, value]) => ({
          value: value.toString(),
          label,
        })),
      },
      title: {
        component: Input,
        props: {
          placeholder: "Filter by Title",
          style: { width: isMobile ? "80vw" : 300 },
          value: filters.title,
          onChange: (e) => handleFilterChange("title", e.target.value),
        },
      },
      deadline_hard: {
        component: DatePicker,
        props: {
          style: { width: isMobile ? "80vw" : 300 },
          placeholder: "Filter by Deadline",
          value: filters.deadline_hard,
          onChange: (value) => handleFilterChange("deadline_hard", value),
        },
      },
      allotted_deadline: {
        component: DatePicker,
        props: {
          style: { width: isMobile ? "80vw" : 300 },
          placeholder: "Filter by Allotted Deadline",
          value: filters.allotted_deadline,
          onChange: (value) => handleFilterChange("allotted_deadline", value),
        },
      },
      client_deadline: {
        component: DatePicker,
        props: {
          style: { width: isMobile ? "80vw" : 300 },
          placeholder: "Filter by Client Deadline",
          value: filters.client_deadline,
          onChange: (value) => handleFilterChange("client_deadline", value),
        },
      },
      subscriber: {
        component: Select,
        props: {
          mode: "multiple",
          placeholder: "Filter By Subscribers",
          style: { width: isMobile ? "80vw" : 300, textAlign: "left" },
          value: filters.subscriber || [], // Ensure this is never undefined
          onChange: (value) => handleFilterChange("subscriber", value),
          optionFilterProp: "children",
        },
        options: subscribers?.map((subscriber) => ({
          value: subscriber.id,
          label: subscriber.name,
        })),
      },
      
      moved_to_fl: {
        component: Select,
        props: {
          placeholder: "Moved to Fl",
          style: {
            width: isMobile ? "80vw" : 300,
            textAlign: "left",
            position: "relative",
            left: isMobile ? "0" : "-30px",
          },
          value: filters.moved_to_fl,
          onChange: (value) => handleFilterChange("moved_to_fl", value),
        },
        options: [
          { value: true, label: "Yes" },
          { value: false, label: "No" },
        ],
      },
    };

    // Get the configuration for this filter, or use default
    const config = filterConfigs[filter] || defaultConfig;
    const Component = config.component;

    // For Select components with options
    if (config.options) {
      return (
        <Component {...config.props}>
          {config.options.map(({ value, label }) => (
            <Option key={value} value={value}>
              {label}
            </Option>
          ))}
        </Component>
      );
    }

    // For other components
    return <Component {...config.props} />;
  };

  return (
    <div style={{ width: "100%" }}>
      <div
        style={{ background: "#eceff1", padding: "20px", borderRadius: "8px" }}>
        {fetchingStatusList ? (
          <Spin />
        ) : (
          <div
            style={{
              marginBottom: 16,
              display: "flex",
              flexWrap: "wrap",
              gap: "20px",
              justifyContent: "space-between",
              alignItems:'flex-end',
              width:'100%'
            }}>
              {console.log(filters.subscribers , '1234')}
            {filtersList.map((filter) => (
              <div key={filter} style={{ marginRight: 16 }}>
                {renderFilter(filter)}
              </div>
            ))}
            {console.log(filtersList , 'filterlist')}
            <div
              style={{
                display: "flex",
                gap: "20px",
                justifyContent: "flex-end",
                alignContent:'flex-end',
                alignItems:'flex-end',
                position: "relative",
                right: isMobile ? "0" : "10px",
                flexDirection: isMobile ? "column" : "row",
                width:'100%'
              }}>
              <Button
                type="primary"
                style={{
                  width: isMobile ? "80vw" : 140,
                  background: "#040724",
                }}
                icon={<SearchOutlined />}
                onClick={handleSearch}>
                Search
              </Button>
              <Button
                style={{ width: isMobile ? "80vw" : 140 }}
                type="default"
                onClick={handleCancel}>
                Reset
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchFilters;
