import React from "react";
import {
  Descriptions,
  Tag,
  Tooltip,
  Button,
  Popover,
  Avatar,
  Pagination,
} from "antd";
import moment from "moment";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import usePermissions from "../../hooks/usePermissions";
const MobileViewTransaction = ({
  transactions,
  selectedTransaction,
  handleViewClick,
  dropdownValue,
  handleApprove,
  handleReject,
  pagination,
  setPagination,
  fetchData,
}) => {
  const { hasPermission } = usePermissions();
  const handlePaginationChange = async (page, pageSize) => {
    // Update the pagination in parent state
    setPagination((prev) => ({
      ...prev,
      current: page,
      pageSize: pageSize,
    }));

    // Trigger data loading with updated pagination
    await fetchData(null, page, pageSize);
  };
  return (
    <div>
      {transactions.map((transaction, index) => (
        <div
          key={index}
          style={{
            border: "1px solid #d9d9d9",
            borderRadius: "8px",
            padding: "1rem",
            marginBottom: "1rem",
            background: "#fff",
          }}>
           <Descriptions column={1} size="small" bordered>
        {Object.keys(transaction).map((key) => {
          if (
            key === "rejection_remarks" ||
            key === "created" ||
            key === "comments" ||
            key === "currency"
          )
            return null;

          const formattedTitle =
            key === "client_username"
              ? "Full Name" // Custom label for client_username
              : key
                  .replace(/_/g, " ") // Replace underscores with spaces
                  .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize each word

          let value = transaction[key];

          if (key === "transaction_id") {
            return (
              <Descriptions.Item label={formattedTitle} key={key}>
                {value}
                <div style={{ fontSize: "12px", color: "#888" }}>
                  {moment(transaction.created)
                    .local()
                    .format("DD-MM-YYYY hh:mm a")}
                </div>
              </Descriptions.Item>
            );
          } else if (key === "payment_status") {
            let color;
            let statusText;
            switch (value) {
              case "COMPLETED":
                color = "green";
                statusText = "Completed";
                break;
              case "FAILED":
                color = "red";
                statusText = "Failed";
                break;
              case "PENDING":
                color = "orange";
                statusText = "Pending";
                break;
              default:
                color = "default";
                statusText = value || "N/A";
                break;
            }
            return (
              <Descriptions.Item label={formattedTitle} key={key}>
                <Tag color={color} style={{ textTransform: "capitalize" }}>
                  {statusText}
                </Tag>
              </Descriptions.Item>
            );
          } else if (key === "payment_mode") {
            return (
              <Descriptions.Item label={formattedTitle} key={key}>
                <Tooltip title={value}>
                  <Tag color={value ? "#36cfc9" : "#d9d9d9"}>
                    {value || "N/A"}
                  </Tag>
                </Tooltip>
              </Descriptions.Item>
            );
          } else if (key === "remarks") {
            return (
              <Descriptions.Item label={formattedTitle} key={key}>
                {value ? (
                  <Button
                    type="link"
                    onClick={() => handleViewClick(transaction)}>
                    View
                  </Button>
                ) : (
                  "N/A"
                )}
              </Descriptions.Item>
            );
          } else if (key === "amount") {
            return (
              <Descriptions.Item label={formattedTitle} key={key}>
                {transaction.currency} {value}
              </Descriptions.Item>
            );
          } else if (key === "author") {
            return (
              <Descriptions.Item label={formattedTitle} key={key}>
                {value ? (
                  <Tooltip title={value}>
                    <Avatar style={{ backgroundColor: "#1890ff" }}>
                      {value.charAt(0)}
                    </Avatar>
                  </Tooltip>
                ) : (
                  "N/A"
                )}
              </Descriptions.Item>
            );
          } else if (key === "receipt") {
            return (
              <Descriptions.Item label={formattedTitle} key={key}>
                {value ? (
                  <Popover
                    content={
                      <a
                        href={value}
                        target="_blank"
                        rel="noopener noreferrer">
                        {value}
                      </a>
                    }
                    title="Receipt">
                    <Button style={{ padding: 0 }} type="link">
                      View Receipt
                    </Button>
                  </Popover>
                ) : (
                  "N/A"
                )}
              </Descriptions.Item>
            );
          } else {
            return (
              <Descriptions.Item label={formattedTitle} key={key}>
                {value || "N/A"}
              </Descriptions.Item>
            );
          }
        })}
      </Descriptions>


          {/* Footer section for allTransactions dropdown value */}
          {dropdownValue === "allTransactions" &&
            hasPermission("approve_reject_transaction") && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "1rem",
                  gap: "10px",
                }}>
                <Button
               style={{ background: "#22c55e" }}
                  icon={<CheckCircleOutlined  style={{color:'white'}} color="white"/>}
                
                  onClick={() => handleApprove(transaction)}></Button>
                <Button
                  type="danger"
                  icon={<CloseCircleOutlined style={{ color: "white" }} />}
              style={{background:'red'}}
                  onClick={() => handleReject(transaction)}>
         
                </Button>
              </div>
            )}
        </div>
      ))}
      <Pagination
        current={pagination.current}
        pageSize={pagination.pageSize}
        total={pagination.total}
        onChange={handlePaginationChange}
        style={{ textAlign: "center", marginTop: "20px" }}
      />
    </div>
  );
};

export default MobileViewTransaction;
