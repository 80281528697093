import React from "react";
import {
  Descriptions,
  Avatar,
  Tag,
  Button,
  Popover,
  Tooltip,
  Pagination,
} from "antd";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { useState } from "react";
import usePermissions from "../../hooks/usePermissions";
const MobileViewRefunds = ({
  data,
  loading,
  onAction,
  pagination,
  setPagination,
  fetchData,
  dropdownValue,
  showFlDeductionModal,
  showSubscriberDeductionModal,
  showDeleteConfirmationModal,
  setSelectedRecord,
  showModal,
}) => {
  const { hasPermission } = usePermissions();
  const getInitials = (name) => {
    const nameArray = name?.split(" ") || [];
    return nameArray.map((word) => word.charAt(0).toUpperCase()).join("");
  };
  const handleSubscriberDeduction = (item) => {
    setSelectedRecord(item);
    showSubscriberDeductionModal(item);
  };
  const handlePaginationChange = async (page, pageSize) => {
    console.log(pageSize.page, "pahfe");
    // Update the pagination in parent state
    setPagination((prev) => ({
      ...prev,
      current: page,
      pageSize: pageSize,
    }));

    // Trigger data loading with updated pagination
    await fetchData(null, page, pageSize);
  };
  const renderField = (key, value, record) => {
    if (key === "uid") {
      return null;
    }
    if (key === "currency") {
      return null;
    }
    switch (key) {
      case "refund_amount":
        return (
          <div>
            <div>
              {record.currency} {value}
            </div>
          </div>
        );
      case "refund_mode":
        return (
          <div>
            {record.refund_mode && (
              <Tag color="green">{record.refund_mode}</Tag>
            )}
          </div>
        );
      case "date":
        return (
          <div>
            {moment(record.created).local().format("DD-MM-YYYY hh:mm a")}
          </div>
        );
      case "action_date":
        return (
          <div>
            {moment(record.action_date).local().format("DD-MM-YYYY hh:mm a")}
          </div>
        );
      case "admin_action_date":
        return (
          <div>
            {moment(record.admin_action_date)
              .local()
              .format("DD-MM-YYYY hh:mm a")}
          </div>
        );
      case "status":
        return (
          <span
            style={{
              color:
                value === "Approved"
                  ? "green"
                  : value === "Pending"
                  ? "orange"
                  : "red",
            }}>
            {value}
          </span>
        );

      case "bdm_reason":
      case "feedback":
        return (
          <Popover
            content={
              <div
                style={{
                  maxWidth: 300,
                  maxHeight: 200,
                  whiteSpace: "pre-wrap",
                  overflowY: "scroll",
                }}>
                {value}
              </div>
            }
            title={key.replace(/_/g, " ").toUpperCase()}>
            {value ? <a href="#">View</a> : "N/A"}
          </Popover>
        );
      case "receipt": // New case for receipt
        return value ? (
          <Popover
            content={
              <a href={value} target="_blank" rel="noopener noreferrer">
                {value}¯¸
              </a>
            }
            title="Receipt">
            <Button style={{ padding: 0 }} type="link">
              View Receipt
            </Button>
          </Popover>
        ) : (
          "N/A"
        );

      case "task_author_name":
        return (
          <Popover content={<strong>{value}</strong>} trigger="hover">
            <div style={{ display: "flex", alignItems: "center" }}>
              <Avatar style={{ backgroundColor: "#87d068" }}>
                {getInitials(value)}
              </Avatar>
            </div>
          </Popover>
        );

      default:
        return value || "N/A";
    }
  };

  return (
    <div>
      {loading ? (
        <p>Loading...</p>
      ) : (
        data.map((item) => (
          <div
            key={item.order_id}
            style={{
              marginBottom: "16px",
              border: "1px solid #f0f0f0",
              borderRadius: "8px",
            }}>
            <Descriptions bordered column={1}>
              {Object.keys(item)
                .filter((key) => key !== "uid" && key !== "currency" && key !== "payment_source") // Exclude "uid" and "currency"
                .map((key) => (
                  <Descriptions.Item
                    key={key}
                    label={key.replace(/_/g, " ").toUpperCase()}>
                    {key === "source" ? (
                      <div>
                        {item.source === "Transaction" ? (
                          <div
                            style={{ display: "flex", alignItems: "center" }}>
                            <span>{item.source}</span>
                            <Tooltip title={item.payment_source}>
                              <InfoCircleOutlined
                                style={{
                                  marginLeft: "8px",
                                  cursor: "pointer",
                                  color: "orange",
                                }}
                              />
                            </Tooltip>
                          </div>
                        ) : (
                          <span>{item.source}</span>
                        )}
                      </div>
                    ) : (
                      renderField(key, item[key], item)
                    )}
                  </Descriptions.Item>
                ))}

              {dropdownValue === "allRefunds" && (
                <>
                  <Descriptions.Item label="Subscriber Deduction">
                    <Button
                      type="link"
                      disabled={item.source === "Transaction"}
                      style={{
                        opacity: item?.source === "Transaction" ? 0.5 : 1,
                      }}
                      onClick={() => handleSubscriberDeduction(item)}>
                      View
                    </Button>
                  </Descriptions.Item>

                  <Descriptions.Item label="Apply FL Deduction">
                    <Button
                      type="link"
                      style={{
                        opacity: item.move_to_fl_task ? 1 : 0.5,
                      }}
                      disabled={!item.move_to_fl_task}
                      onClick={() => showFlDeductionModal(item)}>
                      Apply
                    </Button>
                  </Descriptions.Item>
                </>
              )}

              {dropdownValue === "myRefunds" &&
                hasPermission("can_delete_refund") && (
                  <Descriptions.Item label={"Delete"}>
                    <Tooltip title="Delete">
                      <Button
                        type="danger"
                        disabled={item.status !== "Pending"}
                        icon={
                          <DeleteOutlined
                            style={{
                              opacity: item?.status === "Pending" ? 1 : 0.3,
                              color: "#ff4d4f", // Faded red when disabled
                            }}
                          />
                        }
                        shape="circle"
                        onClick={() => {
                          showDeleteConfirmationModal(item);
                          setSelectedRecord(item);
                        }}
                      />
                    </Tooltip>
                  </Descriptions.Item>
                )}
            </Descriptions>
            {dropdownValue === "allRefunds" &&
              hasPermission("can_accept_reject_refund") && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: "10px",
                    marginTop: "12px",
                  }}>
                  {item.status === "Pending" ? (
                    <>
                      <Tooltip title="Approve">
                        <Button
                          type="primary"
                          icon={<CheckCircleOutlined />}
                          shape="circle"
                          onClick={() => showModal("approve", item)}
                          style={{ background: "#22c55e" }} // Green background
                        />
                      </Tooltip>
                      <Tooltip title="Reject">
                        <Button
                          type="danger"
                          icon={<CloseCircleOutlined />}
                          shape="circle"
                          onClick={() => showModal("reject", item)}
                        />
                      </Tooltip>
                    </>
                  ) : (
                    <span style={{ fontWeight: "bold", color: "#4caf50" }}>
                      Action Taken
                    </span>
                  )}
                </div>
              )}
          </div>
        ))
      )}
      <Pagination
        current={pagination.current}
        pageSize={pagination.pageSize}
        total={pagination.total}
        onChange={handlePaginationChange}
        style={{ textAlign: "center", marginTop: "20px" }}
      />
    </div>
  );
};

export default MobileViewRefunds;
