import React from "react";
import { Row, Col, Flex, Skeleton } from "antd";
import {
  ShoppingOutlined,
  ClockCircleOutlined,
  TruckOutlined,
  FileDoneOutlined,
} from "@ant-design/icons";
import Img1 from "../../img/icon1.jpeg";
import { useMediaQuery } from "react-responsive";
import closedWon from "../../img/lead-generation.png";
import openLeads from "../../img/sales.png";

import ClosedWonSVG from "../../img/closed-won.svg";
import OpenLeadsSVG from "../../img/open-leads.svg";
import TotalLeadsSVG from "../../img/total-leads.svg";
import ClosedLostSvg from "../../img/closed-lost.svg";
const OrderStatistics = ({ statistics, leadType, loading }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  console.log(leadType, statistics, "stag");
  const StatItem = ({ icon, value, label }) => (
    <Col>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          background: "#eceff1",

          padding: "10px 12px",
          minWidth: isMobile ? "170px" : "230px",
          borderRadius: "4px",
        }}>
        <div
          style={{
            backgroundColor: "whitesmoke",
            borderRadius: "50%",
            width: "40px",
            height: "40px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginRight: "10px",
          }}>
          <img
            src={icon}
            alt={label}
            style={{ width: "30px", height: "30px" }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}>
          <div style={{ fontSize: "22px", fontWeight: "bold", color: "#333" }}>
            {value}
          </div>
          <div style={{ fontSize: "13px", color: "#666" }}>{label}</div>
        </div>
      </div>
    </Col>
  );

  return (
    <Flex
      style={{
        minWidth: "50%",
        background: "rgb(241 245 249)",
        width: isMobile ? "88vw" : "50%",
        padding: "10px",
        borderRadius: "8px",
      }}>
      {loading ? (
        <Flex
          wrap="wrap"
          justify={isMobile ? "flex-start" : "space-between"}
          style={{
            display: "flex",
            borderRadius: "8px",

            flexWrap: isMobile ? "wrap" : "wrap",
            width: isMobile ? "95vw" : "100%",
            maxWidth: isMobile ? "95vw" : "45vw",
            gap: isMobile ? "14px" : 0,
          }}>
          {Array.from({ length: 4 }).map((_, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                background: "#eceff1",
                padding: "10px 12px",
                minWidth: isMobile
                  ? "170px"
                  : leadType !== "assignedLeads"
                  ? "180px"
                  : "230px",
                borderRadius: "4px",
                height: "60px", // Adjust height if needed
                marginBottom: isMobile ? "14px" : "0", // Spacing for wrap behavior
              }}></div>
          ))}
        </Flex>
      ) : (
        <Flex
          justify={isMobile ? "flex-start" : "center"}
          align="center"
          gap={10}
          style={{
            display: "flex",
            borderRadius: "8px",
            flexDirection: "row",
            flexWrap: isMobile ? "wrap" : "wrap",
            width: isMobile ? "90vw" : "100%",

            gap: isMobile ? "14px" : 0,
          }}>
          <StatItem
            icon={TotalLeadsSVG}
            value={statistics.totalLeads}
            label="Total Leads"
          />

          <StatItem
            icon={OpenLeadsSVG}
            value={statistics.openLeads}
            label="Open Leads"
          />
          <StatItem
            icon={ClosedWonSVG}
            value={statistics.closedWon}
            label="Closed Won"
          />

          <StatItem
            icon={ClosedLostSvg}
            value={statistics.closedLost}
            label="Closed Lost "
          />

          {/* {leadType === "allLeads" && (
            <StatItem
              icon={ClosedWonSVG}
              value={statistics.assignedLeads}
              label="Assigned Leads"
            />
          )}
          {leadType === "managedLeads" && (
            <StatItem
              icon={ClosedWonSVG}
              value={statistics.assignedLeads}
              label="Assigned Leads"
            />
          )}
          {leadType === "allLeads" && (
            <StatItem
              icon={ClosedWonSVG}
              value={statistics.manageLeads}
              label="Managed Leads"
            />
          )} */}
        </Flex>
      )}
    </Flex>
  );
};

export default OrderStatistics;
