import { EditOutlined, EyeFilled, EyeInvisibleFilled } from "@ant-design/icons";
import {
  Button,
  Card,
  Descriptions,
  Flex,
  Form,
  Input,
  Modal,
  Pagination,
  Popover,
  Space,
  Spin,
  Table,
  Typography,
  message,
} from "antd";

import moment from "moment";
import React, { useCallback, useState } from "react";
import { useMediaQuery } from "react-responsive";
import usePermissions from "../../hooks/usePermissions";
import axiosInstance from "../../services/axiosInstance";
import { catchError } from "../../utils/errorHandler";
import clientService from "../../services/clientService";
import SkeletonTable from "../../components/SkeltonComponent";
import TaskDirectUserTable from "./TaskDirectUserTable";
import SpinLoading from "../../components/MyOrderActions/SpinLoading";

const MobileViewSelfRegistered = ({
  selfRegistered,
  showModal,
  pagination,
  setPagination,
}) => {
  const { hasPermission } = usePermissions();
  const [expandedClientId, setExpandedClientId] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [smallloading, setSmallLoading] = useState(false);

  const toggleExpand = async (clientId) => {
    if (expandedClientId === clientId) {
      setExpandedClientId(null);
      return;
    }

    setSmallLoading(true);
    try {
      const data = await clientService.getClientTasks(clientId);
      setTasks(data?.data?.data?.results || []);
      setExpandedClientId(clientId);
    } catch (error) {
      await catchError(error);
    } finally {
      setSmallLoading(false);
    }
  };

  return (
    <div>
      {selfRegistered.map((user) => (
        <Card
          key={user.client_profile_uid}
          style={{ marginBottom: "1rem" }}
          actions={[
            hasPermission("can_update_self_registered_client_remarks") && (
              <Button
                type="link"
                icon={<EditOutlined />}
                onClick={() => showModal(user)}
              >
                Edit Remarks
              </Button>
            ),
          ]}
        >
          <Descriptions bordered size="small">
            <Descriptions.Item label="Full Name">
              {user.full_name || "N/A"}
            </Descriptions.Item>
            <Descriptions.Item label="Contact">
              {user.contact_no || "N/A"}
            </Descriptions.Item>
            <Descriptions.Item label="Email">
              {user.email || "N/A"}
            </Descriptions.Item>
            <Descriptions.Item label="Created">
              {user.created_at
                ? moment(user.created_at).local().format("DD-MM-YYYY hh:mm A")
                : "N/A"}
            </Descriptions.Item>
            <Descriptions.Item label="Placed Order">
              {user?.is_placed_order ? "Yes" : "No"}
            </Descriptions.Item>
            <Descriptions.Item label="Remarks">
              {user.remarks || "No remarks available"}
            </Descriptions.Item>
          </Descriptions>
          <Button
            style={{ float: "right", marginRight: "10%", marginTop: "10px" }}
            onClick={() => toggleExpand(user.uid)}
            icon={
              expandedClientId === user.uid ? (
                <EyeInvisibleFilled />
              ) : (
                <EyeFilled />
              )
            }
          />
          {expandedClientId === user.uid && (
            <div style={{ marginTop: "1rem" }}>
              {smallloading ? (
                <Spin size="large" />
              ) : (
                <TaskDirectUserTable data={tasks} />
              )}
            </div>
          )}
        </Card>
      ))}
      <Pagination
        current={pagination.current}
        pageSize={pagination.pageSize}
        total={pagination.total}
        onChange={(page, pageSize) =>
          setPagination((prev) => ({ ...prev, current: page, pageSize }))
        }
        style={{ textAlign: "center", marginTop: 16 }}
      />
    </div>
  );
};

const SelfRegisteredUsers = ({
  selfRegistered,
  loading,
  pagination,
  getSelfRegistered,
  setPagination,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentClientId, setCurrentClientId] = useState(null);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [taskLoading, setTaskLoading] = useState({});
  const [formSubmitLoading, setFormSubmitLoading] = useState(false);
  const { hasPermission } = usePermissions();
  const [tasks, setTasks] = useState([]);
  const [remarksOld, setRemarksOld] = useState("");
  const { Title } = Typography;

  const handleOpenModal = (clientId) => {
    setCurrentClientId(clientId?.client_profile_uid);
    setRemarksOld(clientId?.remarks);
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
    setExpandedRowKeys([]);
  };

  const toggleExpand = useCallback(
    async (record) => {
      if (expandedRowKeys.includes(record.client_profile_uid)) {
        setExpandedRowKeys([]);
        setTasks([]);
      } else {
        setExpandedRowKeys([record.client_profile_uid]);
        setTaskLoading(true);
        try {
          const data = await clientService.getClientTasks(record.uid);
          setTasks(data?.data?.data?.results || []);
          setTaskLoading(false);
        } catch (error) {
          setTaskLoading(false);
          await catchError(error);
        }
      }
    },
    [expandedRowKeys]
  );

  const columns = React.useMemo(() => {
    if (selfRegistered && selfRegistered.length > 0) {
      return [
        {
          title: "Full Name",
          dataIndex: "full_name",
          key: "full_name",
          render: (text, record) => {
            const formattedDate = moment(record.created_at)
              .local()
              .format("DD-MM-YYYY hh:mm A");
            return (
              <div>
                {expandedRowKeys.includes(record.client_profile_uid) ? (
                  <EyeInvisibleFilled
                    style={{ cursor: "pointer", marginRight: "6px" }}
                    onClick={() => toggleExpand(record)}
                  />
                ) : (
                  <EyeFilled
                    style={{ cursor: "pointer", marginRight: "6px" }}
                    onClick={() => toggleExpand(record)}
                  />
                )}
                {text}
                <span
                  style={{ opacity: 0.5, fontSize: "12px", display: "block" }}
                >
                  {formattedDate || "N/A"}
                </span>
              </div>
            );
          },
        },
        {
          title: "Contact",
          key: "contact",
          render: (text, record) => (
            <div>
              <div>{record.email || "N/A"}</div>
              <div>{record.contact_no || "N/A"}</div>
            </div>
          ),
        },
        {
          title: "Placed Order",
          dataIndex: "is_placed_order",
          key: "is_placed_order",
          render: (value) => (
            <span
              style={{ color: value ? "green" : "red" }}
            >
              {value ? "Yes" : "No"}
            </span>
          ),
        },
        {
          title: "Remarks",
          dataIndex: "remarks",
          key: "remarks",
          render: (text) => (
            <Popover
              content={<div>{text || "No remarks available"}</div>}
              title="Remarks"
              trigger="hover"
              placement="topLeft"
            >
              <Button type="link" style={{ padding: 0 }}>
                View
              </Button>
            </Popover>
          ),
        },
        ...(hasPermission("can_update_self_registered_client_remarks")
          ? [
              {
                title: "Edit Remark",
                key: "action",
                render: (_, record) => (
                  <Button onClick={() => handleOpenModal(record)} type="link">
                    <EditOutlined />
                  </Button>
                ),
              },
            ]
          : []),
      ];
    }
    return [];
  }, [selfRegistered, expandedRowKeys, hasPermission, toggleExpand]);

  return (
    <div>
      {loading ? (
        <Spin size="large" />
      ) : isMobile ? (
        <MobileViewSelfRegistered
          selfRegistered={selfRegistered}
          showModal={handleOpenModal}
          pagination={pagination}
          setPagination={setPagination}
        />
      ) : (
        <Table
          dataSource={selfRegistered}
          columns={columns}
          expandable={{
            expandedRowRender: (record) => (
              <div>
                {taskLoading ? (
                  <SpinLoading />
                ) : (
                  <TaskDirectUserTable data={tasks} />
                )}
              </div>
            ),
            expandedRowKeys,
            onExpand: (expanded, record) => toggleExpand(record),
            expandIconColumnIndex: -1,
            rowExpandable: (record) => record.uid !== "Not Expandable",
          }}
          pagination={{
            current: pagination.current,
            pageSize: pagination.pageSize,
            total: pagination.total,
            onChange: (page, pageSize) =>
              setPagination({ ...pagination, current: page, pageSize }),
          }}
          rowKey="client_profile_uid"
        />
      )}

      <Modal
        className="custom-modal2"
        title={
          <div
            className="custom-modal-header"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Title
              level={3}
              style={{
                color: "white",
                margin: 0,
                position: "relative",
                marginLeft: "10px",
              }}
            >
              Edit Remarks
            </Title>
          </div>
        }
        visible={isModalVisible}
        onCancel={handleCloseModal}
        footer={null}
        destroyOnClose
      >
        <Form
          initialValues={{
            remarks: remarksOld,
          }}
          onFinish={async (values) => {
            setFormSubmitLoading(true);
            try {
              const response = await axiosInstance.post(
                `/useraccount/v2/add-client-remarks/${currentClientId}/`,
                { remarks: values.remarks }
              );

              if (response.status === 200) {
                message.success(response.data.data);
                getSelfRegistered();
                handleCloseModal();
                setFormSubmitLoading(false);
              }
            } catch (error) {
              setFormSubmitLoading(false);
              await catchError(error);
            }
          }}
        >
          <Form.Item
            name="remarks"
            rules={[{ required: true, message: "Please write remarks" }]}
          >
            <Input.TextArea placeholder="Write remarks" />
          </Form.Item>
          <Flex align="flex-end" justify="flex-end" style={{ width: "100%" }}>
            <Button type="primary" htmlType="submit">
              {formSubmitLoading ? <Spin /> : "Submit"}
            </Button>
          </Flex>
        </Form>
      </Modal>
    </div>
  );
};

export default SelfRegisteredUsers;
