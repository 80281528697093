import axiosInstance from "./axiosInstance";
import { catchError } from "../utils/errorHandler";

const hierarchyService = {
  fetchHierarchy: async () => {
    try {
       
    //   const response = await axiosInstance.get('/analytics/team/hierarchy/');
    const response = await axiosInstance.get('/analytics/team/hierarchy?permission=can_view_sales_and_analytics');
      if (response.status === 200) {
        return { success: true, data: response.data };

      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      console.log(error.response , 'res')
      return { success: false };
    }
  },
  fetchBdmAnalyticsHierarchy: async () => {
    try {
       
    //   const response = await axiosInstance.get('/analytics/team/hierarchy/');
    const response = await axiosInstance.get('/analytics/team/hierarchy/?flat=True&permission=can_view_bdm_analytics');
      if (response.status === 200) {
        return { success: true, data: response.data };

      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      console.log(error.response , 'res')
      return { success: false };
    }
  }
};

export default hierarchyService;
