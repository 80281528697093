import React, { useEffect, useCallback, useState } from "react";
import clientService from "../../services/clientService";
import AllClientsTableSummary from "./AllClientsTableSummary";
import { Flex, Select, Button, message, Tabs } from "antd";
import SearchFilters from "./SearchFilters";
import { Typography } from "antd";
import AddClientModal from "./AddClientModal";
import { CSSTransition } from "react-transition-group";
import { FilterOutlined } from "@ant-design/icons";
import "./ClientParent.css";
import usePermissions from "../../hooks/usePermissions";
import { useMediaQuery } from "react-responsive";
import Referrals from "./Referrals";
import AddReferralModal from "./AddReferralModal";
import { form } from "framer-motion/client";
import AgentsList from "./AgentsList";
import SelfRegisteredUsers from "./SelfRegisteredUsers";

function AllClientsParent() {
  const { hasPermission } = usePermissions();
  const [isReferralModalVisible, setIsReferralModalVisible] = useState(false);
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const [agentsList, setAgentsList] = useState([]);
  const { Option } = Select;
  const { Paragraph } = Typography;
  const [clients, setClients] = useState([]);
  const [referrals, setReferrals] = useState([]);
  const [filtersList, setFiltersList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [websites, setWebsites] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [selfRegistered, setSelfRegistered] = useState([]);
  const [activeTab, setActiveTab] = useState("clients");
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
    showSizeChanger: false,
  });

  const [filters, setFilters] = useState({});
  const [viewMode, setViewMode] = useState("");
  const showReferralModal = () => setIsReferralModalVisible(true);
  const closeReferralModal = () => {
    setIsReferralModalVisible(false);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  const getAllClients = useCallback(
    async (page = 1, pageSize = 20) => {
      setLoading(true);
      const result = await clientService.getAllClients(page, pageSize, filters);

      if (result.success) {
        setFiltersList(result?.data?.data?.filters);
        console.log(result?.data?.data?.filters, "filters");
        setClients(result.data.data.results);
        setPagination({
          ...pagination,
          total: result?.data?.data?.count || 0,
          current: page,
          pageSize,
        });
      }
      setLoading(false);
    },
    [pagination, filters]
  );
  const getAgents = useCallback(
    async (page = 1, pageSize = 20) => {
      setLoading(true);
      const result = await clientService.getAgentsList(page, pageSize, filters);

      if (result.success) {
        setFiltersList(result?.data?.data?.filters);

        setAgentsList(result?.data?.data?.results);
        setPagination({
          ...pagination,
          total: result?.data?.data?.count || 0,
          current: page,
          pageSize,
        });
      }
      setLoading(false);
    },
    [pagination, filters]
  );
  const fetchWebsites = async () => {
    const result = await clientService.getWebsitesList();
    if (result) {
      setWebsites(result.data.data);
    } else {
      message.error("Failed to load websites");
    }
  };
  const getMyClients = useCallback(
    async (page = 1, pageSize = 20) => {
      setLoading(true);
      const result = await clientService.getMyClients(page, pageSize, filters); // Fetch only my clients

      if (result.success) {
        setFiltersList(result?.data?.data?.filters);

        setClients(result.data.data.results);
        setPagination({
          ...pagination,
          total: result?.data?.data?.count || 0,
          current: page,
          pageSize,
        });
      }
      setLoading(false);
    },
    [pagination, filters]
  );
  const getClientReferrals = useCallback(
    async (page = 1, pageSize = 20) => {
      setLoading(true);
      const result = await clientService.getClientReferrals(
        page,
        pageSize,
        filters
      ); // Fetch only my clients

      if (result.success) {
        setFiltersList(result?.data?.data?.filters);

        setReferrals(result.data.data.results);
        setPagination({
          ...pagination,
          total: result?.data?.data?.count || 0,
          current: page,
          pageSize,
        });
      }
      setLoading(false);
    },
    [pagination, filters]
  );
  const getSupportReferrals = useCallback(
    async (page = 1, pageSize = 20) => {
      setLoading(true);
      const result = await clientService.getSupportReferrals(
        page,
        pageSize,
        filters
      ); // Fetch only my clients

      if (result.success) {
        setFiltersList(result?.data?.data?.filters);

        setReferrals(result.data.data.results);
        setPagination({
          ...pagination,
          total: result?.data?.data?.count || 0,
          current: page,
          pageSize,
        });
      }
      setLoading(false);
    },
    [pagination, filters]
  );
  const getAllReferrals = useCallback(
    async (page = 1, pageSize = 20) => {
      setLoading(true);
      const result = await clientService.getAllReferrals(
        page,
        pageSize,
        filters
      ); // Fetch only my clients

      if (result.success) {
        setFiltersList(result?.data?.data?.filters);

        setReferrals(result.data.data.results);
        setPagination({
          ...pagination,
          total: result?.data?.data?.count || 0,
          current: page,
          pageSize,
        });
      }
      setLoading(false);
    },
    [pagination, filters]
  );
  const getSelfRegistered = useCallback(
    async (page = 1, pageSize = 20) => {
      setLoading(true);
      const result = await clientService.getSelfRegisteredList(
        page,
        pageSize,
        filters
      );
      if (result.success) {
        setFiltersList(result?.data?.data?.filters);

        setSelfRegistered(result?.data?.data?.results);
        setPagination({
          ...pagination,
          total: result?.data?.data?.count || 0,
          current: page,
          pageSize,
        });
      }
      setLoading(false);
    },
    [pagination, filters]
  );

  // useEffect(() => {
  //   // Set default view mode when tab changes
  //   if (activeTab === "clients") {
  //     setViewMode("myClients");
  //   } else if (activeTab === "referrals") {
  //     setViewMode("myReferrals");
  //   }
  // }, [activeTab]);

  // Fetch clients based on active tab and view mode (My Clients or All Clients)
  // Handle activeTab changes
  useEffect(() => {
    if (activeTab === "clients") {
      setViewMode("myClients");
    } else if (activeTab === "referrals") {
      setViewMode("clientReferrals");
    } else if (activeTab === "agents") {
      setViewMode("Agents");
    }
  }, [activeTab]);

  // Fetch data based on viewMode
  useEffect(() => {
    if (activeTab === "clients") {
      if (viewMode === "allClients") {
        fetchWebsites();
        getAllClients(pagination.current, pagination.pageSize, filters);
      } else if (viewMode === "myClients") {
        getMyClients(pagination.current, pagination.pageSize, filters);
      }
    } else if (activeTab === "referrals") {
      if (viewMode === "allReferrals") {
        getAllReferrals(pagination.current, pagination.pageSize, filters);
      } else if (viewMode === "clientReferrals") {
        getClientReferrals(pagination.current, pagination.pageSize, filters);
      } else if (viewMode === "supportReferrals") {
        getSupportReferrals(pagination.current, pagination.pageSize, filters);
      }
    } else if (activeTab === "agents" && viewMode === "Agents") {
      getAgents(pagination.current, pagination.pageSize, filters);
    } else if (activeTab == "Self_registered_users") {
      getSelfRegistered(pagination.current, pagination.pageSize, filters);
    }
  }, [activeTab, viewMode, pagination.current, pagination.pageSize, filters]);

  const handleSearchClick = (newFilters) => {
    setFilters(newFilters);
    setPagination({ ...pagination, current: 1 }); // Reset to first page when applying new filters
  };

  const handleCancelSearch = async () => {
    setFilters({});
    if (viewMode === "allClients") {
      await getAllClients(1, pagination.pageSize);
    } else {
      await getMyClients(1, pagination.pageSize);
    }
    setPagination({ ...pagination, current: 1 });
  };

  // Handle switching between "My Clients" and "All Clients"
  const handleViewModeChange = (value) => {
    setShowFilters(false);
    const mode =
      value === true || value === "myClients" ? "myClients" : "allClients";

    setViewMode(mode);
    setPagination({ ...pagination, current: 1 }); // Reset pagination when view mode changes
  };

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const handleTabChange = (key) => {
    setPagination({ ...pagination, current: 1 }); // Reset pagination when view mode changes
    setFiltersList([]);
    setActiveTab(key);
  };

  return (
    <Flex vertical style={{ padding: "30px 20px" }}>
      {showFilters && (
        <CSSTransition
          in={showFilters}
          timeout={300}
          classNames="filter"
          unmountOnExit>
          <div className="filter-container">
            <SearchFilters
              filtersList={filtersList}
              onSearch={handleSearchClick}
              pagination={pagination}
              getAllClients={handleCancelSearch}
            />
          </div>
        </CSSTransition>
      )}

      <div
        style={{
          display: "flex",
          justifyContent: isMobile ? "flex-end" : "space-between",
          flexDirection: isMobile ? "column-reverse" : "row",
        }}>
        <Flex style={{ padding: "10px 0px", margin: 0 }} align="flex-end">
          <Paragraph
            style={{
              fontWeight: 500,
              opacity: 0.6,
              textAlign: "left",
              margin: 0,
              padding: 0,
            }}>
            Showing{" "}
            {Math.min(
              (pagination.current - 1) * pagination.pageSize + 1,
              pagination.total
            )}{" "}
            -
            {Math.min(
              pagination.current * pagination.pageSize,
              pagination.total
            )}{" "}
            of {pagination.total} Clients
          </Paragraph>
        </Flex>

        <Flex align="center" style={{ margin: "10px 0px" }}>
          {activeTab !== "agents" && activeTab !=="Self_registered_users" && (
            <Select
              value={viewMode} // Ensure that the value is bound to the 'viewMode' state
              onChange={(value) => {
                setViewMode(value); // Update the 'viewMode' state when the user selects an option
                setPagination({ ...pagination, current: 1 }); // Reset pagination when view mode changes
              }}
              style={{ width: 150, textAlign: "left", marginLeft: "10px" }}
            >
              {activeTab === "clients" ? (
                <>
                  <Option value="myClients">My Clients</Option>
                  <Option value="allClients">All Clients</Option>
                </>
              ) : activeTab === "referrals" ? (
                <>
                  {hasPermission("can_view_my_client_referrals") && (
                    <Option value="clientReferrals">Client Referrals</Option>
                  )}
                  {hasPermission("can_view_all_referrals") && (
                    <Option value="allReferrals">All Referrals</Option>
                  )}
                  {hasPermission("can_view_support_referrals") && (
                    <Option value="supportReferrals">Support Referrals</Option>
                  )}
                </>
              ) : null}
            </Select>
          )}

          {hasPermission("can_create_client") && activeTab === "clients" && (
            <Button
              type="primary"
              onClick={showModal}
              style={{ marginLeft: "10px", background: "#040724" }}
            >
              Add Client
            </Button>
          )}
          {hasPermission("can_create_referral") &&
            activeTab === "referrals" && (
              <Button
                type="primary"
                onClick={showReferralModal}
                style={{ marginLeft: "10px", background: "#040724" }}
              >
                Add Referral
              </Button>
            )}

          <Button
            type="primary"
            icon={<FilterOutlined />}
            onClick={() => setShowFilters(!showFilters)}
            style={{ marginLeft: "10px", background: "#040724" }}
          />
        </Flex>
      </div>

      <Tabs activeKey={activeTab} onChange={handleTabChange}>
        <Tabs.TabPane tab="Clients" key="clients">
          <AllClientsTableSummary
            allclients={clients}
            loading={loading}
            pagination={pagination}
            setPagination={setPagination}
          />
        </Tabs.TabPane>
        {hasPermission("can_view_my_client_referrals") && (
          <Tabs.TabPane tab="Referrals" key="referrals">
            <Referrals
              referrals={referrals}
              loading={loading}
              pagination={pagination}
              setPagination={setPagination}
              getAllReferrals={getAllReferrals}
              getClientReferrals={getClientReferrals}
              viewMode={viewMode}
              getSupportReferrals={getSupportReferrals}
            />
          </Tabs.TabPane>
        )}
        {hasPermission("can_view_become_agent_list") && (
          <Tabs.TabPane tab="Agents" key="agents">
            <AgentsList
              agents={agentsList}
              loading={loading}
              pagination={pagination}
              setPagination={setPagination}
              getAgents={getAgents}
            />
          </Tabs.TabPane>
        )}
        {hasPermission("can_view_self_registered_client") && (
          <Tabs.TabPane tab="Direct Signups" key="Self_registered_users">
            <SelfRegisteredUsers
              selfRegistered={selfRegistered}
              loading={loading}
              pagination={pagination}
              setPagination={setPagination}
              getSelfRegistered={getSelfRegistered}
             />
          </Tabs.TabPane>
        )}
      </Tabs>
      <AddClientModal
        visible={isModalVisible}
        onClose={handleModalClose}
        websites={websites}
      />
      <AddReferralModal
        visible={isReferralModalVisible}
        onClose={closeReferralModal}
        getClientReferrals={getClientReferrals}
      />
    </Flex>
  );
}

export default AllClientsParent;
