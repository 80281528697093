import React, { useMemo, useState } from "react";
import {
  Table,
  Tooltip,
  Avatar,
  Popover,
  Button,
  Flex,
  Tag,
  message,
  Modal,
  Input,
  Descriptions,
  Upload,
  Row,
  Col,
  Form,
  Typography,
} from "antd";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  InboxOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import moment from "moment";
import paymentOperationsService from "../../services/paymentOperationsService";
import { useMediaQuery } from "react-responsive";
import usePermissions from "../../hooks/usePermissions";
import MobileViewTransaction from "./MobileViewTransaction";
import "./paymentOperations.css";
const { Dragger } = Upload;
// Function to render avatars with popover for Visibility and Ownership
const getInitials = (name) => {
  const words = name.split(" ");
  return words
    .map((word) => word.charAt(0).toUpperCase())
    .slice(0, 2)
    .join(""); // Get up to two initials
};

const paymentModeColors = {
  Razorpay: "#f36d44", // Razorpay logo color
  Stripe: "#6772e5", // Stripe logo color

  " N/A": "#808080", // Grey for N/A
};
const renderAvatars = (dataArray, title) => {
  if (!dataArray || dataArray.length === 0) return "N/A";

  const displayedAvatars = dataArray.slice(0, 2);
  const remainingAvatars = dataArray.slice(2);

  const getInitials = (name) => {
    const words = name.split(" ");
    return words
      .map((word) => word.charAt(0).toUpperCase())
      .slice(0, 2)
      .join(""); // Get up to two initials
  };

  return (
    <Avatar.Group maxCount={3}>
      {displayedAvatars.map((name, index) => (
        <Tooltip key={index} title={name}>
          <Avatar style={{ backgroundColor: "#87d068" }}>
            {getInitials(name)}
          </Avatar>
        </Tooltip>
      ))}
      {remainingAvatars.length > 0 && (
        <Popover
          content={
            <div>
              {remainingAvatars.map((name, index) => (
                <p key={index}>{name}</p>
              ))}
            </div>
          }
          title={title}>
          <Avatar style={{ backgroundColor: "#f56a00" }}>
            +{remainingAvatars.length}
          </Avatar>
        </Popover>
      )}
    </Avatar.Group>
  );
};

const TransactionsTable = ({
  transactions,
  loading,
  pagination,
  setPagination,
  dropdownValue,
  fetchData,
}) => {
  const { Title } = Typography;
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const { hasPermission } = usePermissions();

  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [isActionModalVisible, setIsActionModalVisible] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedTransactionIds, setSelectedTransactionIds] = useState([]);
  const [isAccept, setIsAccept] = useState(null); // To track if action is accept or reject
  const [rejectionRemarks, setRejectionRemarks] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [commentText, setCommentText] = useState("");
  const [commentSubmitting, setCommentSubmiting] = useState(false);
  const [loadingAction, setLoadingAction] = useState(false);
  const { Dragger } = Upload;
  const handleViewClick = (record) => {
    setSelectedTransaction(record);
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    setSelectedTransaction(null);
  };
  const handleModalActionClose = () => {
    setIsActionModalVisible(false);
  };
  const handleActionClick = (action, transactionIds) => {
    setSelectedTransactionIds(transactionIds);
    setIsAccept(action === "approve");
    setIsActionModalVisible(true);
  };
  const handleCommentChange = (e) => {
    setCommentText(e.target.value);
  };
  const handleConfirmAction = async () => {
    setLoadingAction(true);
    const payload = {
      transaction_id: selectedTransactionIds,
      accept: isAccept,
      rejection_remarks: !isAccept ? rejectionRemarks : "",
    };

    const result = await paymentOperationsService.transactionAction(payload);

    setLoadingAction(false);

    if (result.success) {
      message.success(
        isAccept
          ? "Transaction approved successfully!"
          : "Transaction rejected successfully!"
      );
      setIsActionModalVisible(false); // Close modal on success
    } else {
      message.error("Action failed! Please try again.");
    }
  };

  const handleRejectReasonChange = (e) => {
    setRejectionRemarks(e.target.value);
  };
  const handleFileChange = (info) => {
    if (info.file.status === "removed") {
      setSelectedFile(null);
    } else {
      setSelectedFile(info.file);
    }
  };

  const handleCommentSubmit = async (transactionId) => {
    setCommentSubmiting(true);
    if (!commentText.trim() && !selectedFile) {
      message.error("Please add a comment or upload a file.");
      return;
    }

    const payload = new FormData(); // Use FormData for file upload
    payload.append("comment", commentText.trim());
    console.log(selectedFile, "1234");
    if (selectedFile) {
      payload.append("files", selectedFile); // Append the file
    }

    try {
      const result = await paymentOperationsService.transactionComment(
        payload,
        transactionId
      );

      if (result.success) {
        message.success("Comment added successfully!");
        setCommentSubmiting(false);
        await fetchData();
        setCommentText("");
        setIsModalVisible(false);
        setSelectedFile(null);
      } else {
        message.error("Failed to add comment. Please try again.");
        setCommentSubmiting(false);
      }
    } catch (error) {
      console.error("Error submitting comment:", error);
      setCommentSubmiting(false);
      message.error("An unexpected error occurred.");
    }
  };

  const columns = useMemo(() => {
    // Start with an empty array of columns
    const paymentModeColors = {
      Razorpay: "#012652", // Razorpay's logo color (dark blue)
      Stripe: "#5433FF", // Stripe's logo color (blue)
      Refund: "red", // Refund color (red)
      NA: "yellow",
    };

    const renderPaymentMode = (paymentMode) => {
      // Use the paymentModeColors object to get the corresponding color
      const color = paymentModeColors[paymentMode] || `#FFA500`; // Default color for other payment modes (orange)

      return (
        <Tag color={color} style={{ textTransform: "capitalize" }}>
          {paymentMode || "NA"}{" "}
          {/* If paymentMode is falsy, it will display "NA" */}
        </Tag>
      );
    };
    const renderAmount = (amount, record) => {
      const isMapped = record?.mapping_status; // Check if 'mapping_status' exists in the record
      return (
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
          }}>
          <span style={{ marginRight: "8px" }}>
            {record.currency} {amount}
          </span>

          {/* Show "Mapped" or "Not Mapped" only if dropdownValue is "myTransactions" */}
          {dropdownValue === "myTransactions" && (
            <span style={{ fontSize: 12 }}>
              {isMapped === "Mapped" ? (
                <span style={{ color: "green" }}>
                  <span style={{ color: "green", marginRight: "1px" }}>●</span>{" "}
                  Mapped
                </span>
              ) : (
                <span style={{ color: "red" }}>
                  <span style={{ color: "red", marginRight: "1px" }}>●</span>{" "}
                  Not Mapped
                </span>
              )}
            </span>
          )}
        </div>
      );
    };

    const dynamicColumns = [];

    if (transactions && transactions.length > 0) {
      // Get the keys of the first record to generate dynamic columns
      const sampleRecord = transactions[0];

      Object.keys(sampleRecord).forEach((key) => {
        // Skip "Rejection_remarks" and "created" columns
        if (
          key === "rejection_remarks" ||
          key === "created" ||
          key === "comments" ||
          key === "currency" ||
          key === "mapping_status"
        )
          return;

        const formattedTitle =
          key === "client_username"
            ? "Full Name" // Display "Full Name" for the 'client_username' key
            : key
                .replace(/_/g, " ") // Replace underscores with spaces
                .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize each word

        let column = {
          title: formattedTitle,
          dataIndex: key,
          key: key,
        };

        // Special render logic for specific fields
        if (key === "transaction_id") {
          column.render = (transactionId, created) => (
            <div>
              <div>{transactionId}</div>
              {created?.created && (
                <div style={{ fontSize: "12px", color: "#888" }}>
                  {moment(created.created).local().format("DD-MM-YYYY hh:mm a")}
                </div>
              )}
            </div>
          );
        } else if (key === "receipt") {
          column.render = (receipt) => {
            return receipt ? (
              <Popover
                content={
                  <a href={receipt} target="_blank" rel="noopener noreferrer">
                    {receipt}
                  </a>
                }
                title="Receipt">
                <Button style={{ padding: 0 }} type="link">
                  View Receipt
                </Button>
              </Popover>
            ) : (
              "N/A"
            );
          };
        } else if (key === "payment_status") {
          column.render = (paymentStatus) => {
            let color;
            let statusText;

            switch (paymentStatus) {
              case "COMPLETED":
                color = "green";
                statusText = "Completed";
                break;
              case "FAILED":
                color = "red";
                statusText = "Failed";
                break;
              case "PENDING":
                color = "orange";
                statusText = "Pending";
                break;
              default:
                color = "default"; // Default color if status is neither Completed, Failed, nor Pending
                statusText = paymentStatus || "N/A";
                break;
            }

            return (
              <Tag color={color} style={{ textTransform: "capitalize" }}>
                {statusText}
              </Tag>
            );
          };
        } else if (key === "payment_mode") {
          column.render = (paymentMode) => renderPaymentMode(paymentMode);
        } else if (key === "remarks") {
          column.render = (remarks, record) => {
            const { rejection_remarks, comments } = record;
            const transactionId = record.transaction_id;

            const maxLength = 30;
            const displayedText =
              remarks?.length > maxLength
                ? `${remarks.slice(0, maxLength)}...`
                : remarks;

            return (
              <Button type="link" onClick={() => handleViewClick(record)}>
                {" "}
                View
              </Button>
            );
          };
        } else if (key === "ownership" || key === "visibility") {
          column.render = (value) =>
            renderAvatars(value, key.charAt(0).toUpperCase() + key.slice(1));
        } else if (key === "status") {
          column.render = (status) => {
            if (status === "Approved") {
              return (
                <Tooltip title="Approved">
                  <CheckCircleOutlined style={{ color: "green" }} />
                </Tooltip>
              );
            } else if (status === "Rejected") {
              return (
                <Tooltip title="Rejected">
                  <CloseCircleOutlined style={{ color: "red" }} />
                </Tooltip>
              );
            } else if (!status) {
              return (
                <Tooltip title="No Action Taken">
                  <span style={{ color: "gray", marginLeft: "8px" }}>N/A</span>
                </Tooltip>
              );
            } else {
              return (
                <Tooltip title="Unapproved">
                  <CloseCircleOutlined style={{ color: "orange" }} />
                </Tooltip>
              );
            }
          };
        } else if (key === "amount") {
          column.render = (amount, record) => renderAmount(amount, record);
        } else if (key === "author") {
          column.render = (author) =>
            author ? (
              <Tooltip title={author}>
                <Avatar style={{ backgroundColor: "#1890ff" }}>
                  {getInitials(author)}
                </Avatar>
              </Tooltip>
            ) : (
              "N/A"
            );
        } else {
          // Default render if no special logic is defined
          column.render = (text) => text || "N/A";
        }

        // Push the column to the dynamicColumns array
        dynamicColumns.push(column);
      });
    }

    if (
      dropdownValue === "allTransactions" &&
      hasPermission("approve_reject_transaction")
    ) {
      dynamicColumns.push({
        title: "Action",
        key: "action",
        render: (_, record) => (
          <div style={{ display: "flex", gap: "10px" }}>
            <Tooltip title="Approve">
              <Button
                type="primary"
                icon={<CheckCircleOutlined />}
                shape="circle"
                onClick={() =>
                  handleActionClick("approve", [record.transaction_id])
                }
                style={{ background: "#22c55e" }}
              />
            </Tooltip>
            <Tooltip title="Reject">
              <Button
                type="danger"
                icon={<CloseCircleOutlined />}
                shape="circle"
                onClick={() =>
                  handleActionClick("reject", [record.transaction_id])
                }
              />
            </Tooltip>
          </div>
        ),
      });
    }

    // Ensure the status column is always the last column
    const statusColumn = dynamicColumns.find((col) => col.key === "status");
    if (statusColumn) {
      // Remove status column from its original position
      dynamicColumns.splice(dynamicColumns.indexOf(statusColumn), 1);
      if (dropdownValue === "allTransactions") {
        // Insert the status column before the last column (second-last position)
        dynamicColumns.splice(dynamicColumns.length - 1, 0, statusColumn);
      } else {
        // Push the status column to the end (last position)
        dynamicColumns.push(statusColumn);
      }
    }
    return dynamicColumns;
  }, [transactions, dropdownValue]);

  return (
    <>
      {isMobile ? (
        <MobileViewTransaction
          transactions={transactions}
          handleViewClick={handleViewClick}
          dropdownValue={dropdownValue}
          pagination={pagination}
          setPagination={setPagination}
          fetchData={fetchData}
        />
      ) : (
        <Table
          style={{ width: "100%" }}
          columns={columns}
          dataSource={transactions}
          loading={loading}
          scroll={{ x: "max-content" }} // Enable horizontal scroll when content overflows
          pagination={{
            current: pagination.current,
            pageSize: pagination.pageSize,
            total: pagination.total,
            onChange: (page, pageSize) => {
              setPagination({ ...pagination, current: page, pageSize });
            },
            onShowSizeChange: (current, size) => {
              setPagination({ ...pagination, current: 1, pageSize: size });
            },
          }}
          rowKey="transaction_id"
        />
      )}

      <Modal
        className="custom-modal"
        title={
          <Flex justify="space-between" className="custom-modal-header">
            <Title
              level={3}
              style={{
                color: "white",
                margin: 0,
                position: "relative",
                marginLeft: "10px",
              }}>
              Confirmation
            </Title>
            {/* <CloseOutlined
              style={{ fontSize: "16px", marginRight: "10px" }}
              onClick={handleModalClose}
            /> */}
          </Flex>
        }
        visible={isActionModalVisible}
        onOk={handleConfirmAction}
        onCancel={() => setIsActionModalVisible(false)}
        confirmLoading={loadingAction}>
        {isAccept === null ? (
          "Select an action first."
        ) : isAccept ? (
          "Are you sure you want to approve this transaction?"
        ) : (
          <>
            <p>Please provide rejection remarks:</p>
            <Input.TextArea
              value={rejectionRemarks}
              onChange={handleRejectReasonChange}
              placeholder="Enter rejection reason"
              rows={4}
              required
            />
          </>
        )}
      </Modal>
      {selectedTransaction && (
        <Modal
          className="custom-modal"
          title={
            <Flex justify="space-between" className="custom-modal-header">
              <Title
                level={3}
                style={{
                  color: "white",
                  margin: 0,
                  position: "relative",
                  marginLeft: "10px",
                }}>
                Remarks and Comments
              </Title>
              {/* <CloseOutlined
              style={{ fontSize: "16px", marginRight: "10px" }}
              onClick={handleModalClose}
            /> */}
            </Flex>
          }
          visible={isModalVisible}
          onCancel={handleModalClose}
          footer={null}>
          <Descriptions size="small" column={1}>
            {selectedTransaction.remarks && (
              <Descriptions.Item label="Remarks">
                {selectedTransaction.remarks}
              </Descriptions.Item>
            )}
            {selectedTransaction.rejection_remarks && (
              <Descriptions.Item label="Rejection Remarks">
                {selectedTransaction.rejection_remarks}
              </Descriptions.Item>
            )}
          </Descriptions>
          {selectedTransaction?.comments?.length > 0 ? (
            <>
              <p style={{ margin: 0, color: "gray" }}>Comments:</p>
              <div
                style={{
                  maxHeight: "200px", // Adjust the height as needed
                  overflowY: "auto",
                  border: "1px solid #d9d9d9",
                  borderRadius: "4px",
                  padding: "8px",
                  backgroundColor: "#fefefe",
                  width: "95%",
                  marginTop: "6px",
                }}>
                {selectedTransaction?.comments?.map((commentObj, index) => (
                  <div
                    key={commentObj.uid || index}
                    style={{
                      marginBottom: "8px",
                      display: "flex",
                      borderBottom: "1px solid #e5e7eb",
                      justifyContent: "space-between",
                    }}>
                    <div
                      style={{
                        marginBottom: "8px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}>
                      <p style={{ margin: 0 }}>{commentObj.comment}</p>
                      <small style={{ color: "gray" }}>
                        {moment(commentObj.created_at)
                          .local()
                          .format("DD-MM-YYYY hh:mm a")}
                      </small>
                    </div>
                    {commentObj.files.map((fileObj, fileIndex) => (
                      <div
                        key={fileObj.id || fileIndex}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "8px",
                        }}>
                        <Popover
                          content={<a>{fileObj.file}</a>}
                          title="File Preview"
                          trigger="click"
                          placement="bottom">
                          <Button type="link">View File</Button>
                        </Popover>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </>
          ) : null}

          {dropdownValue === "myTransactions" && (
            <Form
              layout="vertical"
              style={{ marginTop: "10px", width: "100%" }}>
              <Form.Item label="Add a Comment" style={{ width: "100%" }}>
                <Input.TextArea
                  value={commentText}
                  onChange={handleCommentChange} // Use the handler here
                  rows={3}
                  placeholder="Write your comment here..."
                />
              </Form.Item>
              <Form.Item label="Upload File" style={{ width: "100%" }}>
                <Dragger
                  label="file"
                  name="File"
                  multiple={false}
                  beforeUpload={() => false}
                  onChange={handleFileChange}
                  valuePropName="fileList"
                  getValueFromEvent={(e) =>
                    Array.isArray(e) ? e : e?.fileList
                  }>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag file to this area to upload
                  </p>
                </Dragger>
              </Form.Item>
              <Flex
                justify="flex-end"
                align="flex-end"
                style={{ width: "100%" }}>
                <Button
                  type="primary"
                  loading={commentSubmitting}
                  onClick={() =>
                    handleCommentSubmit(selectedTransaction.transaction_id)
                  }
                  style={{ background: "#001529", marginTop: 10 }}>
                  Submit
                </Button>
              </Flex>
            </Form>
          )}
        </Modal>
      )}
    </>
  );
};

export default TransactionsTable;
