// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ` 
  .custom-modal .custom-modal-header {
    color: white;
    background-color: #040724;
    padding: 10px 0px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    margin: 0;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }
  `, "",{"version":3,"sources":["webpack://./src/page-components/PaymentAndOperations/paymentOperations.css"],"names":[],"mappings":";EACE;IACE,YAAY;IACZ,yBAAyB;IACzB,iBAAiB;IACjB,2BAA2B;IAC3B,4BAA4B;IAC5B,SAAS;IACT,aAAa;IACb,WAAW;IACX,mBAAmB;IACnB,8BAA8B;EAChC","sourcesContent":[" \n  .custom-modal .custom-modal-header {\n    color: white;\n    background-color: #040724;\n    padding: 10px 0px;\n    border-top-left-radius: 8px;\n    border-top-right-radius: 8px;\n    margin: 0;\n    display: flex;\n    width: 100%;\n    align-items: center;\n    justify-content: space-between;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
