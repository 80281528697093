import React, { useState } from "react";
import {
  Tooltip,
  Spin,
  Popover,
  Button,
  Avatar,
  Tag,
  Card,
  Descriptions,
  Flex,
} from "antd";
import moment from "moment";
import { Link } from "react-router-dom";
import { EyeOutlined } from "@ant-design/icons";
import clientService from "../services/clientService";
import usePermissions from "../hooks/usePermissions";
import DynamicTable from "../page-components/All Clients/DynamicTable";
import { useMediaQuery } from "react-responsive";
// import "./Layout/layout.module.css"

const formatFieldName = (fieldName) => {
  return fieldName
    .replace(/_/g, " ") // Replace underscores with spaces
    .replace(/^\w/, (c) => c.toUpperCase()); // Capitalize the first letter
};

const formatDate = (date) => moment(date).local().format("DD-MM-YYYY HH:mm a"); // Convert to local time

const SearchDescriptionsList = ({ items, type }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
const [loading , setLoading] =useState(false)
  const { hasPermission } = usePermissions();

  const [expandedRows, setExpandedRows] = useState({});
  const [tasksData, setTasksData] = useState([]);
  const [loadingIndex, setLoadingIndex] = useState(null);

  const handleEyeClick = async (uid, index) => {
    setLoading(true)
    setExpandedRows((prev) => ({ ...prev, [index]: !prev[index] }));

    if (expandedRows[index]) return;

    setLoadingIndex(index);

    try {

      const tasksResponse = await clientService.getClientTasks(uid);
      if(tasksResponse.success){
        setTasksData((prevTasks) => [
            ...prevTasks,
            { index, tasks: tasksResponse.data.data.results },
          ]);
        setLoading(false)
      }
 
    } catch (error) {
        setLoading(false)
      console.error("Error fetching client details:", error);
    } finally {
        setLoading(false)
      setLoadingIndex(null);
    }
  };

  const getTasksForRow = (index) => {
    const rowData = tasksData.find((data) => data.index === index);
    return rowData ? rowData.tasks : [];
  };

  // Get headers from the first item
  const headers = items?.[0]
    ? Object.keys(items[0]).filter((key) => key !== "uid")
    : [];

  return (
    <div style={{ background: "#f2f6f9", padding: 14 }}>
      {/* For Mobile, Render Cards */}
      {isMobile ? (
        <div>
          {items?.map((item, index) => {
            const { uid, ...rest } = item;

            return (
              <div key={`${type}-${index}`}>
                <Card
                  style={{
                    marginBottom: 12,
                    background: "white",
                    borderRadius: "10px",
                    padding: "10px",
                    boxShadow:
                      "6px 6px 12px rgba(0, 0, 0, 0.1), -6px -6px 12px rgba(255, 255, 255, 0.7)",
                  }}
                  actions={[
                    type === "client" &&
                      hasPermission("can_view_client_details") && (
                        <EyeOutlined
                          style={{ cursor: "pointer", marginRight: "10px" }}
                          onClick={() => handleEyeClick(uid, index)}
                        />
                      ),
                  ]}>
                  {Object.entries(rest).map(([key, value]) => (
                    <Descriptions
                      key={key}
                      bordered
                      style={{ marginBottom: "8px" }}>
                      <Descriptions.Item label={formatFieldName(key)}>
                        {key === "payment_links" ? (
                          <Popover
                            content={
                              <ul>
                                {Array.isArray(value) &&
                                  value.map((link, idx) => (
                                    <li key={idx}>
                                      <a
                                        href={link}
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        {link}
                                      </a>
                                    </li>
                                  ))}
                              </ul>
                            }
                            title="Payment Links">
                            <Button type="link">View Links</Button>
                          </Popover>
                        ) : key === "created_at" ? (
                          formatDate(value)
                        ) : key === "key" ? (
                          <Link
                            to={`/order-management/my-orders/${value}`}
                            style={{ textDecoration: "underline" }}>
                            {value}
                          </Link>
                        ) : (
                          value
                        )}
                      </Descriptions.Item>
                    </Descriptions>
                  ))}
                </Card>
                {expandedRows[index] && (
                  <div
                    style={{
                      margin: "10px 0",
                      height: "100%",
                      padding: "10px",
                      background: "white",
                      borderRadius: "10px",
                      boxShadow:
                        "6px 6px 12px rgba(0, 0, 0, 0.1), -6px -6px 12px rgba(255, 255, 255, 0.7)",
                    }}>
                    {loadingIndex === index ? (
                      <div style={{ textAlign: "center" }}>
                        Loading tasks...
                      </div>
                    ) : (
                      <DynamicTable data={getTasksForRow(index)} />
                    )}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      ) : (
        <>
          <table
            style={{
              width: "100%",
              borderCollapse: "separate",
              borderSpacing: "0 10px",
              borderRadius: "10px",
            }}>
            {/* Table Header */}
            <thead>
              <tr
                style={{
                  background: "#f1f3f5",
                  padding: "4px 8px",
                  borderRadius: "6px",
                  fontWeight: "bold",
                  color: "#555",
                  marginBottom: 4,
                  textAlign: "left",
                }}>
                {headers.map((key) => (
                  <th key={key} style={{ padding: "8px", textAlign: "left" }}>
                    {key === "word" ? "Word Count" : formatFieldName(key)}
                  </th>
                ))}
              </tr>
            </thead>

            {/* Table Body */}
            <tbody style={{ width: "100%", borderRadius: "10px" }}>
              {items?.map((item, index) => {
                const { uid, ...rest } = item;

                return (
                  <tr
                    key={`${type}-${index}`}
                    style={{
                      background: "white",

                      boxShadow:
                        "6px 6px 12px rgba(0, 0, 0, 0.1), -6px -6px 12px rgba(255, 255, 255, 0.7)",
                      padding: "15px 10px",
                      textAlign: "left",
                    }}>
                    {Object.entries(rest).map(([key, value]) => {
                      const displayValue =
                        key === "title" && value.length > 80
                          ? value.slice(0, 80) + "..."
                          : value;

                      return (
                        <td
                          key={key}
                          style={{
                            padding: "10px",
                            textAlign: "left",
                            borderRadius: "10px",
                          }}>
                          <Tooltip
                            key={key}
                            title={
                              key === "ownership" || key === "visibility"
                                ? value
                                : null
                            }
                            placement="topLeft">
                            <div
                              style={{
                                margin: 0,
                                padding: 0,
                                wordBreak: "break-word",
                              }}>
                              {key === "ownership" || key === "visibility" ? (
                                Array.isArray(value) && value.length > 2 ? (
                                  <Popover
                                    content={
                                      <ul>
                                        {value.slice(2).map((name, idx) => (
                                          <li key={idx}>{name}</li>
                                        ))}
                                      </ul>
                                    }
                                    title="Visibility">
                                    <div>
                                      {value.slice(0, 2).map((name, idx) => (
                                        <Avatar
                                          key={idx}
                                          style={{
                                            backgroundColor: "#87d068",
                                            verticalAlign: "middle",
                                            marginRight: 5,
                                          }}
                                          size="small">
                                          {name
                                            .split(" ")
                                            .map((n) => n[0].toUpperCase())
                                            .slice(0, 2)
                                            .join("")}
                                        </Avatar>
                                      ))}
                                      {value.length > 2 && (
                                        <span> +{value.length - 2}</span>
                                      )}
                                    </div>
                                  </Popover>
                                ) : (
                                  <div>
                                    {Array.isArray(value) ? (
                                      value.map((name, idx) => (
                                        <Avatar
                                          key={idx}
                                          style={{
                                            backgroundColor: "#87d068",
                                            verticalAlign: "middle",
                                            marginRight: 5,
                                          }}
                                          size="small">
                                          {name
                                            .split(" ")
                                            .map((n) => n[0].toUpperCase())
                                            .slice(0, 2)
                                            .join("")}
                                        </Avatar>
                                      ))
                                    ) : (
                                      <Avatar
                                        style={{
                                          backgroundColor: "#87d068",
                                          verticalAlign: "middle",
                                        }}
                                        size="small">
                                        {value
                                          .split(" ")
                                          .map((n) => n[0].toUpperCase())
                                          .slice(0, 2)
                                          .join("")}
                                      </Avatar>
                                    )}
                                  </div>
                                )
                              ) : key === "created_at" ? (
                                formatDate(value)
                              ) : key === "word" ? (
                                <Tag color="orange">{value}</Tag>
                              ) : key === "name" ? (
                                <>
                                  {type === "client" &&
                                    hasPermission(
                                      "can_view_client_details"
                                    ) && (
                                      <EyeOutlined
                                        style={{
                                          cursor: "pointer",
                                          justifySelf: "start",
                                          marginRight: "10px",
                                        }}
                                        onClick={() =>
                                          handleEyeClick(uid, index)
                                        }
                                      />
                                    )}
                                  {value}
                                </>
                              ) : key === "key" ? (
                                <Link
                                  to={`/order-management/my-orders/${value}`}
                                  style={{ textDecoration: "underline" }}>
                                  {value}
                                </Link>
                              ) : key === "email" ? (
                                <Popover content={value} title="Email">
                                  <Button type="link">View Email</Button>
                                </Popover>
                              ) : key === "website" ? (
                                <Tag color="green">{value}</Tag>
                              ) : key === "payment_links" &&
                                Array.isArray(value) ? (
                                <Popover
                                  content={
                                    <ul>
                                      {value.map((link, idx) => (
                                        <li key={idx}>
                                          <a
                                            href={link}
                                            target="_blank"
                                            rel="noopener noreferrer">
                                            {link}
                                          </a>
                                        </li>
                                      ))}
                                    </ul>
                                  }
                                  title="Payment Links">
                                  <Button type="link">View Links</Button>
                                </Popover>
                              ) : (
                                displayValue
                              )}
                            </div>
                          </Tooltip>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
          {items?.map((item, index) => {
            const { uid } = item;

            return (
              expandedRows[index] && (
                <div
                  key={`${type}-${index}-expanded`}
                  style={{
                    margin: "10px 0",
                    padding: "10px",
                    background: "white",
                    borderRadius: "10px",
                    boxShadow:
                      "6px 6px 12px rgba(0, 0, 0, 0.1), -6px -6px 12px rgba(255, 255, 255, 0.7)",
                  }}>

                    {loading ? <Flex  justify="center" align="center" style={{width:'100%' }}><Spin size="large"></Spin></Flex> :   <div style={{ display: "flex", flexDirection: "column" }}>
                    <DynamicTable data={getTasksForRow(index)} />
                  </div>}
               
                </div>
              )
            );
          })}
        </>
      )}
    </div>
  );
};

export default SearchDescriptionsList;
