import React, { useEffect, useState } from "react";
import FixedOrderDetails from "../../page-components/OrderDetail/FixedOrderDetails";
import FixedDetails2 from "../../page-components/OrderDetail/FixedDetails2";
import CustomTabs from "../../page-components/OrderDetail/Tabs";
import orderService from "../../services/OrderLists";
import { useLocation, useParams } from "react-router-dom";
import { Spin, Flex, message } from "antd";
import CallFunc from "../../page-components/OrderDetail/CallFunc";
import { useSelector } from "react-redux";
import { catchError } from "../../utils/errorHandler";
import axiosInstance from "../../services/axiosInstance";
import usePermissions from "../../hooks/usePermissions";
import _ from "lodash";

function OrderDetail() {
  const [orderData, setOrderData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [activeOrderTab, setActiveOrderTab] = useState("Overview");
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState({
    orderFiles: false,
    solutionFiles: false,
    comments: false,
    feedback: false,
  });
  const { hasPermission } = usePermissions();

  const [solutionFiles, setSolutionFiles] = useState({
    finalFilesData: [],
    similarityReportData: [],
    otherFilesData: [],
  });
  const [comments, setComments] = useState({
    client_comments: [],
    fl_comments: [],
    internal_comments: [],
  });
  const [feedback, setFeedback] = useState([]);

  const { orderKey } = useParams();

  useEffect(() => {
    fetchOrderDetails();
  }, [orderKey]);

  const fetchOrderDetails = async () => {
    try {
      setIsLoading(true);
      const result = await axiosInstance.get(`task/v2/detail/${orderKey}`);
      setOrderData(_.get(result, 'data', null)); // Fallback to null if data is unavailable
      setErrorMessage(null);
    } catch (error) {
      const status = _.get(error, 'response.status', 500); // Default to 500 if status is undefined
      if (status === 402) {
        setIsLoading(false);
      } else if (status === 401) {
        setErrorMessage("Unauthorized access - please check your permissions.");
        setIsLoading(false);
      } else {
        await catchError(error);
        setIsLoading(false);
      }
    } finally {
      setIsLoading(false);
    }
  };
  const getSolutionFiles = _.debounce(async () => {
    try {
      setLoading((prev) => ({ ...prev, solutionFiles: true }));
      const result = await orderService.getSolutionFiles(orderKey);
  
      if (_.get(result, 'success') && _.get(result, 'data.status') === "success") {
        const { final_files, other_files, similarity_files } = _.get(result, 'data.data', {});
        setSolutionFiles({
          finalFilesData: final_files || [],
          similarityReportData: similarity_files || [],
          otherFilesData: other_files || [],
        });
      }
    } catch (error) {
      console.error("Error fetching solution files:", error);
    } finally {
      setLoading((prev) => ({ ...prev, solutionFiles: false }));
    }
  }, 300); // Debounced to prevent multiple calls in quick succession
  
  // const getSolutionFiles = async () => {
  //   try {
  //     setLoading((prev) => ({ ...prev, solutionFiles: true }));
  //     const result = await orderService.getSolutionFiles(orderKey);
  //     if (_.get(result, 'success') && _.get(result, 'data.status') === "success") {
  //       setLoading((prev) => ({ ...prev, solutionFiles: false }));
  //       const { final_files, other_files, similarity_files } = _.get(result, 'data.data', {});
  //       setSolutionFiles({
  //         finalFilesData: final_files || [],
  //         similarityReportData: similarity_files || [],
  //         otherFilesData: other_files || [],
  //       });
  //     }
  //   } catch (error) {
  //     setLoading((prev) => ({ ...prev, solutionFiles: false }));
  //   } finally {
  //     setLoading((prev) => ({ ...prev, solutionFiles: false }));
  //   }
  // };

  // const fetchOrderDetails = async () => {
  //   try {
  //     setIsLoading(true);
  //     const result = await axiosInstance.get(`task/v2/detail/${orderKey}`);

  //     if (result.status === 200) {
  //       setOrderData(_.get(result, 'data', null));
  //       setErrorMessage(null); 
  //     }
  //   } catch (error) {
  //     const status = _.get(error, 'response.status');
  //     if (status === 402) {
  //       setIsLoading(false);
  //     } else if (status === 401) {
  //       setErrorMessage("Unauthorized access - please check your permissions.");
  //       setIsLoading(false);
  //     } else {
  //       await catchError(error);
  //       setIsLoading(false);
  //     }
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };
  const handleTabChange = async (key) => {
    if (key === "1") {
      setActiveOrderTab("Overview");
    } else if (key === "2") {
      setActiveOrderTab("SolutionFiles");
      getSolutionFiles(); // Debounced to prevent redundant calls
    } else if (key === "3") {
      setLoading((prev) => ({ ...prev, comments: true }));
      setActiveOrderTab("Comments");
  
      const commentTypes = [
        { key: "client_comments", permission: "can_view_client_comments", fetchFunc: orderService.getClientComments },
        { key: "internal_comments", permission: "can_view_internal_comments", fetchFunc: orderService.getInternalComments },
        { key: "fl_comments", permission: "can_view_freelancer_comments", fetchFunc: orderService.getFreelancerComments },
      ];
  
      await Promise.all(
        commentTypes.map(async ({ key, permission, fetchFunc }) => {
          if (hasPermission(permission)) {
            const result = await fetchFunc(orderKey);
            if (_.get(result, 'data.status') === "success") {
              setComments((prev) => ({
                ...prev,
                [key]: _.get(result, 'data.data', []),
              }));
            }
          }
        })
      );
  
      setLoading((prev) => ({ ...prev, comments: false }));
    } else if (key === "4") {
      setLoading((prev) => ({ ...prev, feedback: true }));
      setActiveOrderTab("Feedback");
      const result = await orderService.getOrderFeedback(orderKey);
      if (_.get(result, 'data.status') === "success") {
        setFeedback(_.get(result, 'data.data', []));
      }
      setLoading((prev) => ({ ...prev, feedback: false }));
    }
  };
  
  // const handleTabChange = async (key) => {
  //   if (key === "1") {
  //     setActiveOrderTab("Overview");
  //   } else if (key === "2") {
  //     setActiveOrderTab("SolutionFiles");
  //     getSolutionFiles();
  //   } else if (key === "3") {
  //     setLoading((prev) => ({ ...prev, comments: true }));
  //     setActiveOrderTab("Comments");

  //     const commentTypes = [
  //       { key: "client_comments", permission: "can_view_client_comments", fetchFunc: orderService.getClientComments },
  //       { key: "internal_comments", permission: "can_view_internal_comments", fetchFunc: orderService.getInternalComments },
  //       { key: "fl_comments", permission: "can_view_freelancer_comments", fetchFunc: orderService.getFreelancerComments }
  //     ];

  //     for (let { key, permission, fetchFunc } of commentTypes) {
  //       if (hasPermission(permission)) {
  //         const result = await fetchFunc(orderKey);
  //         if (_.get(result, 'data.status') === "success") {
  //           setComments((prev) => ({
  //             ...prev,
  //             [key]: _.get(result, 'data.data', []),
  //           }));
  //         }
  //       }
  //     }
  //     setLoading((prev) => ({ ...prev, comments: false }));
  //   } else if (key === "4") {
  //     setLoading((prev) => ({ ...prev, feedback: true }));
  //     setActiveOrderTab("Feedback");
  //     const result = await orderService.getOrderFeedback(orderKey);
  //     if (_.get(result, 'data.status') === "success") {
  //       setFeedback(_.get(result, 'data.data', []));
  //     }
  //     setLoading((prev) => ({ ...prev, feedback: false }));
  //   }
  // };

  const { user } = useSelector((state) => state.auth);
  if (!user) {
    console.log("No user permissions available in order Details Main Page");
    return [];
  }

  const permissions = user?.user?.permissions;
  if (errorMessage) {
    return (
      <div style={{ textAlign: "center", padding: "20px" }}>
        <h2>{errorMessage}</h2>
      </div>
    );
  }

  return isLoading ? (
    <Flex
      justify="center"
      align="center"
      style={{ width: "100%", height: "80vh" }}>
      <Spin size="large" />
    </Flex>
  ) : (
    <>
      <FixedOrderDetails orderData={orderData} />
      <FixedDetails2 orderData={orderData} />
      <CustomTabs
        solutionFiles={solutionFiles}
        orderData={orderData}
        setActiveOrderTab={setActiveOrderTab}
        orderKey={orderData?.data?.data?.key}
        moveToPortal={orderData?.data?.moved_to_freelancer_portal}
        orderDataComments={comments}
        setOrderDataComments={setComments}
        setLoading={setLoading}
        setSolutionFiles={setSolutionFiles}
        loading={loading}
        handleTabChange={handleTabChange}
        feedbackData={{ feedback }}
        getSolutionFiles={getSolutionFiles}
        finalFilesData={solutionFiles.finalFilesData}
        similarityReportData={solutionFiles.similarityReportData}
        otherFilesData={solutionFiles.otherFilesData}
        fetchOrderDetails={fetchOrderDetails}
      />
      <div style={{ position: "fixed", bottom: "30px", right: "35px" }}>
        <CallFunc
          orderData={orderData}
          activeOrderTab={activeOrderTab}
          permissions={permissions}
          fetchOrderDetails={fetchOrderDetails}
          handleTabChange={handleTabChange}
          getSolutionFiles={getSolutionFiles}
        />
      </div>
    </>
  );
}

export default OrderDetail;
