import { Flex } from "antd";
import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const OrderBudgetAndAmountDetails = ({ data }) => {
  const orderAnalytics = data?.order_analytics || {};


  const { order_budget = [], amount_paid = [] } = orderAnalytics;

  // Combine order_budget and amount_paid for the graph
  const graphData = order_budget.map((budgetItem, index) => ({
    currency: budgetItem?.currency,
    budget: budgetItem?.amount || 0,
    paid: amount_paid[index]?.amount || 0,
    remaining: (budgetItem?.amount || 0) - (amount_paid[index]?.amount || 0),
  }));

  if (!order_budget.length || !amount_paid.length) {
    return <div>No data available</div>;
  }
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length > 0) {
      const { currency, budget, paid, remaining } = payload[0].payload;
  
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            background: "#f3f4f6",
            minWidth: "210px",
            padding: "10px",
            borderRadius: "10px",
            border: "1px solid #9ca3af",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            gap: "10px",
          }}
        >

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>Total Budget:</span>
            <strong>{budget.toLocaleString()}</strong>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>Amount Paid:</span>
            <strong style={{ color: "#36c6d3" }}>{paid.toLocaleString()}</strong>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>Remaining:</span>
            <strong style={{ color: "#000" }}>{remaining.toLocaleString()}</strong>
          </div>
        </div>
      );
    }
    return null;
  };
  return (
    <Flex vertical style={{ width: "100%" }}>
      <ResponsiveContainer width="100%" height={300}      style={{ backgroundColor: "transparent" }}>
        <BarChart
       style={{ backgroundColor: "transparent" }}
          data={graphData}
          margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
        >
          <XAxis dataKey="currency" />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          {/* Green part for Amount Paid */}
          <Bar dataKey="paid" stackId="a" fill="#36c6d3" name="Amount Paid"  barSize={40}/>
          {/* Gray part for remaining budget */}
          <Bar dataKey="remaining" stackId="a" fill="#d9d9d9" name="Remaining Budget" barSize={40}/>
        </BarChart>
      </ResponsiveContainer>
    </Flex>
  );
};

export default OrderBudgetAndAmountDetails;
