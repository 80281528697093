import React, { useState } from "react";
import { Input, Button, Select, DatePicker } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import useIsMobile from "../../hooks/useIsMobile";

const { Option } = Select;

const SearchFiltersAllocation = ({
  onSearch,
  pagination,
  getAllocation,
  filtersList = [],
}) => {
  const { isMobile } = useIsMobile();

  const [filters, setFilters] = useState({
    key: "",
    flag_status: "",
    start_date: "",
    end_date: "",
    is_urgent: "",
    called_by: "",
  });

  const handleSearch = () => {
    const formattedFilters = {
      ...filters,
      start_date: filters.start_date
        ? dayjs(filters.start_date).format("YYYY-MM-DD")
        : null,
      end_date: filters.end_date
        ? dayjs(filters.end_date).format("YYYY-MM-DD")
        : null,
    };
    onSearch(formattedFilters);
  };

  const handleFilterChange = (key, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));
  };

  const handleReset = async () => {
    setFilters({
      key: "",
      flag_status: "",
      start_date: "",
      end_date: "",
      is_urgent: "",
      called_by: "",
    });
    await getAllocation(1, pagination.pageSize);
  };

  const renderFilter = (filter) => {
    switch (filter) {
      case "flag_status":
        return (
          <Select
            placeholder="Filter by Flag Status"
            style={{ width: isMobile ? "80vw" : 300 }}
            value={filters.flag_status || undefined}
            onChange={(value) => handleFilterChange("flag_status", value)}
          >
            <Option value="Open">Open</Option>
            <Option value="Closed">Closed</Option>
          </Select>
        );
      case "start_date":
        return (
          <DatePicker
            style={{ width: isMobile ? "80vw" : 300 }}
            placeholder="Filter by Start Date"
            value={filters.start_date ? dayjs(filters.start_date) : null}
            onChange={(value) => handleFilterChange("start_date", value)}
          />
        );
      case "end_date":
        return (
          <DatePicker
            style={{ width: isMobile ? "80vw" : 300 }}
            placeholder="Filter by End Date"
            value={filters.end_date ? dayjs(filters.end_date) : null}
            onChange={(value) => handleFilterChange("end_date", value)}
          />
        );
      case "is_urgent":
        return (
          <Select
            placeholder="Filter by Urgency"
            style={{ width: isMobile ? "80vw" : 300 }}
            value={filters.is_urgent || undefined}
            onChange={(value) => handleFilterChange("is_urgent", value)}
          >
            <Option value="true">Yes</Option>
            <Option value="false">No</Option>
          </Select>
        );
      case "called_by":
        return (
          <DatePicker
            style={{ width: isMobile ? "80vw" : 300 }}
            placeholder="Filter by Called By"
            value={filters.called_by ? dayjs(filters.called_by) : null}
            onChange={(value) => handleFilterChange("called_by", value)}
          />
        );
      default:
        return (
          <Input
            placeholder={`Filter by ${filter}`}
            style={{ width: 300 }}
            value={filters[filter] || ""}
            onChange={(e) => handleFilterChange(filter, e.target.value)}
          />
        );
    }
  };

  const isWebsiteFilterPresent = filtersList.includes("is_urgent");

  return (
    <div style={{ width: "100%" }}>
      <div
        style={{
          background: "#eceff1",
          padding: "20px",
          borderRadius: "8px",
        }}
      >
        <div
          style={{
            marginBottom: 16,
            display: "flex",
            flexWrap: "wrap",
            gap: "20px",
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}
        >
          {filtersList.map((filter) => (
            <div key={filter} style={{ textAlign: "left", marginRight: 16 }}>
              {renderFilter(filter)}
            </div>
          ))}

          <div
            style={{
              display: "flex",
              gap: "20px",
              marginRight: isMobile ? 0 : "12px",
              justifyContent: isWebsiteFilterPresent
                ? "flex-end"
                : "space-between",
              width: isWebsiteFilterPresent ? "100%" : "auto",
            }}
          >
            <Button
              type="primary"
              style={{ width: 140, background: "#040724" }}
              icon={<SearchOutlined />}
              onClick={handleSearch}
            >
              Search
            </Button>
            <Button style={{ width: 140 }} type="default" onClick={handleReset}>
              Reset
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchFiltersAllocation;
