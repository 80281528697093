import React, { useState, useEffect } from "react";
import { Tooltip, Spin, Descriptions ,Empty} from "antd";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  Legend,
  ResponsiveContainer,
} from "recharts"; // Import Recharts components

const CountryWiseTaskAndSalesMap = ({ data }) => {
  const [loading, setLoading] = useState(true);

  const countryAnalytics = data?.country_analytics || [];

  useEffect(() => {
    if (countryAnalytics.length > 0) {
      setLoading(false); // Stop loading when data is available
    } else {
      setLoading(false); // Stop loading if no country_analytics
    }
  }, [countryAnalytics]);

  // Prepare data for the bar chart
  let chartData = countryAnalytics.map((countryData) => ({
    country: countryData.country || "Unknown", // Group all null or unknown country names as "Unknown"
    task_count: countryData.task_count,
    // Calculate the total sales value and keep an array of sales details (amount and currency)
    sales_value: countryData.sales_value || [],
  }));

  // Group all "Unknown" countries together and label them as "Others"
  const unknownCountryData = chartData.filter(
    (item) => item.country === "Unknown"
  );
  const otherCountryData = chartData.filter(
    (item) => item.country !== "Unknown"
  );

  chartData = [
    ...otherCountryData,
    {
      country: "Others", // Use "Others" instead of "Unknown"
      task_count: unknownCountryData.reduce(
        (sum, item) => sum + item.task_count,
        0
      ),
      sales_value: unknownCountryData.reduce(
        (sales, item) => [...sales, ...item.sales_value], // Concatenate all sales from unknown countries
        []
      ),
    },
  ];
  const CustomTooltip = ({ payload, label, active }) => {
    if (active && payload && payload.length) {
      const { task_count, sales_value } = payload[0].payload;

      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            background: "#f3f4f6",
            minWidth: "210px",
            backdropFilter: "10px",
            padding: "10px",
            borderRadius: "10px",
            border: "1px solid #9ca3af",
            gap: "10px",
          }}>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}>
            <div
              style={{
                display: "flex",
                width: "100%",
                flexDirection: "column",
              }}>
              <p style={{ margin: 0 }}>Task Count</p>
              <strong>{task_count}</strong>
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                flexDirection: "column",
                justifyContent: "flex-end",
                textAlign: "right",
              }}>
                  <p style={{ margin: 0 }}>Country</p>
                  <strong>{label}</strong>
            </div>
          </div>

          <div
            style={{
              backdropFilter: "10px",
              background: "white",
              border: "1px solid #9ca3af",
              padding: "10px",
              borderRadius: "10px",
            }}>
            {sales_value && sales_value.length > 0 ? (
              sales_value.map((sale, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }}>
                  <p style={{ margin: 0, color: "gray" }}>{sale.currency}</p>
                  <strong>{sale.amount}</strong>
                </div>
              ))
            ) : (
              <p>No Sales</p>
            )}
          </div>
        </div>
      );
    }

    return null;
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}>
        <Spin size="large" />
      </div>
    );
  }
  if (chartData.length === 0) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}>
        <Empty description="No Data Available" />
      </div>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}>
      <div style={{ width: "100%", height:'auto' }}>
        <ResponsiveContainer width="100%" height={500}>
          <BarChart
            barGap={"100px"}
            width={"100%"}
            layout="vertical"
            data={chartData}
 
            style={{ backgroundColor: "transparent" }} // Remove the background by setting it to transparent
        >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis type="number" />
            <YAxis
              type="category"
              dataKey="country"
              // Set the width of the Y-axis if needed
              tick={{
                fontSize: 10, // Smaller font size
                whiteSpace: "nowrap", // Prevent text wrapping
                overflow: "hidden", // Hide overflow if text is too long
                textOverflow: "ellipsis", // Add ellipsis for long text
                textAlign: "center", // Center align the text
              }}
              tickLine={false} // Hide the tick line if you want a cleaner look
              interval={0} // Show all ticks
              style={{
                overflow: "visible", // Ensure tick labels are visible if they're long
                wordBreak:0
              }}
            />
            <RechartsTooltip content={<CustomTooltip />} />
            {/* <Legend /> */}
            <Bar dataKey="task_count" fill="#36c6d3" barSize={20} />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default CountryWiseTaskAndSalesMap;
