import axiosInstance from "./axiosInstance";
import { catchError } from "../utils/errorHandler";

const bdmAnalyticsService = {
  fetchBdmAnalyticsData: async (startDate, endDate, monthYear, year ,uids) => {
    try {
      // Create an object to hold the query parameters
      const params = {};

      // Conditionally add parameters to the params object
      if (startDate) params.start_date = startDate;
      if (endDate) params.end_date = endDate;
      if (monthYear) params.month = monthYear;  // Example format: '2025-01'
      if (year) params.year = year;

      // Build the query string from the params object
      const queryString = new URLSearchParams(params).toString();
      const body = { 
        uids: Array.isArray(uids) ? uids : []
      };
      // Make the API request with the query string if there are parameters
      const url = `/analytics/bdm/?${queryString}`;
      const response = await axiosInstance.post(url ,body);

      if (response.status === 200) {
        return { success: true, data: response.data };
      }

      return { success: false, message: "Unexpected response status" };
    } catch (error) {
      await catchError(error); // Assuming catchError logs and handles errors
      console.error(error.response, 'Error response');
      return { success: false, message: error?.response?.data?.message || "An error occurred" };
    }
  },
};

export default bdmAnalyticsService;
