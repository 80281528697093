import React, { useState } from "react";
import { List, Tag, Button, Checkbox } from "antd";
import { ClockCircleOutlined } from "@ant-design/icons";
import { Flex } from "antd";
import { Circle, Dot } from "lucide-react";
import moment from "moment";
const NotificationList = ({
  notifications,
  onMarkAsRead,
  onNotificationClick,
}) => {
  const [expandedItems, setExpandedItems] = useState({});

  const toggleExpand = (uid) => {
    setExpandedItems((prev) => ({
      ...prev,
      [uid]: !prev[uid],
    }));
  };

  const renderPreview = (preview, uid) => {
    const words = preview.split(" ");
    const isExpanded = expandedItems[uid];
    const shouldCollapse = words.length > 16;

    if (shouldCollapse && !isExpanded) {
      return (
        <>
          {words.slice(0, 16).join(" ")}...
          <Button
            type="link"
            style={{ padding: 0 }}
            onClick={(e) => {
              e.stopPropagation();
              toggleExpand(uid);
            }}>
            Show More
          </Button>
        </>
      );
    }

    return (
      <>
        {preview}
        {shouldCollapse && (
          <Button
            type="link"
            style={{ padding: 0 }}
            onClick={(e) => {
              e.stopPropagation();
              toggleExpand(uid);
            }}>
            Show Less
          </Button>
        )}
      </>
    );
  };

  return (
    <List
      style={{ width: "100%", background: "transparent", height: "100%" }}
      dataSource={notifications}
      renderItem={(item) => (
        <List.Item
          style={{
            borderBottom: "1px solid #D1D5DB",
            borderRadius: 4,
            padding: 16,
            marginBottom: 12,
            cursor: "pointer",
          }}
          onClick={() => onNotificationClick(item)}>
          <Flex
            justify="space-between"
            align="center"
            style={{ width: "100%" }}>
            <Flex style={{ width: "80%" }} align="flex-start">
              <Flex style={{ maxWidth: "80%" }} vertical align="flex-start">
                
                <Flex align="flex-start">
                  {" "}
                  <p style={{ color: "#595959", margin: 0, padding: 0 }}>
                    {item.verb}
                  </p>
                </Flex>

                <p
                  style={{
                    color: "#9ca3af",
                    margin: 0,
                    padding: 0,
                    textAlign: "left",
                  }}>
                  "{renderPreview(item?.data?.preview, item.uid)}"
                </p>
              </Flex>
            </Flex>
            <Flex
             vertical style={{height:'max-content'}} align="flex-end" justify="flex-end">
                {item?.data?.type ? (
                  <Tag color="#040724" style={{width:'max-content'}}>{item.data.type}</Tag>
                ) : null}
                <Flex align="center" >
                <Dot color="#1C64F2" size={40} />
               
      

               <p style={{ color: "#8c8c8c", fontSize: 12 , margin:0,padding:0}}>
                 <ClockCircleOutlined />{" "}
                 {moment(item.timestamp).local().format("DD-MM-YYYY hh:mm a")}
               </p>
                </Flex>
      
            </Flex>
          </Flex>
        </List.Item>
      )}
    />
  );
};

export default NotificationList;
