import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
console.log("Current Environment:", process.env.NODE_ENV);
// In your index.js or App.js
if (process.env.NODE_ENV === "production") {
  console.log("profdeced");

  // Add inline styles dynamically in production mode
  const prodStyle = document.createElement("style");
  prodStyle.innerHTML = `
    body > iframe {
      display: none !important;
    }
  `;
  document.head.appendChild(prodStyle);

  window.addEventListener("error", (event) => {
    event.preventDefault(); // Prevent the error overlay
  });

  window.addEventListener("unhandledrejection", (event) => {
    event.preventDefault(); // Prevent unhandled promise rejection display
  });
} else {
  // const prodStyle = document.createElement("style");
  // prodStyle.innerHTML = `
  //   body > iframe {
  //     display: none!important;
  //   }
  // `;
  // document.head.appendChild(prodStyle);

  // window.addEventListener("error", (event) => {
  //   event.preventDefault(); // Prevent the error overlay
  // });
  console.log("devl");
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
