import React, { useEffect } from "react";
import "./App.css";
import { Provider } from "react-redux";
import store, { persistor } from "./redux/store";
import { ConfigProvider } from "antd";
import { customTheme } from "./customTheme";
import { PersistGate } from "redux-persist/integration/react";
import { MainLayout } from "./components/Layout/MainLayout";
import { fetchPermissions } from "./redux/slices/authSlice";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ErrorBoundary } from "react-error-boundary";

const App = () => {
 


  return (
<ErrorBoundary fallback={<div>Something went wrong</div>}>
      <ConfigProvider theme={customTheme}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <MainLayout />
          </PersistGate>
        </Provider>
      </ConfigProvider>
    </ErrorBoundary>
  );
};

export default App;
