import React from "react";
import { Tabs, Tag, Button, Switch } from "antd";
import { CheckCheck } from "lucide-react";

const categories = [
  { key: "urgent", label: "Urgent", color: "#f87171" },
  { key: "highPriority", label: "High Priority", color: "#fdba74" },
  { key: "feedbacks", label: "Feedbacks", color: "#93c5fd" },
  { key: "resolved", label: "Resolved", color: "#d8b4fe" },
  { key: "info", label: "Info", color: "#cbd5e1" },
];

const NotificationsTabs = ({
  activeTab,
  onTabChange,
  onShowUnreadToggle,
  showUnread,
}) => {
  return (
    <Tabs
      style={{ width: "100%" }}
      activeKey={activeTab}
      onChange={onTabChange}
      tabBarStyle={{ marginBottom: 16 }}
      tabBarExtraContent={
        <div style={{ display: "flex", alignItems: "center", gap: 16 }}>
          <Switch
            checked={showUnread}
            checkedChildren="Show All Unread" unCheckedChildren="Show All Unread"
            onChange={onShowUnreadToggle}
         
          />
     
          <Button
            type="link"
            icon={<CheckCheck />}
            style={{ padding: 0, marginRight: 16 }}
          >
            Mark all as read
          </Button>
        </div>
      }
    >
      {categories.map((category) => (
        <Tabs.TabPane
          key={category.key}
          tab={
            <span>
              <Tag color={category.color}>{category.label}</Tag>
            </span>
          }
        />
      ))}
    </Tabs>
  );
};

export default NotificationsTabs;
