import React, { useState } from "react";
import {
  Table,
  Tag,
  Modal,
  Form,
  Input,
  Button,
  InputNumber,
  message,
  Typography,
} from "antd";
import { useNavigate } from "react-router-dom";
import "./DynamicTable.css";
import clientService from "../../services/clientService";
import usePermissions from "../../hooks/usePermissions";
import moment from "moment";

const DynamicTable = ({ data, activeTab, username, uid }) => {
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { hasPermission } = usePermissions();
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [splitAmount, setSplitAmount] = useState({
    username: "",
    splitAmount: "",
    splitRemarks: "",
    currency: "", // Add currency to the state for display purposes
  });
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  const handleOk = async () => {
    try {
      setIsLoading(true);
      const values = await form.validateFields();
      console.log("Split values:", values);

      if (selectedRecord) {
        const splitData = {
          username: values.username,
          splitAmount: values.splitAmount, // Only sending the numeric value
          splitRemarks: values.splitRemarks,
        };

        const response = await clientService.splitTransaction(
          selectedRecord.transaction_id,
          splitData
        );

        if (response.success) {
          message.success("Transaction split successfully");
          setIsLoading(false);
          setIsModalVisible(false);
          form.resetFields();
          // You might want to refresh the data here
        } else {
          setIsLoading(false);
          // message.error("Failed to split transaction");
        }
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Validation failed:", error);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const handleSplit = (record) => {
    setSelectedRecord(record);
    setIsModalVisible(true);
    setSplitAmount({
      ...splitAmount,
      username: "", // Reset the username value
      splitAmount: record.amount, // The numeric value of the amount to be split
      splitRemarks: "", // Reset remarks value
      currency: record.currency, // Set the currency symbol for display
    });
    form.setFieldsValue({
      username: splitAmount.username,
      splitAmount: record.amount, // Display the amount in the field
      splitRemarks: splitAmount.splitRemarks,
    });
  };

  const columns =
    data && data.length > 0
      ? Object.keys(data[0])
          .filter((key) => key !== "date_of_transaction") // Exclude 'date_of_transaction' here
          .map((key) => {
            if (key === "transaction_id") {
              return {
                title: "Transaction ID",
                dataIndex: key,
                key,
                render: (transactionId, record) => {
                  const formattedDate = moment(record.date_of_transaction);
                  const isValidDate = formattedDate.isValid(); // Check if the date is valid

                  return (
                    <div>
                      <span>{transactionId}</span>
                      <br />
                      <span style={{ fontSize: "12px", opacity: 0.6 }}>
                        {isValidDate
                          ? formattedDate.local().format("YYYY-MM-DD hh:mm A")
                          : "No Date Updated"}
                      </span>
                    </div>
                  );
                },
              };
            }

            if (key === "deadline_soft") {
              return {
                title: "Deadline Soft",
                dataIndex: key,
                key,
                render: (record) => (
                  <div>
                    {moment(record).local().format("YYYY-MM-DD hh:mm A")}
                  </div>
                ),
              };
            }
            if (key === "action_at") {
              return {
                title: "Action at",
                dataIndex: key,
                key,
                render: (record) => (
                  <div>
                    {moment(record).local().format("YYYY-MM-DD hh:mm A")}
                  </div>
                ),
              };
            }

            if (key === "is_completely_linked") {
              return {
                title: "Completely Linked",
                dataIndex: key,
                key,
                render: (record) => (
                  <div>
                    <Tag color={record ? "green" : "red"}>
                      {record ? "YES" : "NO"}
                    </Tag>
                  </div>
                ),
              };
            }

            if (key === "created_at") {
              return {
                title: "Created at",
                dataIndex: key,
                key,
                render: (record) => (
                  <div>
                    {moment(record).local().format("YYYY-MM-DD hh:mm A")}
                  </div>
                ),
              };
            }

            if (key === "status") {
              return {
                title: "Status",
                dataIndex: key,
                key,
                render: (status) => {
                  if (status === null || status === undefined) {
                    return <Tag color="default">N/A</Tag>;
                  }

                  let color = "blue";
                  if (status.toLowerCase() === "completed") {
                    color = "green";
                  } else if (status.toLowerCase() === "not completed") {
                    color = "red";
                  }

                  return <Tag color={color}>{status.toUpperCase()}</Tag>;
                },
              };
            }

            if (key === "key") {
              return {
                title: "KEY",
                dataIndex: key,
                key,
                render: (text, record) => (
                  <a
                    onClick={() =>
                      navigate(`/order-management/my-orders/${record.key}`)
                    }>
                    {text}
                  </a>
                ),
              };
            }

            // Conditionally render the 'SPLIT STATUS' column based on permissions and status values
            if (key === "is_splited") {
              return {
                title: "SPLIT STATUS",
                dataIndex: key,
                key,
                render: (value, record) => {
                  // Check if the transaction is completely linked or refunded
                  if (record.is_completely_linked) {
                    return (
                      <span style={{ color: "green", fontSize: "12px" }}>
                        Completely Linked
                      </span>
                    );
                  } else if (record.is_refunded) {
                    return (
                      <span tyle={{ color: "red", fontSize: "12px" }}>
                        Refunded
                      </span>
                    );
                  }

                  // Otherwise, render the split option if the user has the appropriate permission
                  return hasPermission("can_split_transaction") ? (
                    <a onClick={() => handleSplit(record)}>Split</a>
                  ) : (
                    <p type="secondary" style={{ color: "gray" }}>
                      Split
                    </p>
                  );
                },
              };
            }

            // Default column configuration
            return {
              title: key.replace(/_/g, " ").toUpperCase(),
              dataIndex: key,
              key,
              render: (text) => (text !== null ? text : "-"),
            };
          })
      : [];

  return (
    <div style={{ width: "100%" }}>
      <Table
        style={{ width: "96%" }}
        columns={columns}
        dataSource={data}
        rowKey="transaction_id"
        className="custom-table"
        scroll={{ x: true }}
      />
      <Modal
        loading={isLoading}
        title="Split Transaction"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}>
        <Form form={form} layout="vertical">
          <Form.Item
            name="username"
            label="Username"
            rules={[{ required: true, message: "Please input the username!" }]}>
            <Input defaultValue={splitAmount.username} />
          </Form.Item>
          <Form.Item
            name="splitAmount"
            label="Split Amount"
            rules={[
              { required: true, message: "Please input the split amount!" },
              {
                pattern: /^\d*\.?\d*$/,
                message: "Only numbers and decimal points are allowed",
              },
            ]}>
            <InputNumber
              style={{ width: "100%" }}
              prefix={splitAmount.currency} // Currency symbol on the left
              formatter={(value) => value.replace(/\B(?=(\d{3})+(?!\d))/g, ",")} // Format the number with commas
              parser={(value) => value.replace(/[^0-9.]/g, "")} // Remove non-numeric characters
              defaultValue={splitAmount.splitAmount}
              min={0}
              controls={false}
              keyboard={false}
              onKeyPress={(e) => {
                // Only allow numeric characters and decimal point
                if (!/[0-9.]/.test(e.key)) {
                  e.preventDefault(); // Prevent the input of any non-numeric characters
                }
              }}
              onChange={(value) => {
                // Optional: You can handle state updates here
                setSplitAmount({
                  ...splitAmount,
                  splitAmount: value,
                });
              }}
            />
          </Form.Item>
          <Form.Item
            name="splitRemarks"
            label="Split Remarks"
            rules={[
              { required: true, message: "Please input the split remarks!" },
            ]}>
            <Input.TextArea />
          </Form.Item>
        </Form>
      </Modal>
      </div>
  );
};

export default DynamicTable;
