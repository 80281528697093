import { EditOutlined } from "@ant-design/icons";
import {
  Table,
  Modal,
  Button,
  Form,
  Select,
  Input,
  Card,
  Descriptions,
  Pagination,
  Spin,
  message,
  Popover,
  Tooltip,
  Tag,
  Typography,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import usePermissions from "../../hooks/usePermissions";
import { catchError } from "../../utils/errorHandler";
import axiosInstance from "../../services/axiosInstance";

const MobileCallAllTable = ({
  data,
  showModal,
  pagination,
  setPagination,
  viewMode,
  loading,
}) => {
  const { hasPermission } = usePermissions();
  const [timeElapsedArray, setTimeElapsedArray] = useState([]);

  useEffect(() => {
    const interval = setInterval(() => {
      const updatedElapsedTimes = data.map((record, index) => {
        return calculateTimeElapsed(
          record.action_taken_on,
          record.called_on,
          record.flag
        );
      });
      setTimeElapsedArray(updatedElapsedTimes);
    }, 1000);

    return () => clearInterval(interval);
  }, [data, timeElapsedArray]);

  return (
    <div>
      {loading ? (
        <Spin />
      ) : (
        data.map((user) => (
          <Card
            key={user.uuid}
            style={{ marginBottom: "1rem" }}
            actions={
              viewMode === "myAllocation" &&
              user.can_action &&
              user.flag === "Open"
                ? [
                    hasPermission("can_take_action_on_call_allocation") && (
                      <Button
                        type="link"
                        icon={<EditOutlined />}
                        onClick={() => showModal(user)}
                      >
                        Edit
                      </Button>
                    ),
                  ]
                : viewMode === "allAllocation"
                ? [
                    hasPermission("can_take_action_on_call_allocation") && (
                      <Button
                        type="link"
                        icon={<EditOutlined />}
                        onClick={() => showModal(user)}
                      >
                        Edit
                      </Button>
                    ),
                  ]
                : []
            }
          >
            <Descriptions bordered size="small">
              <Descriptions.Item label="Order">
                <Link
                  target="_blank"
                  to={`/order-management/my-orders/${user.order}`}
                >
                  {user.order || "N/A"}
                </Link>
              </Descriptions.Item>
              <Descriptions.Item label="Word">
                {user.word_count || "N/A"}
              </Descriptions.Item>
              <Descriptions.Item label="Order Status">
                {user.task_status || "N/A"}
              </Descriptions.Item>
              <Descriptions.Item label="Call Allocation Status">
                <Popover
                  content={
                    <div style={{ maxWidth: "300px", whiteSpace: "normal" }}>
                      {user.status || "N/A"}
                    </div>
                  }
                  placement="top"
                >
                  {user.status
                    ? user.status.length > 21
                      ? `${user.status.slice(0, 21)}...`
                      : user.status
                    : "N/A"}
                </Popover>
              </Descriptions.Item>

              {viewMode === "allAllocation" && (
                <Descriptions.Item label="Time Elapsed">
                  <span
                    style={{
                      color: user.flag === "Closed" ? "green" : "red",
                    }}
                  >
                    {timeElapsedArray.find(
                      (_, index) => index === data.indexOf(user)
                    ) || "Calculating..."}
                  </span>
                </Descriptions.Item>
              )}

              <Descriptions.Item label="Called By">
                {user.called_by || "N/A"}
              </Descriptions.Item>
              <Descriptions.Item label="Called On">
                {user.called_on
                  ? moment(user.called_on).local().format("DD-MM-YYYY hh:mm A")
                  : "N/A"}
              </Descriptions.Item>
              <Descriptions.Item label="Flag">
                <span style={{ color: user.flag === "Open" ? "red" : "green" }}>
                  {user.flag || "N/A"}
                </span>
              </Descriptions.Item>

              {viewMode === "allAllocation" && (
                <Descriptions.Item label="Urgent">
                  {user.urgent || "No"}
                </Descriptions.Item>
              )}
              <Descriptions.Item label="Action Taken By">
                {user.action_taken_by || "None"}
              </Descriptions.Item>
              <Descriptions.Item label="Action Taken On">
                {user.action_taken_on
                  ? moment(user.action_taken_on)
                      .local()
                      .format("DD-MM-YYYY hh:mm A")
                  : "N/A"}
              </Descriptions.Item>
            </Descriptions>
          </Card>
        ))
      )}
      <Pagination
        current={pagination.current}
        pageSize={pagination.pageSize}
        total={pagination.total}
        onChange={(page, pageSize) =>
          setPagination((prev) => ({ ...prev, current: page, pageSize }))
        }
        style={{ textAlign: "center", marginTop: 16 }}
      />
    </div>
  );
};

const calculateTimeElapsed = (actionTakenOn, calledOn, flag) => {
  if (!actionTakenOn || !calledOn) return "N/A";

  let result;

  if (flag === "Open") {
    const calledDate = moment(calledOn, "DD-MM-YYYY hh:mm:ss");
    const now = moment();
    const duration = moment.duration(now.diff(calledDate));

    const hours = String(Math.floor(duration.asHours()) % 24).padStart(2, "0");
    const minutes = String(duration.minutes()).padStart(2, "0");
    const seconds = String(duration.seconds()).padStart(2, "0");

    result = `${hours}:${minutes}:${seconds}`;
  } else {
    const actionDate = moment(actionTakenOn, "DD-MM-YYYY hh:mm:ss");
    const calledDate = moment(calledOn, "DD-MM-YYYY hh:mm:ss");
    const duration = moment.duration(actionDate.diff(calledDate));

    const days = Math.floor(duration.asDays());
    const hours = String(Math.floor(duration.asHours()) % 24).padStart(2, "0");
    const minutes = String(duration.minutes()).padStart(2, "0");
    const seconds = String(duration.seconds()).padStart(2, "0");

    result = `${hours}:${minutes}:${seconds}`;
  }

  return result;
};

const CallAllocationtables = ({
  data,
  loading,
  pagination,
  setPagination,
  viewMode,
  setEditFormSubmit,
}) => {
  const { hasPermission } = usePermissions();
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [timeElapsedArray, setTimeElapsedArray] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [saveFlag, setSaveFlag] = useState("");
  const { Title } = Typography;

  const { Option } = Select;

  useEffect(() => {
    const interval = setInterval(() => {
      const updatedElapsedTimes = data.map((record, index) => {
        return calculateTimeElapsed(
          record.action_taken_on,
          record.called_on,
          record.flag
        );
      });
      setTimeElapsedArray(updatedElapsedTimes);
    }, 1000);

    return () => clearInterval(interval);
  }, [data, timeElapsedArray]);

  const commonColumns = [
    {
      title: "Order",
      dataIndex: "order",
      key: "order",
      render: (key) => (
        <Link target="_blank" to={`/order-management/my-orders/${key}`}>
          {key}
        </Link>
      ),
    },
    {
      title: "Word",
      dataIndex: "word_count",
      key: "word_count",
    },
    {
      title: "Order Status",
      dataIndex: "task_status",
      key: "task_status",
      render: (text) => {
        // const statusColors = {
        //   Unassigned: "gray",
        //   "Assigned to manager": "blue",
        //   "In Progress": "orange",
        //   "Quality Check": "purple",
        //   Completed: "cyan",
        //   Delivered: "green",
        //   Feedback: "blue",
        //   Rejected: "red",
        //   Cancelled: "gray",
        //   Failed: "gray",
        // };
        const statusColors = {
          "In Progress": "orange",
          Cancelled: "volcano",
          Completed: "green",
        };
        return (
          <Tag color={statusColors[text] || "default"}>{text || "N/A"}</Tag>
        );
      },
    },
    {
      title: "Call Allocation Status",
      dataIndex: "status",
      key: "status",
      render: (text) => (
        <Popover
          content={
            <div style={{ maxWidth: "350px", whiteSpace: "normal" }}>
              {text || "N/A"}
            </div>
          }
          placement="top"
        >
          {text ? (text.length > 21 ? `${text.slice(0, 21)}...` : text) : "N/A"}
        </Popover>
      ),
    },
    {
      title: "Called Details",
      key: "called_details",
      render: (record) => (
        <div>
          <div style={{ marginBottom: 4 }}>{record.called_by || "N/A"}</div>
          <div>
            {record.called_on
              ? moment(record.called_on).local().format("DD-MM-YYYY hh:mm A")
              : "N/A"}
          </div>
        </div>
      ),
    },
    {
      title: "Flag",
      dataIndex: "flag",
      key: "flag",
      render: (flag) => (
        <span
          style={{
            color: flag === "Open" ? "red" : "green",
          }}
        >
          {flag}
        </span>
      ),
    },
    {
      title: "Action Details",
      key: "action_details",
      render: (record) => (
        <div>
          <div style={{ marginBottom: 4 }}>
            {record.action_taken_by || "None"}
          </div>
          <div>
            {record.action_taken_on
              ? moment(record.action_taken_on)
                  .local()
                  .format("DD-MM-YYYY hh:mm A")
              : "N/A"}
          </div>
        </div>
      ),
    },
  ];

  const actionColumn = {
    title: "Action",
    key: "action",
    render: (_, record) => {
      return record?.can_action && record?.flag === "Open" ? (
        <Button type="link" onClick={() => showModal(record)}>
          <EditOutlined />
        </Button>
      ) : null;
    },
  };

  if (
    viewMode === "myAllocation" &&
    hasPermission("can_take_action_on_call_allocation")
  ) {
    commonColumns.push(actionColumn);
  } else if (
    viewMode === "allAllocation" &&
    hasPermission("can_take_action_on_call_allocation")
  ) {
    commonColumns.push({
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Button type="link" onClick={() => showModal(record)}>
          <EditOutlined />
        </Button>
      ),
    });
  }

  if (viewMode === "allAllocation") {
    commonColumns.splice(4, 0, {
      title: "Time Elapsed",
      dataIndex: "",
      key: "",
      render: (_, record, index) => (
        <span style={{ color: record.flag === "Open" ? "red" : "green" }}>
          {timeElapsedArray[index] || "Calculating..."}
        </span>
      ),
    });
    commonColumns.splice(8, 0, {
      title: "Urgent",
      dataIndex: "urgent",
      key: "urgent",
      render: (text) => (
        <span style={{ color: text ? "red" : "green" }}>
          {text ? "Yes" : "No"}
        </span>
      ),
    });
  }

  const showModal = (record) => {
    setSelectedRecord(record);
    setIsModalVisible(true);
    setSaveFlag(record?.flag);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedRecord(null);
  };

  const handleSave = async (values) => {
    const payload = {
      uid: selectedRecord.uuid,
      action: values.status,
      reason: values.reason,
    };
    setSubmitLoading(true);
    try {
      const res = await axiosInstance.post(
        "task/v2/call-associate-tasks/",
        payload
      );
      if (res.status && res.data.status) {
        message.success(res.data.data);
        setEditFormSubmit(true);
        setSubmitLoading(false);
        handleCancel();
      }
    } catch (err) {
      await catchError(err);
      setSubmitLoading(false);
    }
  };

  return (
    <div style={{ width: "100%" }}>
      {isMobile ? (
        <MobileCallAllTable
          data={data}
          showModal={showModal}
          pagination={pagination}
          setPagination={setPagination}
          viewMode={viewMode}
          loading={loading}
        />
      ) : (
        <>
          <Table
            style={{ width: "100%" }}
            columns={commonColumns}
            dataSource={data}
            loading={loading}
            pagination={{
              current: pagination.current,
              pageSize: pagination.pageSize,
              total: pagination.total,
              onChange: (page, pageSize) => {
                setPagination({ ...pagination, current: page, pageSize });
              },
              onShowSizeChange: (current, size) => {
                setPagination({ ...pagination, current: 1, pageSize: size });
              },
            }}
            rowKey="uuid"
          />
        </>
      )}

      <Modal
        className="custom-modal2"
        title={
          <div
            className="custom-modal-header"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Title
              level={3}
              style={{
                color: "white",
                margin: 0,
                position: "relative",
                marginLeft: "10px",
              }}
            >
              CHANGE ACTION
            </Title>
          </div>
        }
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        destroyOnClose
      >
        <Form
          layout="vertical"
          onFinish={handleSave}
          initialValues={{ status: saveFlag, reason: "" }}
        >
          <Form.Item
            label="Status"
            name="status"
            rules={[{ required: true, message: "Please select a status" }]}
          >
            <Select>
              <Option value="Open">Open</Option>
              <Option value="Closed">Closed</Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Reason"
            name="reason"
            rules={[{ required: true, message: "Please enter a reason" }]}
          >
            <Input.TextArea rows={4} placeholder="Enter reason..." />
          </Form.Item>

          <Form.Item style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button type="primary" htmlType="submit">
              {submitLoading ? <Spin /> : "Submit"}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default CallAllocationtables;
