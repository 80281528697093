import axiosInstance from "./axiosInstance";
import { catchError } from "../utils/errorHandler";
import { Device } from "@twilio/voice-sdk";
import { message } from "antd";
const notificationsService = {
    getNotificationsList: async () => {
        try {
          const response = await axiosInstance.get("notifications/v2/list/");
          if (response.status === 200) {
            return { success: true, data: response.data };
          }
          return { success: false };
        } catch (error) {
          await catchError(error);
          return { success: false };
        }
      },

    
};

export default notificationsService;
