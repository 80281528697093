import React, { useState, useEffect } from "react";
import {
  Tabs,
  Skeleton,
  Button,
  Popover,
  message,
  DatePicker,
  Flex,
  Empty,
  Spin,
} from "antd";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import DynamicTable from "./DynamicTable";
import clientService from "../../services/clientService";
import SkeletonTable from "../../components/SkeltonComponent";
import dayjs from "dayjs";
import { CalendarOutlined } from "@ant-design/icons";
import usePermissions from "../../hooks/usePermissions";
import { useMediaQuery } from "react-responsive";
const { TabPane } = Tabs;

const ClientTabs = ({ uid, clientDetails, username }) => {
  const { hasPermission } = usePermissions();
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const [activeTab, setActiveTab] = useState("1");
  const [loading, setLoading] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [refunds, setRefunds] = useState([]);
  const [revenueData, setRevenueData] = useState({
    week: [],
    month: [],
    total: [],
  });
  const [visible, setVisible] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const paymentLinks = clientDetails;

  const fetchData = async (type) => {
    setLoading(true);
    try {
      let data = null;
      switch (type) {
        case "transactions":
          data = await clientService.getClientTransactions(uid);
          console.log(data?.data?.data?.results, "teans");
          setTransactions(data?.data?.data?.results || []);
          break;
        case "tasks":
          data = await clientService.getClientTasks(uid);
          setTasks(data?.data?.data?.results || []);
          break;
        case "refunds":
          data = await clientService.getClientRefunds(uid);
          setRefunds(data?.data?.data?.results || []);
          break;
        case "revenue":
          await fetchRevenueData();
          break;
        default:
          break;
      }
    } catch (error) {
      console.error(`Error fetching ${type}:`, error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const tabTypeMap = {
      1: "transactions",
      2: "tasks",
      3: "refunds",
      4: "revenue",
    };
    fetchData(tabTypeMap[activeTab]);
  }, [activeTab, uid]);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      message.success("Payment link copied to clipboard!");
    });
  };

  const getDomainName = (url) => {
    try {
      const { hostname } = new URL(url);
      return hostname;
    } catch (error) {
      return url;
    }
  };

  const getLinkText = (url) => {
    const domain = getDomainName(url);
    if (domain.includes("oz")) {
      return "Link for OZ Assignments";
    } else if (domain.includes("anayalearning")) {
      return "Link for Anaya Learning";
    } else if (domain.includes("locusrags")) {
      return "Link for Locusrags";
    }
  };

  const fetchRevenueData = async () => {
    try {
      const revenue = await clientService.getClientRevenue(
        uid,
        startDate ? dayjs(startDate).format("YYYY-MM-DD") : null,
        endDate ? dayjs(endDate).format("YYYY-MM-DD") : null
      );
      const processedData = {
        week: formatRevenueData(revenue?.data?.data?.last_week),
        month: formatRevenueData(revenue?.data?.data?.last_month),
        total: formatRevenueData(
          revenue?.data?.data?.total || revenue?.data?.total
        ),
      };
      setRevenueData(processedData);
    } catch (error) {
      console.error("Error fetching revenue data:", error);
      setRevenueData({ week: [], month: [], total: [] });
    }
  };

  const formatRevenueData = (data) => {
    if (!data || typeof data !== "object") return [];
    return Object.entries(data).map(([currency, amount]) => ({
      currency,
      amount: parseFloat(amount) || 0,
    }));
  };

  const handleDateChange = (setDate, newDate) => {
    if (newDate === null) {
      setDate(null);
    } else {
      setDate(dayjs(newDate).format("YYYY-MM-DD"));
    }
  };
  useEffect(() => {
    if (endDate) fetchRevenueData();
  }, [endDate]);

  const renderDatePickers = () => (
    <div style={{ display: "flex", gap: "10px" }}>
      <DatePicker
        value={startDate ? dayjs(startDate) : null}
        onChange={(date) => handleDateChange(setStartDate, date)}
        format="YYYY-MM-DD"
        placeholder="Start Date"
        allowClear
      />
      <DatePicker
        value={endDate ? dayjs(endDate) : null}
        onChange={(date) => handleDateChange(setEndDate, date)}
        format="YYYY-MM-DD"
        disabled={!startDate}
        placeholder="End Date"
        allowClear
      />
    </div>
  );

  const renderRevenueCharts = () => (
    <div>
      {loading ? (
        <Skeleton />
      ) : (
        <>
          <div
            style={{
              marginBottom: 16,
              display: "flex",
              justifyContent: "flex-end",
              flexDirection: isMobile ? 'column' : 'row'
            }}
          >
            {renderDatePickers()}
          </div>
          <Flex style={{ width: "100%" ,    flexDirection: isMobile ? 'column' : 'row'}} align="center">
            {!endDate && (
              <>
                <ResponsiveContainer width="30%" height={200}>
                  {revenueData.week.length > 0 &&
                  revenueData.week.some((item) => item.amount > 0) ? (
                    <BarChart data={revenueData.week} barSize={30}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="currency" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Bar dataKey="amount" fill="#8884d8" name="Last Week" />
                    </BarChart>
                  ) : (
                    <Empty description="No data for last week" />
                  )}
                </ResponsiveContainer>
                <ResponsiveContainer width="30%" height={200}>
                  {revenueData.month.length > 0 &&
                  revenueData.month.some((item) => item.amount > 0) ? (
                    <BarChart data={revenueData.month} barSize={30}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="currency" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Bar dataKey="amount" fill="#82ca9d" name="Last Month" />
                    </BarChart>
                  ) : (
                    <Empty description="No data for last month" />
                  )}
                </ResponsiveContainer>
              </>
            )}
            <ResponsiveContainer width={"30%"} height={200}>
              {revenueData.total.length > 0 &&
              revenueData.total.some((item) => item.amount > 0) ? (
                <BarChart data={revenueData.total} barSize={30}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="currency" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="amount" fill="#ffc658" name="Total" />
                </BarChart>
              ) : (
                <Empty description="No total revenue data" />
              )}
            </ResponsiveContainer>
          </Flex>
        </>
      )}
    </div>
  );

  const content = (
    <div>
      {paymentLinks.length > 0 ? (
        paymentLinks.map((link, index) => (
          <div
            key={index}
            onClick={() => copyToClipboard(link)}
            style={{ cursor: "pointer", color: "#1890ff", marginBottom: "8px" }}
          >
            {getLinkText(link)}
          </div>
        ))
      ) : (
        <div>No payment links available</div>
      )}
    </div>
  );

  return (
    <div style={{ overflowX: isMobile ? "auto" : "unset", whiteSpace: "nowrap" , width:isMobile ?'100%' : '96%' }}>
      <Tabs
        defaultActiveKey="1"
        onChange={(key) => setActiveTab(key)}
        tabBarExtraContent={

          !isMobile  &&
          hasPermission("can_view_client_details") && paymentLinks?.length > 0 && (
            <Popover
              content={content}
              title={
                <span style={{ color: "#8c8c8c", fontWeight: "normal" }}>
                  Click to copy
                </span>
              }
              trigger="hover"
              visible={visible}
              onVisibleChange={(visible) => setVisible(visible)}
            >
              <Button style={{marginRight:1}}type="link">View Payment Links</Button>
            </Popover>
          )
        }
      >
        {hasPermission("can_view_client_transactions") && (
          <TabPane tab="Transactions" key="1">
            {loading ? (
              <Flex justify="center" align="center" style={{width:"100%"}}>
      <Spin size="lg" />
              </Flex>
    
            ) : (
              <DynamicTable username={username} data={transactions} uid={uid} />
            )}
          </TabPane>
        )}

        {hasPermission("can_view_client_tasks") && (
          <TabPane tab="Tasks" key="2">
            {loading ?      <Flex justify="center" align="center" style={{width:"100%"}}>
      <Spin size="lg" />
              </Flex>: <DynamicTable data={tasks} />}
          </TabPane>
        )}

        {hasPermission("can_view_client_refunds") && (
          <TabPane tab="Refunds" key="3">
            {loading ?      <Flex justify="center" align="center" style={{width:"100%"}}>
      <Spin size="lg" />
              </Flex> : <DynamicTable data={refunds} />}
          </TabPane>
        )}

        {hasPermission("can_view_client_revenue") && (
          <TabPane tab="Revenue" key="4">
            {renderRevenueCharts()}
          </TabPane>
        )}
      </Tabs>
    </div>
  );
};

export default ClientTabs;
