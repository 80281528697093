import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  InputNumber,
  Select,
  Upload,
  Button,
  Row,
  Col,
  message,
} from "antd";
import { InboxOutlined } from "@ant-design/icons";
import paymentOperationsService from "../../services/paymentOperationsService";
import commonService from "../../services/commonService";
const { Option } = Select;
const { Dragger } = Upload;

const AddTransactionForm = ({
  isModalVisible,
  setIsModalVisible,
  fetchTransactions,
}) => {
  const [form] = Form.useForm();
  const [paymentModes, setPaymentModes] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);
  const [loading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchPaymentModes = async () => {
      try {
        const response = await paymentOperationsService.getTransactionPaymentModes();
        if (response.success && response.user?.data) {
          // Transform the data into label-value pairs\
     
          const modes = response.user.data.map((mode) => ({
            value: mode.key, // Set the key as value
            label: mode.value, // Set the value as label
          }));
     
          setPaymentModes(modes);
        } else {
          message.error("Failed to fetch payment modes.");
        }
      } catch (error) {
        message.error("An error occurred while fetching payment modes.");
      }
    };
    const fetchCurrencyList = async () => {
      try {
        const response = await commonService.getCurrencyList();
        if (response.success) {
          // The currency list is an array of strings
          setCurrencyList(response?.data?.data?.currency);
        } else {
          message.error("Failed to fetch currency list.");
        }
      } catch (error) {
        message.error("An error occurred while fetching currencies.");
      }
    };

    fetchPaymentModes();
    fetchCurrencyList();
  }, []);

  const handleFinish = async (values) => {
    try {
      setIsLoading(true);
      // Transform form values into the required payload structure
      const payload = {
        amount: values.amountPaid,
        currency: values.currency,
        remarks: values.remarks || "",
        user: values.clientUsername,
        payment_mode: values.paymentMode,
        receipt: values.receipt?.[0]?.originFileObj, // Access the uploaded file
      };

      // Call createTransaction
      const result = await paymentOperationsService.createTransaction(payload);

      if (result.success) {
        message.success("Transaction created successfully!");
        setIsLoading(false);
        setIsModalVisible(false);
        await fetchTransactions();
        form.resetFields();
      } else {
        setIsModalVisible(false);
        setIsLoading(false);
        message.error("Failed to create the transaction.");
      }
    } catch (error) {
      setIsModalVisible(false);
      setIsLoading(false);
      message.error("An error occurred. Please try again.");
    }
  };

  return (
    <Form

      form={form}
      layout="vertical"
      onFinish={handleFinish}
      style={{ maxWidth: "100%", width:'100%' }}>
      {/* First Row: Client Username and Amount Paid */}
      <Row style={{ width: "100%" }} gutter={16}>
        <Col sm={24} md={12}>
          <Form.Item
            label="Client Username"
            name="clientUsername"
            rules={[
              { required: true, message: "Please enter the client username" },
            ]}>
            <Input placeholder="Enter client username" />
          </Form.Item>
        </Col>
        <Col sm={24} md={12}>
          <Form.Item
            label="Amount Paid"
            name="amountPaid"
            rules={[
              { required: true, message: "Please enter the amount paid" },
              {
                type: "number",
                min: 0,
                message: "Amount must be a positive number",
              },
            ]}>
            <InputNumber
              style={{ width: "100%" }}
              placeholder="Enter amount paid"
              step="0.01"
            />
          </Form.Item>
        </Col>
      </Row>

      {/* Second Row: Currency and Payment Mode */}
      <Row style={{ width: "100%" }} gutter={16}>
        <Col sm={24} md={12}>
          <Form.Item
            label="Currency"
            name="currency"
            rules={[{ required: true, message: "Please select a currency" }]}>
            <Select placeholder="Select currency">
              {currencyList.map((currency) => (
                <Option key={currency} value={currency}>
                  {currency}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col sm={24} md={12}>
          <Form.Item
            label="Payment Mode"
            name="paymentMode"
            rules={[
              { required: true, message: "Please select a payment mode" },
            ]}>
            <Select placeholder="Select payment mode">
              {paymentModes.map((mode) => (
                <Option key={mode.value} value={mode.value}>
                  {mode.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      {/* Third Row: Receipt */}
      <Row style={{ width: "100%" }} gutter={16}>
        <Col span={24}>
          <Form.Item
            label="Receipt"
            name="receipt"
            valuePropName="fileList"
            getValueFromEvent={(e) => (Array.isArray(e) ? e : e?.fileList)}
            // rules={[{ required: true, message: "Please upload a receipt" }]}
          >
            <Dragger
              name="file"
              beforeUpload={() => false}
              listType="picture"
              maxCount={1}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined size={20} />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
            </Dragger>
          </Form.Item>
        </Col>
      </Row>

      {/* Fourth Row: Remarks */}
      <Row style={{ width: "100%" }} gutter={16}>
        <Col span={24}>
          <Form.Item
            label="Remarks"
            name="remarks"
            rules={[{ required: true, message: "Please write remark" }]}>
            <Input.TextArea placeholder="Enter any remarks" rows={4} />
          </Form.Item>
        </Col>
      </Row>

      {/* Fifth Row: Submit Button */}
      <Row style={{ width: "100%" }} justify="end">
        <Col>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              Submit
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default AddTransactionForm;
