import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css"; // Import default styles for the phone input
import { Modal, Form, Input, Button, Typography, message } from "antd";
import clientService from "../../services/clientService";

function AddReferralModal({ visible, onClose, getClientReferrals }) {
  const { Title } = Typography;
  const [form] = Form.useForm();
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);
  const handleCancel = () => {
    form.resetFields(); // Reset the form fields when the modal is closed
    onClose();
    setPhone("");
  };

  const handleFormSubmit = async () => {
    try {
      setLoading(true);
      const values = await form.validateFields(); // Validate and retrieve form values
      const payload = { ...values, contact_no: phone }; // Add formatted phone to payload
      const response = await clientService.createReferral(payload);
      if (response.success) {
        setLoading(false);

        message.success("Referral added successfully");
        form.resetFields(); // Reset form after successful submission
        setPhone(""); // Reset phone input
        handleCancel(); // Close the modal
        await getClientReferrals();
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error adding referral:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      className="custom-modal2"
      title={
        <div
          className="custom-modal-header"
          style={{ display: "flex", justifyContent: "space-between" }}>
          <Title
            level={3}
            style={{
              color: "white",
              margin: 0,
              position: "relative",
              marginLeft: "10px",
            }}>
            Add Referral
          </Title>
        </div>
      }
      visible={visible}
      onCancel={handleCancel}
      footer={[
        <Button key="cancel" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handleFormSubmit}
          loading={loading}>
          Submit
        </Button>,
      ]}>
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          affiliate_email: "",
          referral_email: "",
          first_name: "",
        }}>
        <Form.Item
          label="Referral’s Name"
          name="first_name"
          rules={[{ required: true, message: "Please enter the first name" }]}>
          <Input />
        </Form.Item>
        <Form.Item
          label="Referrals’s Email address"
          name="referral_email"
          rules={[
            { required: true, message: "Please enter the affiliate email" },
            { type: "email", message: "Please enter a valid email" },
          ]}>
          <Input />
        </Form.Item>

        <Form.Item
          label="Referral’s Contact Number"
          required
          validateStatus={phone ? "success" : "error"}
          rules={[
            { required: true, message: "Please enter the Referral’s Contact Number" },
   ,
          ]}
         >
          <PhoneInput
            country={"us"}
            value={phone}
            onChange={setPhone}
            inputStyle={{ width: "100%" }}
          />
        </Form.Item>
        <Form.Item
          label="Referred by (email address)"
          name="affiliate_email"
          rules={[
            { required: true, message: "Please enter the referral email" },
            { type: "email", message: "Please enter a valid email" },
          ]}>
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default AddReferralModal;
