import React, { useState } from "react";
import {
  Typography,
  Spin,
  Card,
  Descriptions,
  Button,
  Tag,
  Pagination,
  Flex,
} from "antd";
import { EyeOutlined, EyeFilled } from "@ant-design/icons";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import paymentOperationsService from "../../services/paymentOperationsService";
import usePermissions from "../../hooks/usePermissions";
import moment from "moment";
import { Link } from "react-router-dom"; // Ensure this import exists

const { Text } = Typography;

function WalletMobileTable({
  walletData,
  loading,
  pagination,
  setPagination,
  fetchWalletData,
  detailPagination,

  setDetailPagination,
}) {
  const { hasPermission } = usePermissions();

  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const [detailData, setDetailData] = useState({});
  const [loadingDetail, setLoadingDetail] = useState(false);

  const fetchWalletDetail = async (key, page = 1, pageSize = 10) => {
    setLoadingDetail(true);
    try {
      const currentPage = page?.current || page; // Extract current page if it's an object

      const response = await paymentOperationsService.walletDetail(
        key,
        currentPage,
        pageSize
      );
      setLoadingDetail(false);
      if (response.success) {
        setDetailData((prev) => ({
          ...prev,
          [key]: response?.data?.data?.results,
        }));
        setDetailPagination((prev) => ({
          ...prev,
          total: response.data.data.count,
          current: currentPage,
        }));
      }
    } catch (error) {
      setLoadingDetail(false);
      console.error("Error fetching wallet detail:", error);
    }
  };
  const handleDetailPaginationChange = async (page, pageSize, key) => {
    // Update the pagination for the specific key
    setDetailPagination((prev) => ({
      ...prev,
      [key]: {
        current: page,
        pageSize,
      },
    }));

    // Trigger data loading with updated pagination
    await fetchWalletDetail(key, page, pageSize);
  };
  const handlePaginationChange = async (page, pageSize) => {
    console.log(pageSize.page, "pahfe");
    // Update the pagination in parent state
    setPagination((prev) => ({
      ...prev,
      current: page,
      pageSize: pageSize,
    }));

    // Trigger data loading with updated pagination
    await fetchWalletData(page, pageSize);
  };
  const handleExpand = (expanded, record) => {
    const { key } = record;
    if (expanded) {
      setExpandedRowKeys([key]);
      if (!detailData[key]) {
        fetchWalletDetail(key);
      }
    } else {
      setExpandedRowKeys([]);
      setDetailData((prev) => {
        const newData = { ...prev };
        delete newData[key]; // Clear wallet details when row is collapsed
        return newData;
      });
    }
  };

  const renderWalletCard = (record) => {
    return (
      <Card
        style={{
          marginBottom: "16px",
          justifyContent: "flex-start",
          textAlign: "left",
        }}
        extra={
          hasPermission("can_view_wallet_detail") &&
          expandedRowKeys.includes(record.key) ? (
            <EyeFilled
              style={{ cursor: "pointer", color: "#1890ff" }}
              onClick={() => handleExpand(false, record)} // Collapse row
            />
          ) : (
            <EyeOutlined
              style={{ cursor: "pointer", color: "#1890ff" }}
              onClick={() => handleExpand(true, record)} // Expand row
            />
          )
        }>
        <Descriptions bordered column={1}>
          <Descriptions.Item label="Username">{record?.name}</Descriptions.Item>
          <Descriptions.Item label="Username">
            {record?.username}
          </Descriptions.Item>
          <Descriptions.Item label="Email">{record?.email}</Descriptions.Item>
          <Descriptions.Item label="Amount">
            {record.amount
              ? record?.amount.map((amt, index) => (
                  <Tag key={index} color="green">
                    {amt?.currency} {amt?.amount}{" "}
                  </Tag>
                ))
              : null}
          </Descriptions.Item>
        </Descriptions>
        {expandedRowKeys.includes(record.key) && expandedRowRender(record)}
      </Card>
    );
  };

  const expandedRowRender = (record) => {
    const data = detailData[record.key];

    if (loadingDetail) {
      return (
        <div style={{ textAlign: "center" }}>
          <Spin tip="Loading details..." />
        </div>
      );
    }

    if (!data || Object.keys(data).length === 0) {
      return <Text type="secondary">No details available</Text>;
    }

    const dataSource = Array.isArray(data) ? data : Object.values(data);

    return (
      <>
        {dataSource.map((item, index) => (
          <Card
            key={item?.key || index}
            style={{
              marginBottom: "12px", // 2px spacing between cards
              alignItems: "flex-start",
              justifyContent: "flex-start",
            }}>
            <Descriptions column={1} bordered size="small">
              <Descriptions.Item label="Amount">
                {item?.amount} {item?.currency || "N/A"}
              </Descriptions.Item>
              <Descriptions.Item label="Type">
                {item?.type === "Credited" ? (
                  <span style={{ color: "green" }}>
                    <PlusOutlined style={{ marginRight: 4 }} />
                    Credit
                  </span>
                ) : item?.type === "Debited" ? (
                  <span style={{ color: "red" }}>
                    <MinusOutlined style={{ marginRight: 4 }} />
                    Debit
                  </span>
                ) : (
                  "N/A"
                )}
              </Descriptions.Item>
              <Descriptions.Item label="Order ID">
                {item?.orderId ? (
                  <Link to={`/order-management/${item?.orderId}`}>
                    {item?.orderId}
                  </Link>
                ) : (
                  "N/A"
                )}
              </Descriptions.Item>
              <Descriptions.Item label="Created">
                {item?.created
                  ? moment(item?.created).format("DD-MM-YYYY HH:mm A")
                  : "N/A"}
              </Descriptions.Item>
              <Descriptions.Item label="Source">
                {item?.source || "N/A"}
              </Descriptions.Item>
              <Descriptions.Item label="Detail">
                {item?.detail || "N/A"}
              </Descriptions.Item>
            </Descriptions>
          </Card>
        ))}

        <Pagination
          current={detailPagination.current || 1}
          pageSize={detailPagination.pageSize || 10}
          total={detailPagination?.total || 0}
          onChange={(page, pageSize) =>
            handleDetailPaginationChange(page, pageSize, record.key)
          }
        />

        <div style={{ textAlign: "right", marginTop: "16px" }}>
          <Button
            type="primary"
            icon={<EyeOutlined />}
            onClick={() => handleExpand(false, record)} // Close Details
          >
            Close Details
          </Button>
        </div>
      </>
    );
  };

  return (
    <div>
      {walletData.map((record) => renderWalletCard(record))}
      <Pagination
        current={pagination.current}
        pageSize={pagination.pageSize}
        total={pagination.total}
        onChange={handlePaginationChange}
        style={{ textAlign: "center", marginTop: "20px" }}
      />
    </div>
  );
}

export default WalletMobileTable;
