import React, { useState, useEffect } from "react";
import {
  Button,
  Layout,
  Typography,
  Input,
  Spin,
  Divider,
  Pagination,
  Flex,
} from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./layout.module.css";
import {
  toggleSidebarCollapsed,
  toggleSidebarDrawer,
} from "../../redux/slices/uiSlice";
import getPageTitle from "../../routes/routeTitles";
import { routeTitles } from "../../routes/routeTitles";
import axiosInstance from "../../services/axiosInstance";
import SearchDescriptionsList from "../SearchDescriptionsList";
import { useMediaQuery } from "react-responsive";
import moment from "moment";

const { Header } = Layout;
const { Title } = Typography;

const HeaderContent = ({ isAuthenticated }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  // State
  const [loading, setLoading] = useState(false);
  const [searched, setSearched] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResult, setSearchResult] = useState({ clients: [], tasks: [] });
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  // Redux state
  const { collapsed, drawerOpen } = useSelector((state) => ({
    collapsed: state.ui.collapsed,
    drawerOpen: state.ui.drawerOpen,
  }));

  const currentPath = location.pathname;
  const pageTitle = getPageTitle(currentPath, routeTitles);

  // Reset search when navigating away from search page
  useEffect(() => {
    if (!currentPath.includes("search=true")) {
      setSearchQuery("");
      setSearchResult({ clients: [], tasks: [] });
      setSearched(false);
      setLoading(false);
    }
  }, [currentPath]);

  // Cleanup loading state when component unmounts
  useEffect(() => {
    return () => {
      setLoading(false);
    };
  }, []);

  const handleSearch = async (page = 1, pageSize = 10) => {
    if (!searchQuery.trim()) return;

    setLoading(true);
    setSearched(true);

    const validPage = Number.isInteger(page) && page > 0 ? page : 1;
    const validPageSize =
      Number.isInteger(pageSize) && pageSize > 0 ? pageSize : 10;

    // Navigate to search page
    navigate(
      `/search=true?query=${encodeURIComponent(
        searchQuery
      )}&page=${validPage}&pageSize=${validPageSize}`
    );

    try {
      const response = await axiosInstance.get(
        `/task/v2/global-search/?search=${encodeURIComponent(
          searchQuery
        )}&page=${validPage}&pageSize=${validPageSize}`
      );

      if (response?.data?.data) {
        const { data } = response.data;
        setSearchResult(data);

        // Update pagination based on tasks count
        setPagination((prev) => ({
          ...prev,
          current: validPage,
          pageSize: validPageSize,
          total: data.tasks?.count || 0,
        }));
      }
    } catch (error) {
      console.error("Error fetching search results:", error);
      setSearchResult({ clients: [], tasks: [] });
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (page, pageSize) => {
    handleSearch(page, pageSize);
  };

  if (!isAuthenticated) return null;

  const renderSearchResults = () => {
    if (!searched) return null;

    return (
      <>
        {loading ? (
          <Flex style={{ width: "100%" }} align="center" justify="center">
            <Spin size="large" />
          </Flex>
        ) : (
          <div
            style={{
              maxHeight: "100%",
              overflowY: "scroll",

              background: "#f2f6f9 ",

              // minHeight: "30%",
              padding: 14,
            }}>
            {/* Clients Section */}
            {Array.isArray(searchResult?.clients) &&
              searchResult.clients.length > 0 && (
                <>
                  <Title level={4}>Clients</Title>
                  <SearchDescriptionsList
                    items={searchResult.clients}
                    type="client"
                  />
                  <Divider />
                </>
              )}

            {/* Tasks Section */}
            {Array.isArray(searchResult?.tasks?.results) &&
              searchResult.tasks.results.length > 0 && (
                <>
                  <Title level={4}>Orders</Title>
                  <SearchDescriptionsList
                    items={searchResult.tasks.results}
                    type="task"
                  />
                  <Pagination
                    current={pagination.current}
                    pageSize={pagination.pageSize}
                    total={pagination.total}
                    onChange={handlePageChange}
                    className={styles.pagination}
                  />
                </>
              )}

            {/* No Results Message */}
            {searched &&
              !loading &&
              !searchResult?.clients?.length &&
              !searchResult?.tasks?.results?.length && (
                <p className={styles.noResults}>
                  No data found for your search.
                </p>
              )}
          </div>
        )}
      </>
    );
  };

  return (
    <>
        <Header className={styles.global_header}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => dispatch(toggleSidebarCollapsed())}
            className={styles.header_btn}
          />
          <Button
            type="text"
            icon={drawerOpen ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => dispatch(toggleSidebarDrawer())}
            className={styles.mobile_toggle}
          />
          {/* <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => dispatch({ type: "TOGGLE_SIDEBAR" })}
            className={styles.header_btn}
          /> */}
          <Title level={2} style={{ margin: 0 }}>
            {pageTitle}
          </Title>
        </div>

        <div
          style={{ marginLeft: "auto", display: "flex", alignItems: "center" }}>
          <Input.Search
            placeholder="Search..."
            onSearch={() => handleSearch(1, pagination.pageSize)}
            onChange={(e) => setSearchQuery(e.target.value)}
            value={searchQuery}
            style={{ width: isMobile ? 100 : 300, marginRight: 16 }}
            enterButton={
              <Button type="primary" style={{ backgroundColor: "#040724" }}>
                <SearchOutlined />
              </Button>
            }
          />
        </div>
      </Header>

      {renderSearchResults()}
    </>
  );
};

export default HeaderContent;