import React, { useState } from 'react';
import { Form, Input, Button, Divider, Row, Col, message } from 'antd';
import UserHeirachyTeam from '../../page-components/UserHeirachyTeam';
import { catchError } from '../../utils/errorHandler';
import axiosInstance from '../../services/axiosInstance';

const HeirachyTeam = () => {
    const [form] = Form.useForm();
    const [submitLoading, setSubmitLoading] = useState(false)
    const [postSuccess, setPostSuccess] = useState(false)

    const onFinish = async (values) => {
        setSubmitLoading(true)
        try {
            const response = await axiosInstance.post('useraccount/v2/team-hierarchies/', values)

            if (response.status && response.data.status === "success") {
                message.success(response.data.data)
                setPostSuccess(true)
                form.resetFields()
            }

        } catch (err) {
            await catchError(err)
        } finally {
            setSubmitLoading(false)

        }
    };

    return (
        <Row>
            <Col xs={24} md={12}>
                <div style={{ padding: '10px', textAlign: "left" }}>
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={onFinish}
                        style={{
                            width: '100%',
                            backgroundColor: "white",
                            padding: "20px",
                            borderRadius: '10px',
                        }}
                    >
                        <h4 style={{ marginBottom: 20 }}>MAP YOUR TEAM MEMBER</h4>
                        <Divider />
                        <Form.Item
                            style={{ width: "100%" }}
                            label="Email"
                            name="email"
                            rules={[
                                { required: true, message: 'Please enter your email!' },
                                { type: 'email', message: 'Please enter a valid email address!' },
                            ]}
                        >
                            <Input placeholder="Enter email" />
                        </Form.Item>

                        <Form.Item
                            style={{ width: "100%" }}
                            label="Employee ID"
                            name="employee_id"
                            rules={[{ required: true, message: 'Please enter the Employee ID!' }]}
                        >
                            <Input placeholder="Enter employee ID" />
                        </Form.Item>

                        <Form.Item
                            style={{
                                display: "flex",
                                margin: "0px",
                                justifyContent: "flex-end",
                                width: "100%",
                                gap: "10px",
                            }}
                        >
                            <Button type="primary" htmlType="submit" block>
                                Create
                            </Button>
                        </Form.Item>
                    </Form>

                    <p style={{ marginTop: 20, fontSize: 12, color: '#888', fontWeight: "bold" }}>
                        Note: Email should strictly be the same as associated with Locus Dashboard.
                    </p>
                </div>
            </Col>

            <Col xs={24} md={12}>
                <div style={{ padding: '10px' }}>
                    <div
                        style={{
                            backgroundColor: "white",
                            padding: "20px",
                            borderRadius: '10px',
                            textAlign: 'left'
                        }}
                    >
                        <h4>CURRENT TEAM MEMBER</h4>
                        <Divider />
                        <UserHeirachyTeam postSuccess={postSuccess} />
                    </div>
                </div>
            </Col>
        </Row>
    );
};

export default HeirachyTeam;
