import React, { useState, useEffect, useCallback } from "react";
import { Tabs, Select, Button, Typography, message } from "antd";
import { FilterOutlined } from "@ant-design/icons";
import TransactionsTable from "../../page-components/PaymentAndOperations/TransactionsTable";
import PaymentOperationsFilters from "../../page-components/PaymentAndOperations/PaymentOperationsFilters";
import clientService from "../../services/clientService";
import CustomModal from "../../components/MyOrderActions/CustomModal";
import AddTransactionsModal from "../../page-components/PaymentAndOperations/AddTransactionsModal";
import usePermissions from "../../hooks/usePermissions";
import RefundsTable from "../../page-components/PaymentAndOperations/RefundsTable";
import paymentOperationsService from "../../services/paymentOperationsService";
import { useMediaQuery } from "react-responsive";
import SearchFilters from "../../page-components/PaymentAndOperations/PaymentOperationsFilters";
import AddRefund from "../../page-components/PaymentAndOperations/AddRefund";

const PaymentOperations = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const { Option } = Select;
  const { TabPane } = Tabs;
  const { Paragraph } = Typography;
  const { hasPermission } = usePermissions();

  const [activeTab, setActiveTab] = useState("Transactions");
  const [dropdownValue, setDropdownValue] = useState("myTransactions");
  const [transactions, setTransactions] = useState([]);
  const [refunds, setRefunds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
  });
  const [filters, setFilters] = useState({});
  const [filtersList, setFiltersList] = useState([]);

  const [showFilters, setShowFilters] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isRefundModalVisible, setIsRefundModalVisible] = useState(false);
  const handleDownloadCsv = async () => {
    try {
      const response = await paymentOperationsService.getTransactioncCsv();
      console.log(response , 'res')
      if (response.success) {
        const url = window.URL.createObjectURL(new Blob([response.data]));

        const link = document.createElement("a");
        console.log(link , 'link')
        link.href = url;
        link.setAttribute("download", "transactions.csv"); // Set the filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        message.error("Failed to download CSV.");
      }
    } catch (error) {
      console.error("Error downloading CSV:", error);
      message.error("An error occurred while downloading the CSV.");
    }
  };

  // Dynamically create dropdown options based on permissions
  const dropdownOptions = {
    Transactions: [
      hasPermission("can_view_my_transactions") && {
        label: "My Transactions",
        value: "myTransactions",
      },
      hasPermission("can_view_all_transactions") && {
        label: "All Transactions",
        value: "allTransactions",
      },
    ].filter(Boolean), // Filter out `false` values
    Refunds: [
      hasPermission("can_view_my_refund_list") && {
        label: "My Refunds",
        value: "myRefunds",
      },
      hasPermission("can_view_all_refund_list") && {
        label: "All Refunds",
        value: "allRefunds",
      },
    ].filter(Boolean), // Filter out `false` values
  };

  const fetchData = useCallback(async () => {
    setLoading(true);

    let apiCall;

    if (dropdownValue === "myTransactions") {
      // If dropdown is 'myTransactions'
      apiCall = clientService.getMyTransactions;
    } else if (dropdownValue === "allTransactions") {
      // If dropdown is 'allTransactions'
      apiCall = clientService.getAllTransactions;
    } else if (dropdownValue === "myRefunds") {
      // If dropdown is 'myRefunds'
      apiCall = paymentOperationsService.getMyRefunds;
    } else if (dropdownValue === "allRefunds") {
      // If dropdown is 'allRefunds'
      apiCall = paymentOperationsService.getAllRefunds;
    }

    try {
      const result = await apiCall(
        pagination.current,
        pagination.pageSize,
        filters
      );

      if (result.success) {
        const data = result?.data?.data;
        if (
          dropdownValue === "myTransactions" ||
          dropdownValue === "allTransactions"
        ) {
          setTransactions(data?.results || []);

          setFiltersList(data.filters);
        } else if (
          dropdownValue === "myRefunds" ||
          dropdownValue === "allRefunds"
        ) {
          setRefunds(data?.results || []);
          setFiltersList(result.data.data.filters);
        }
        setPagination((prev) => ({ ...prev, total: data?.count || 0 }));
      } else {
        message.error(`Failed to fetch ${activeTab.toLowerCase()}.`);
      }
    } catch (error) {
      console.error(`Error fetching ${activeTab.toLowerCase()}:`, error);
    } finally {
      setLoading(false);
    }
  }, [
    activeTab,
    dropdownValue,
    pagination.current,
    pagination.pageSize,
    filters,
  ]);

  useEffect(() => {
    fetchData();
  }, [activeTab, dropdownValue, filters, pagination.current]);

  const handleTabChange = (key) => {
    setActiveTab(key);
    setDropdownValue(key === "Transactions" ? "myTransactions" : "myRefunds");
    setPagination((prev) => ({ ...prev, current: 1 }));
  };

  const handleDropdownChange = (value) => {
    setFilters({});
    setFiltersList([]);
    setShowFilters(false);
    setDropdownValue(value);
    setTransactions([]); // Clear transactions array
    setRefunds([]); // Clear refunds array

    // Set active tab based on dropdown value
    if (value === "myTransactions" || value === "allTransactions") {
      setActiveTab("Transactions");
    } else if (value === "myRefunds" || value === "allRefunds") {
      setActiveTab("Refunds");
      // Fetch refunds when dropdown value is related to refunds
    }

    // Reset pagination to first page
    setPagination((prev) => ({ ...prev, current: 1 }));
  };

  const toggleFilters = () => setShowFilters(!showFilters);

  const showModal = () => setIsModalVisible(true);
  const handleModalClose = () => setIsModalVisible(false);
  const handleRefundModalClose = () => setIsRefundModalVisible(false);
  const handleSearchClick = async (newFilters) => {
    setFilters(newFilters);
    setTransactions([]);
    setPagination({ ...pagination, current: 1 });
  };
  const handleCancel = async () => {
    setFilters({});
    // Reset all filters to the initial state
    setTransactions([]);

    // Fetch data with the reset filters
  };
  return (
    <div style={{ display: "flex", flexDirection: "column", padding: "20px" }}>
      <Tabs activeKey={activeTab} onChange={handleTabChange}>
        {hasPermission("can_view_my_transactions") && (
          <TabPane tab="Transactions" key="Transactions" />
        )}
        {hasPermission("can_view_my_refund_list") && (
          <TabPane tab="Refunds" key="Refunds" />
        )}
      </Tabs>

      <div>
        {showFilters && (
          <SearchFilters
            filtersList={filtersList}
            onSearch={handleSearchClick}
            fetchData={fetchData}
            setTransactions={setTransactions}
            onCancel={handleCancel}
          />
        )}
        <div
          style={{
            display: "flex",
            justifyContent: isMobile ? "flex-end" : "space-between",
            alignItems: isMobile ? "flex-end" : "center",
            marginBottom: "10px",
            flexDirection: isMobile ? "column-reverse" : "row",
            gap: isMobile ? 2 : 0,
          }}>
          <Paragraph style={{ fontWeight: 500, opacity: 0.6, margin: 0 }}>
            Showing{" "}
            {Math.min(
              (pagination.current - 1) * pagination.pageSize + 1,
              pagination.total
            )}{" "}
            -{" "}
            {Math.min(
              pagination.current * pagination.pageSize,
              pagination.total
            )}{" "}
            of {pagination.total}
          </Paragraph>

          <div style={{ display: "flex", gap: "10px", marginTop: 10 }}>
            <Select
              value={dropdownValue}
              onChange={handleDropdownChange}
              style={{ width: 150 }}>
              {dropdownOptions[activeTab].map(({ label, value }) => (
                <Option key={value} value={value}>
                  {label}
                </Option>
              ))}
            </Select>
            {/* {activeTab === "Transactions" && (
              <Button
                type="primary"
                onClick={handleDownloadCsv}
                style={{ marginLeft: "10px", background: "#040724" }}>
                Download CSV
              </Button>
            )} */}

            {hasPermission("can_create_transaction") &&
              activeTab === "Transactions" && (
                <Button
                  type="primary"
                  onClick={showModal}
                  style={{ marginLeft: "10px", background: "#040724" }}>
                  Add Transaction
                </Button>
              )}

            {hasPermission("can_create_transaction_refund") &&
              activeTab === "Refunds" && (
                <Button
                  type="primary"
                  onClick={() => setIsRefundModalVisible(true)}
                  style={{ marginLeft: "10px", background: "#040724" }}>
                  Add Refund
                </Button>
              )}

            <Button
              type="primary"
              style={{ background: "#001529" }}
              icon={<FilterOutlined />}
              disabled={loading}
              onClick={toggleFilters}
            />
          </div>
        </div>
        {dropdownValue === "myTransactions" ||
        dropdownValue === "allTransactions" ? (
          <TransactionsTable
            transactions={transactions}
            loading={loading}
            pagination={pagination}
            setPagination={setPagination}
            dropdownValue={dropdownValue}
            fetchData={fetchData}
          />
        ) : null}
        {dropdownValue === "myRefunds" || dropdownValue === "allRefunds" ? (
          <RefundsTable
            refunds={refunds}
            loading={loading}
            pagination={pagination}
            setPagination={setPagination}
            dropdownValue={dropdownValue}
            fetchData={fetchData}
          />
        ) : null}
        <CustomModal
          isModalVisible={isModalVisible}
          handleModalClose={handleModalClose}
          selectedLabel="Add Transactions"
          selectedComponent={
            <AddTransactionsModal
              handleModalClose={handleModalClose}
              isModalVisible={isModalVisible}
              setIsModalVisible={setIsModalVisible}
              fetchTransactions={fetchData}
            />
          }
          modalWidth={700}
        />{" "}
        <CustomModal
          isModalVisible={isRefundModalVisible}
          handleModalClose={handleRefundModalClose}
          selectedLabel="Add Refund"
          selectedComponent={
            <AddRefund
              handleModalClose={handleRefundModalClose}
              isModalVisible={isRefundModalVisible}
              setIsModalVisible={setIsRefundModalVisible}
              fetchTransactions={fetchData}
            />
          }
          modalWidth={700}
        />
      </div>
    </div>
  );
};

export default PaymentOperations;
