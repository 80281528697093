import React, { useEffect, useState, useCallback } from "react";
import usePermissions from "../../hooks/usePermissions";
import { Button, Flex, Select, Typography, message } from "antd";
import { FilterOutlined } from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";
import clientService from "../../services/clientService";
import { CSSTransition } from "react-transition-group";
import CallAllocationtables from "../../page-components/CallAllocationComp/CallAllocationtables"
import SearchFiltersAllocation from "../../page-components/CallAllocationComp/SearchFiltersAllocation";


function CallAllocationOrders() {
    const { hasPermission } = usePermissions();
    const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
    const { Option } = Select;
    const { Paragraph } = Typography;
    const [loading, setLoading] = useState(false);
    const [showFilters, setShowFilters] = useState(false);
    const [allocations, setAllocations] = useState([]);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 20,
        total: 0,
        showSizeChanger: false,
    });
    const [filtersList, setFiltersList] = useState([]);
    const [filters, setFilters] = useState({});
    const [viewMode, setViewMode] = useState("myAllocation");
    const [editFormSubmit, setEditFormSubmit] = useState(false)

    const getAllAllocation = useCallback(
        async (page = 1, pageSize = 20) => {
            setLoading(true);
            const result = await clientService.getAllCallAllocation(page, pageSize, filters);

            if (result.success) {
                setFiltersList(result.data.data.filters);
                setAllocations(result.data.data.results);
                setPagination({
                    ...pagination,
                    total: result.data.data.count || 0,
                    current: page,
                    pageSize,
                });
            }
            setLoading(false);
        },
        [pagination, filters]
    );

    const getMyAllocation = useCallback(
        async (page = 1, pageSize = 20) => {
            setLoading(true);
            const result = await clientService.getMyAllocations(page, pageSize, filters);

            if (result.success) {
                setFiltersList(result?.data?.data?.filters);
                setAllocations(result.data.data.results);
                setPagination({
                    ...pagination,
                    total: result?.data?.data?.count || 0,
                    current: page,
                    pageSize,
                });
            }
            setLoading(false);
        },
        [pagination, filters]
    );

    // console.log("allocations", allocations)

    useEffect(() => {

        if (viewMode === "allAllocation") {
            getAllAllocation(pagination.current, pagination.pageSize);
        } else if (viewMode === "myAllocation") {
            getMyAllocation(pagination.current, pagination.pageSize);
        }

    }, [viewMode, pagination.current, pagination.pageSize, filters, editFormSubmit]);

    const handleSearchClick = (newFilters) => {
        setFilters(newFilters);
        setPagination({ ...pagination, current: 1 });
    };

    const handleCancelSearch = async () => {
        setFilters({});
        if (viewMode === "allAllocation") {
            await getAllAllocation(1, pagination.pageSize);
        } else {
            await getMyAllocation(1, pagination.pageSize);
        }
        setPagination({ ...pagination, current: 1 });
    };

    return (
        <Flex vertical style={{ padding: "30px 20px" }}>
            {showFilters && (
                <CSSTransition
                    in={showFilters}
                    timeout={300}
                    classNames="filter"
                    unmountOnExit>
                    <div className="filter-container">
                        <SearchFiltersAllocation
                            filtersList={filtersList}
                            onSearch={handleSearchClick}
                            pagination={pagination}
                            getAllocation={handleCancelSearch}
                        />
                    </div>
                </CSSTransition>
            )}

            <div
                style={{
                    display: "flex",
                    justifyContent: isMobile ? "flex-end" : "space-between",
                    flexDirection: isMobile ? "column-reverse" : "row",
                }}>
                <Flex style={{ padding: "10px 0px", margin: 0 }} align="flex-end">
                    <Paragraph
                        style={{
                            fontWeight: 500,
                            opacity: 0.6,
                            textAlign: "left",
                            margin: 0,
                            padding: 0,
                        }}>
                        Showing{" "}
                        {Math.min(
                            (pagination.current - 1) * pagination.pageSize + 1,
                            pagination.total
                        )}{" "}
                        -
                        {Math.min(
                            pagination.current * pagination.pageSize,
                            pagination.total
                        )}{" "}
                        of {pagination.total} Allocations
                    </Paragraph>
                </Flex>

                <Flex align="center" style={{ margin: "10px 0px" }}>

                    <Select
                        value={viewMode}
                        onChange={(value) => {
                            setViewMode(value);
                            setPagination({ ...pagination, current: 1 });
                        }}
                        style={{ width: 150, textAlign: "left", marginLeft: "10px" }}
                    >
                        {hasPermission("can_view_my_call_allocation_tasks") && <Option value="myAllocation">My Allocation</Option>}
                        {hasPermission("can_view_all_call_allocation_tasks") && <Option value="allAllocation">All Allocation</Option>}
                    </Select>

                    <Button
                        type="primary"
                        icon={<FilterOutlined />}
                        onClick={() => setShowFilters(!showFilters)}
                        style={{ marginLeft: "10px", background: "#040724" }}
                    />
                </Flex>
            </div>

            <CallAllocationtables
                loading={loading}
                data={allocations}
                pagination={pagination}
                setPagination={setPagination}
                viewMode={viewMode}
                setEditFormSubmit={setEditFormSubmit}
            />
        </Flex>
    );
}

export default CallAllocationOrders;
