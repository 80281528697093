import React, { useEffect, useState } from "react";
import { Flex, Table, Tag, Typography, Spin } from "antd";
import { EyeOutlined, PlusOutlined, MinusOutlined } from "@ant-design/icons";
import { EyeFilled } from "@ant-design/icons";
import paymentOperationsService from "../../services/paymentOperationsService";
import moment from "moment";
import { useMediaQuery } from "react-responsive";
import WalletMobileTable from "./WalletMobile";
import usePermissions from "../../hooks/usePermissions";
const { Text } = Typography;

function WalletTable({ walletData, loading, pagination, setPagination ,fetchWalletData}) {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const { hasPermission } = usePermissions();

  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [detailData, setDetailData] = useState({});
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [detailPagination, setDetailPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const fetchWalletDetail = async (key, page = 1, pageSize = 10) => {
    setLoadingDetail(true);
    console.log("Page:", page); // Debugging log to check the value of page
    console.log("Page Size:", pageSize); // Debugging log to check pageSize value

    try {
      // If `page` is an object (for example, from Ant Design Pagination component),
      // ensure we're using the correct value (e.g., `page.current`).
      const currentPage = page?.current || page; // Extract current page if it's an object

      const response = await paymentOperationsService.walletDetail(
        key,
        currentPage,
        pageSize
      );
      setLoadingDetail(false);
      if (response.success) {
        setDetailData((prev) => ({
          ...prev,
          [key]: response?.data?.data?.results,
        }));
        setDetailPagination((prev) => ({
          ...prev,
          total: response.data.data.count,
          current: currentPage,
        }));
      }
    } catch (error) {
      setLoadingDetail(false);
      console.error("Error fetching wallet detail:", error);
    }
  };

  const handleExpand = (expanded, record) => {
    const { key } = record;
    if (expanded) {
      setExpandedRowKeys([key]);
      if (!detailData[key]) {
        fetchWalletDetail(key);
      }
    } else {
      setExpandedRowKeys([]);
      setDetailData((prev) => {
        const newData = { ...prev };
        delete newData[key]; // Clear wallet details when row is collapsed
        return newData;
      });
    }
  };

  const handleTableChange = (pagination) => {
    setPagination({
      ...pagination,
    });
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <Flex align="center" gap="small">
          {hasPermission("can_view_wallet_detail") ? (
            expandedRowKeys.includes(record.key) ? (
              <EyeFilled
                style={{ marginRight: 8, cursor: "pointer", color: "#1890ff" }}
                onClick={() => handleExpand(false, record)} // Collapse row
              />
            ) : (
              <EyeOutlined
                style={{ marginRight: 8, cursor: "pointer", color: "#1890ff" }}
                onClick={() => handleExpand(true, record)} // Expand row
              />
            )
          ) : null}
    
          <Text>{text}</Text>
        </Flex>
      ),
    },
    
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    // {
    //   title: "Created At",
    //   dataIndex: "created",
    //   key: "created",
    //   render: (date) => new Date(date).toLocaleString(),
    // },
    {
      title: "Amount",
      key: "amount",
      width: 400,
      render: (_, record) => (
        <Flex
          style={{
            maxWidth: "400px",
            width: "100%",
            flexDirection: "row",
            gap: "8px",
            flexWrap: "wrap",
          }}>
          {record.amount
            ? record.amount.map((amt, index) => (
                <Tag key={index} color="green">
                  {amt.currency} {amt.amount}
                </Tag>
              ))
            : null}
        </Flex>
      ),
    },
  ];
  const handleDetailPaginationChange = (page, pageSize) => {
    setDetailPagination({
      ...pagination,
    });
    const expandedKey = expandedRowKeys[0];
    if (expandedKey) {
      fetchWalletDetail(expandedKey, page); // Re-fetch wallet details when page changes
    }
  };

  const expandedRowRender = (record) => {
    console.log("record:", record); // Check if the record contains the expected fields
    const data = detailData[record.key];

    if (loadingDetail) {
      return (
        <Flex style={{ width: "100%" }} justify="center" align="center">
          <Spin tip="Loading details..." />{" "}
        </Flex>
      );
    }

    if (!data || Object.keys(data).length === 0) {
      return <Text type="secondary">No details available</Text>;
    }

    // Convert the data object to an array if it's not already an array
    const dataSource = Array.isArray(data)
      ? data
      : Object.values(data).filter((item) => item && typeof item === "object");

    // Define columns for the transaction details
    const detailColumns = [
      {
        title: "CREATED",
        dataIndex: "created",
        key: "created",
        render: (text, record) => (
          <div>
            <div style={{}}>
              {record?.created
                ? moment(record.created).local().format("DD-MM-YYYY hh:mm a")
                : "N/A"}
            </div>
          </div>
        ),
      },
      // {
      //   title: "Key",
      //   dataIndex: "key",
      //   key: "key",
      //   render: (text, record) => <div>{text || "N/A"}</div>,
      // },
      {
        title: "AMOUNT",
        dataIndex: "amount",
        key: "amount",
        render: (text, row) => `${text || "N/A"} ${row.currency || ""}`,
      },
      {
        title: "TYPE",
        dataIndex: "type",
        key: "type",
        render: (text) => {
          if (text === "Credited") {
            return (
              <Typography.Text style={{ color: "green" }}>
                <PlusOutlined /> Credit
              </Typography.Text>
            );
          } else if (text === "Debited") {
            return (
              <Typography.Text style={{ color: "red" }}>
                <MinusOutlined /> Debit
              </Typography.Text>
            );
          }
          return <Typography.Text>{text || "N/A"}</Typography.Text>;
        },
      },
      {
        title: "Order Id",
        dataIndex: "order_id",
        key: "order_id",
        render: (text, record) => (
          <div>
            {text ? (
              <a href={`/order-management/my-orders/${text}`}>
                {text || "N/A"}
              </a>
            ) : (
              "N/A"
            )}
          </div>
        ),
      },

      {
        title: "SOURCE",
        dataIndex: "source",
        key: "source",
      },
      {
        title: "DETAIL",
        dataIndex: "detail",
        key: "detail",
      },
    ];

    return (
      <Table
        columns={detailColumns}
        dataSource={dataSource}
        pagination={detailPagination}
        onChange={handleDetailPaginationChange}
        rowKey="key"
      />
    );
  };

  return isMobile ? (
    <>
      
      <WalletMobileTable
        walletData={walletData}
        loading={loading}
        pagination={pagination}
        setPagination={setPagination}
        fetchWalletData={fetchWalletData}
        detailPagination={detailPagination}
    
        handleDetailPaginationChange={handleDetailPaginationChange}
        setDetailPagination={setDetailPagination}

      />
    </>
  ) : (
    <Table
      rowKey="key"
      columns={columns}
      dataSource={walletData}
      loading={loading}
      pagination={pagination}
      onChange={handleTableChange}
      rowClassName={(record) =>
        expandedRowKeys.includes(record.key) ? "expanded-row" : ""
      } // Apply class for expanded rows
      expandable={{
        expandedRowKeys,
        onExpand: handleExpand,
        expandedRowRender,
        expandIcon: () => null, // Remove default Ant Design expand icon
      }}
    />
  );
}

export default WalletTable;
