// Tasks.js
import React, { useState, useEffect, useCallback } from "react";
import { message, Button, Flex, Tabs, Select } from "antd";
import orderService from "../../services/OrderLists";
import OrderListTable from "./OrderListTable";

import { CSSTransition } from "react-transition-group";
import { FilterOutlined } from "@ant-design/icons";
import "./tasks.css";
import SearchFilters from "./SearchFilters";
import MobileViewComponent from "./MobileViewComponent";
import usePermissions from "../../hooks/usePermissions";
import OrderFormParent from "./OrderFormParent";
import { useMediaQuery } from "react-responsive";

import { debounce } from "lodash";
import { useMemo } from "react";

const { Option } = Select;
const MAX_CONCURRENT_REQUESTS = 4; // Limit for concurrent requests
const requestQueue = [];
let activeRequests = 0;

// Request Queue Manager
const processQueue = async () => {
  if (requestQueue.length === 0 || activeRequests >= MAX_CONCURRENT_REQUESTS) {
    return;
  }

  const { requestFn, resolve, reject } = requestQueue.shift();
  activeRequests++;

  try {
    const result = await requestFn();
    resolve(result);
  } catch (error) {
    reject(error);
  } finally {
    activeRequests--;
    processQueue();
  }
};

// Wrapper for API Calls
const enqueueRequest = (requestFn) =>
  new Promise((resolve, reject) => {
    requestQueue.push({ requestFn, resolve, reject });
    processQueue();
  });
const Tasks = () => {
  const { TabPane } = Tabs;
  const { hasPermission } = usePermissions();
  const [subscribers, setSubscribers] = useState([]);
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const [selectedOption, setSelectedOption] = useState("my-orders");
  const [filtersList, setFiltersList] = useState([]);
  const [fetchingStatusList, setFetchingStatusList] = useState(false);
  const [selectedSortOrder, setSelectedSortOrder] = useState({
    order: "",
    sort: "",
  });

  const [showFilters, setShowFilters] = useState(false);
  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);

  const [statusList, setStatusList] = useState({}); // New state for status list
  const [isSearching, setIsSearching] = useState(false);
  const [urgencyFilter, setUrgencyFilter] = useState(""); // Default filter to "Deadline > 1 Day"
  const [filters, setFilters] = useState({});
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: false,
  });

  const [isModalVisible, setIsModalVisible] = useState(false);
  // useEffect(() => {
  //   console.log("Updated Pagination in Main Parent:", pagination);
  // }, [pagination]);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };
  const handleTabChange = (key) => setUrgencyFilter(key);
  // Debounced version of the loadOrders function
  const debouncedLoadOrders = useMemo(
    () =>
      debounce(async (urgencyLevel, page = 1, pageSize = 10) => {
        setLoading(true);

        try {
          const result = await enqueueRequest(() =>
            orderService.fetchOrders(
              urgencyLevel || null,
              { order: "", sort: "" }, // Adjust as needed
              page,
              pageSize,
              selectedOption
            )
          );

          if (result.success) {
            setFiltersList(result?.data?.data?.filters);
            setOrders(result.data.data.results);
            setPagination({
              ...pagination,
              total: result?.data?.data?.count || 0,
              current: page,
              pageSize,
            });
          } else {
            message.error("Failed to fetch orders");
          }
        } catch (error) {
          console.error("Error fetching orders:", error);
        } finally {
          setLoading(false);
        }
      }, 500), // Debounce delay
    [selectedOption, pagination]
  );

  // const debouncedLoadOrders = useMemo(
  //   () =>
  //     debounce(
  //       async (
  //         urgencyLevel,
  //         page = 1,
  //         pageSize = 10,
  //         order = "",
  //         sort = ""
  //       ) => {
  //         setLoading(true);
  //         try {
  //           const result = await orderService.fetchOrders(
  //             urgencyLevel || null,
  //             {
  //               ...filters,
  //               order: selectedSortOrder.order,
  //               sort: selectedSortOrder.sort,
  //             },
  //             page,
  //             pageSize,
  //             selectedOption
  //           );
  //           if (result.success) {
  //             setFiltersList(result?.data?.data?.filters);
  //             setOrders(result.data.data.results);
  //             setPagination({
  //               ...pagination,
  //               total: result?.data?.data?.count || 0,
  //               current: page,
  //               pageSize,
  //             });

  //             // setPagination((prev) => ({
  //             //   ...prev,
  //             //   current: page,
  //             //   pageSize,
  //             //   total: result.data.data.count,
  //             // }));
  //           } else {
  //             message.error("Failed to fetch orders");
  //           }
  //         } catch (error) {
  //           console.error("Error fetching orders:", error);
  //         } finally {
  //           setLoading(false);
  //         }
  //       },
  //       500
  //     ), // Debounce delay of 500ms
  //   [filters, selectedSortOrder, selectedOption, currentPage]
  // );

  // const loadOrders = useCallback(
  //   async (urgencyLevel, page = 1, pageSize = 10, order = "", sort = "") => {
  //     setLoading(true);
  //     // console.log("stetetet 3333",selectedOption)
  //      try {
  //       const result = await orderService.fetchOrders(
  //         urgencyFilter || null, // Pass null if urgencyFilter is empty
  //         {
  //           ...filters,
  //           order: selectedSortOrder.order,
  //           sort: selectedSortOrder.sort,
  //         }, // Include sorting in filters
  //         page, // Pass the current page
  //         pageSize ,// Pass the page size
  //         selectedOption
  //       );

  //       if (result.success) {
  //         setPagination({
  //           ...pagination,
  //           total: result?.data?.data?.count || 0,
  //           current: page,
  //           pageSize,
  //         });

  //         setFiltersList(result?.data?.data?.filters);
  //         setOrders(
  //           result.data.data.results.map((order) => ({
  //             ...order,
  //           }))
  //         );
  //         setTotal(result.data.data.count);
  //       } else {
  //         message.error("Failed to fetch orders");
  //       }
  //     } catch (error) {
  //       console.error("Error fetching orders:", error);
  //       setLoading(false);
  //       // message.error("An error occurred while fetching orders");
  //     } finally {
  //       setLoading(false);
  //     }
  //   },
  //   [currentPage, urgencyFilter, filters, selectedSortOrder, selectedOption]
  // );

  // const handleSearch = async (value) => {
  //   setSearchKey(value);
  //   setOrders([]);
  //   if (!value) {
  //     setIsSearching(false);
  //     loadOrders(urgencyFilter);
  //     return;
  //   }
  //   setLoading(true);
  //   const result = await orderService.searchOrders(value);
  //   if (result.success) {
  //     setOrders(result.data.results.map((order) => ({ ...order, key: order.key })));
  //     setIsSearching(true);
  //   } else {
  //     message.error("Failed to fetch orders");
  //   }
  //   setLoading(false);
  // };
  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };
  const handleClearSearch = async () => {
    setFilters({});

    // await loadOrders(urgencyFilter, 1);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    const order =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
        ? "desc"
        : "";
    setSelectedSortOrder({ order: sorter.field, sort: order });
    setPagination((prev) => ({
      ...prev,
      current: pagination.current,
      pageSize: pagination.pageSize,
    }));
    // loadOrders(urgencyFilter, pagination.current, pagination.pageSize);
    debouncedLoadOrders(urgencyFilter, pagination.current, pagination.pageSize);
  };

  const fetchStatusList = async () => {
    try {
      setFetchingStatusList(true);
      const response = await orderService.getStatusList();

      if (response) {
        setFetchingStatusList(false);
        const newStatusList = {};
        for (const [key, value] of Object.entries(response.data.data)) {
          newStatusList[value] = parseInt(key);
        }
        setStatusList(newStatusList);
      } else {
        setFetchingStatusList(false);

        console.error("Failed to fetch status list or invalid response format");
        console.error("Response:", response);
      }
    } catch (error) {
      setFetchingStatusList(false);
      console.error("Error fetching status list:", error);
    }
  };

  const fetchDropdownList = async () => {
    try {
      const dropdownList = await orderService.getSubscribersDropDown();

      setSubscribers(dropdownList?.data?.data || []);
    } catch (error) {
      console.error("Error fetching dropdown list:", error);
    }
  };

  useEffect(() => {
    return () => {
      debouncedLoadOrders.cancel();
    };
  }, [debouncedLoadOrders]);
  useEffect(() => {
    fetchStatusList();
    fetchDropdownList()
    debouncedLoadOrders(urgencyFilter, pagination.current, pagination.pageSize);
  }, [
    urgencyFilter,
    pagination.current,
    pagination.pageSize,
    filters,
    selectedSortOrder,
    selectedOption,
  ]);


  const handleSearchClick = (newFilters) => {
    setFilters(newFilters);
    setPagination({ ...pagination, current: 1 });
  };
  const tabConfig = [
    { key: "all", label: "All Orders", color: "blue", bgColor: "grey" },
    { key: "red", label: "Deadline Missed", color: "red", bgColor: "#FFCCCB" },
    {
      key: "orange",
      label: "Deadline > 1 Day",
      color: "orange",
      bgColor: "#FFE5B4",
    },
    {
      key: "yellow",
      label: "Deadline in 2-4 Days",
      color: "#FACA15",
      bgColor: "#FFF9C4",
    },
    {
      key: "green",
      label: "Deadline > 4 Days",
      color: "green",
      bgColor: "#C8E6C9",
    },
  ];

  const handleChange = (value) => {
    setSelectedOption(value);
  };

  useEffect(() => {
    if (selectedOption === "team-orders" || selectedOption === "my-orders") {
      debouncedLoadOrders(selectedOption);
    }
  }, [selectedOption]);

  return (
    <div className="mainParent">
      {/* Filters Section */}
      {showFilters && (
        <CSSTransition
          in={showFilters}
          timeout={300}
          classNames="filter"
          unmountOnExit>
          <SearchFilters
            filtersList={filtersList}
            onSearch={handleSearchClick}
            loadOrders={handleClearSearch}
            pagination={pagination}
            statusList={statusList}

            fetchingStatusList={fetchingStatusList}
            subscribers={subscribers}
            setSubscribers={setSubscribers}
          />
        </CSSTransition>
      )}


      <Flex gap={4}>
        <Select
          placeholder="Select an action"
          style={{ width: "100%" }}
          disabled={loading}
          value={selectedOption}
          onChange={handleChange}>
          <Option value="my-orders">My Orders</Option>
          {hasPermission("can_view_team_member_orders") && (
            <Option value="team-orders">Team Orders</Option>
          )}
        </Select>
        {hasPermission("can_create_task") && (
          <Button
            type="primary"
            onClick={showModal}
            disabled={loading}
            style={{ background: "#001529", color: "white" }}>
            Add Order
          </Button>
        )}

        <OrderFormParent
          visible={isModalVisible}
          setVisible={setIsModalVisible}
          onClose={closeModal}
          loadOrders={debouncedLoadOrders}
        />
        <Button
          type="primary"
          icon={<FilterOutlined />}
          onClick={toggleFilters}
          style={{ background: "#040724", width: "40px" }}
        />
      </Flex>

      {/* Content Section */}
      {isMobile ? (
        <div style={{ width: "100%" }}>
          <Tabs onChange={handleTabChange} defaultActiveKey="all">
            {tabConfig.map((tab) => (
              <TabPane
                tab={
                  <span
                    style={{
                      color: "black",
                      padding: "8px 16px",
                      borderRadius: "8px 8px 0 0",
                      backgroundColor: tab.bgColor,
                    }}>
                    {tab.label}
                  </span>
                }
                key={tab.key}>
                <Flex
                  vertical
                  align="flex-end"
                  gap={10}
                  style={{ width: "100%" }}>
                  {!loading && (
                    <p style={{ margin: 0, padding: 0 }}>
                      Showing{" "}
                      {Math.min(
                        (pagination.current - 1) * pagination.pageSize + 1,
                        pagination.total
                      )}{" "}
                      -{" "}
                      {Math.min(
                        pagination.current * pagination.pageSize,
                        pagination.total
                      )}{" "}
                      of {pagination.total} Orders.
                    </p>
                  )}

                  <MobileViewComponent
                    data={orders}
                    pagination={pagination}
                    loadOrders={debouncedLoadOrders}
                    setPagination={setPagination}
                    loading={loading}
                  />
                </Flex>
              </TabPane>
            ))}
          </Tabs>
        </div>
      ) : (
        <OrderListTable
          orders={orders}
          loading={loading}
          pagination={!isSearching ? pagination : false}
          // pagination={
          //   isSearching
          //     ? false
          //     : {
          //         current: currentPage,
          //         pageSize: pageSize,
          //         total: total,
          //         showSizeChanger: false,
          //       }
          // }
          onChange={handleTableChange}
          setUrgencyFilter={setUrgencyFilter}
          loadOrders={debouncedLoadOrders}
          setPagination={setPagination}
        />
      )}
    </div>
  );
};

export default Tasks;
