import React, { useState } from "react";
import {
  Modal,
  Upload,
  Button,
  message,
  Form,
  Alert,
  Typography,
  Space,
  Flex,
} from "antd";
import { InboxOutlined, DeleteOutlined } from "@ant-design/icons";
import axiosInstance from "../../services/axiosInstance"; // Adjust the path as needed
import "./Modal.css";
const ImportLeadsModal = ({ isVisible, onClose, fetchData }) => {
  const { Title } = Typography;
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleFileChange = ({ fileList }) => {
    const currentFile = fileList[0]?.originFileObj || null;

    if (currentFile) {
      const isCSVOrExcel =
        currentFile.type === "application/vnd.ms-excel" ||
        currentFile.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        currentFile.name.endsWith(".csv") ||
        currentFile.name.endsWith(".xls") ||
        currentFile.name.endsWith(".xlsx");

      if (!isCSVOrExcel) {
        message.error("You can only upload CSV, XLS, or XLSX files!");
        setFile(null);
      } else {
        setFile(currentFile);
      }
    } else {
      setFile(null);
    }
  };
  const handleDownloadSample = () => {
    // Define the CSV content manually
    const csvContent = `name,email,phone_no,country,lead_tags,source,type`;

    // Create a Blob from the CSV content
    const blob = new Blob([csvContent], { type: "text/csv" });

    // Create a temporary link to trigger the download
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "sample_leads.csv"; // Name of the downloaded file
    link.click();
  };

  const handleDeleteFile = () => {
    setFile(null);
    message.info("File removed.");
  };

  const handleSubmit = async () => {

    if (!file) {
      message.error("Please upload a file before submitting.");
      return;
    }

    setIsLoading(true);

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axiosInstance.post(
        `/lead/v2/import-lead/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.status === 201) {
        message.success("File imported successfully!");

        onClose(); // Close modal after success
        await fetchData();
      } else {
        message.error("Failed to import leads. Please try again.");
      }
    } catch (error) {
      message.error("An error occurred while importing the file.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      className="custom-modal2"
      title={
        <Flex justify="space-between" className="custom-modal-header">
          <Title
            level={3}
            style={{
              color: "white",
              margin: 0,
              position: "relative",
              marginLeft: "10px",
            }}>
            Import Leads
          </Title>
          {/* <CloseOutlined style={{ fontSize: "16px", marginRight: "10px" }} /> */}
        </Flex>
      }
      visible={isVisible}
      onCancel={onClose}
      width={800}
      height={500}
      footer={
        <Button
          style={{ marginTop: "2rem", width: "max-content" }}
          onClick={handleSubmit}
          loading={isLoading}
          type="primary"
          htmlType="submit"
          block>
          Submit
        </Button>
      }>
      <Form layout="vertical">
        <Form.Item>
          <Alert
            message={
              <span>
                Download our{" "}
                <a
                  onClick={handleDownloadSample}
                  style={{
                    color: "#1677ff", // Make it look like a link
                    cursor: "pointer", // Change cursor to pointer to indicate it's clickable
                    textDecoration: "underline", // Underline the text for a link-like appearance
                  }}>
                  Sample CSV File
                </a>{" "}
                to understand the required format for uploading leads. <br />
                Only files in CSV, XLS, or XLSX format are supported
              </span>
            }
            type="info"
            showIcon
          />
        </Form.Item>

        <Form.Item>
          <Upload.Dragger
            name="file"
            accept=".csv,.xls,.xlsx"
            beforeUpload={() => false} // Prevent automatic upload
            onChange={handleFileChange}
            maxCount={1} // Allow only one file
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
          </Upload.Dragger>
          {/* {file && (
            <div style={{ marginTop: 16 }}>
              <Space size="middle">
                <strong>Uploaded File:</strong>
                <span>{file.name}</span>
                <Button
                  type="link"
                  icon={<DeleteOutlined />}
                  danger
                  onClick={handleDeleteFile}>
                  Delete
                </Button>
              </Space>
            </div>
          )} */}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ImportLeadsModal;
