import axiosInstance from "./axiosInstance";
import { catchError } from "../utils/errorHandler";
import { message } from "antd";

const paymentOperationsService = {
  transactionAction: async (payload) => {
    try {
      const response = await axiosInstance.post(
        "/transactions/v2/action/",
        payload
      );
      if (response.status === 200) {
        // message.success("You have successfully logged in!");
        return { success: true, user: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },
  refundAction: async (key, payload) => {
    try {
      const response = await axiosInstance.post(
        `/task/v2/refund/action/${key}/`,
        payload
      );
      if (response.status === 200) {
        // message.success("You have successfully logged in!");
        return { success: true, user: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },


  globalSearch :async (searchQuery, page = 1, pageSize = 10) => {
    const query = new URLSearchParams({
      search: searchQuery,
      page,
      page_size: pageSize,
    }).toString();
  
    try {
      const response = await axiosInstance.get(`/task/v2/global-search/?${query}`);
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      if (error.response && error.response.status === 500) {
        message.error("Internal Server Error");
      }
      await catchError(error);
      return { success: false };
    }
  },
  
  getAllRefunds: async (page = 1, pageSize = 10, filters = {}) => {
    // const {
    //   username,
    //   email,
    //   ownership,
    //   task_count,
    //   website,
    //   inactive_since_last_quarter,
    //   visibility
    // } = filters;
    // const query = new URLSearchParams({
    //   page,
    //   page_size: pageSize,
    //   ...(username && { username }),
    //   ...(email && { email }),
    //   ...(ownership && { ownership }),
    //   ...(task_count && { task_count }),
    //   ...(website && { website }),
    //   ...(visibility && { visibility }),
    //   ...(inactive_since_last_quarter && { inactive_since_last_quarter }),
    // }).toString();
    const {
      key,
      status,
      refund_mode,
      payment_mode,

      start_date,
      end_date,
    } = filters;
    const query = new URLSearchParams({
      page,
      page_size: pageSize,
      ...(refund_mode && { refund_mode }),

      ...(payment_mode && { payment_mode }),
      ...(status && { status }),

      ...(key && { key }),
      ...(start_date && { start_date }),
      ...(end_date && { end_date }),
    }).toString();
    try {
      const response = await axiosInstance.get(
        `/task/v2/refund-list/all/?${query}`
      );
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      if (error.response && error.response.status === 500) {
        message.error("Internal Server Error");
      }
      await catchError(error);
      return { success: false };
    }
  },
  getMyRefunds: async (page = 1, pageSize = 10, filters = {}) => {
    // const {
    //   username,
    //   email,
    //   ownership,
    //   task_count,
    //   website,
    //   inactive_since_last_quarter,
    //   visibility
    // } = filters;
    // const query = new URLSearchParams({
    //   page,
    //   page_size: pageSize,
    //   ...(username && { username }),
    //   ...(email && { email }),
    //   ...(ownership && { ownership }),
    //   ...(task_count && { task_count }),
    //   ...(website && { website }),
    //   ...(visibility && { visibility }),
    //   ...(inactive_since_last_quarter && { inactive_since_last_quarter }),
    // }).toString();
    const {
key,
      status,
      refund_mode,
      payment_mode,

      start_date,
      end_date,
    } = filters;
    const query = new URLSearchParams({
      page,
      page_size: pageSize,
      ...(refund_mode && { refund_mode }),

      ...(payment_mode && { payment_mode }),
      ...(status && { status }),

      ...(key && { key }),
      ...(start_date && { start_date }),
      ...(end_date && { end_date }),
    }).toString();
    try {
      const response = await axiosInstance.get(
        `/task/v2/refund-list/my/?${query}`
      );
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      if (error.response && error.response.status === 500) {
        message.error("Internal Server Error");
      }
      await catchError(error);
      return { success: false };
    }
  },
  createTransaction: async (payload) => {
    try {
      const response = await axiosInstance.post(
        "/transactions/v2/create/",
        payload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.status === 201) {
        // message.success("You have successfully logged in!");
        return { success: true, user: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },
  createRefund: async (payload) => {
    try {
      const response = await axiosInstance.post(
        "/task/v2/refund/transaction/create/",
        payload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.status === 201) {
        // message.success("You have successfully logged in!");
        return { success: true, user: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },
  creditToWallet: async (payload) => {
    try {
      const response = await axiosInstance.post("/wallet/v2/credit/", payload);
      if (response.status === 200) {
        // message.success("You have successfully logged in!");
        return { success: true, user: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },
  deibitFromWallteWallet: async (payload) => {
    try {
      const response = await axiosInstance.post("/wallet/v2/debit/", payload);
      if (response.status === 200) {
        // message.success("You have successfully logged in!");
        return { success: true, user: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },
  transactionComment: async (payload, transId) => {
    try {
      const response = await axiosInstance.post(
        `/transactions/v2/comments/${transId}/`,
        payload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.status === 201) {
        // message.success("You have successfully logged in!");
        return { success: true, user: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },
  deleteRefund: async (transId) => {
    try {
      const response = await axiosInstance.delete(
        `/task/v2/refund-list/my/?uid=${transId}`
      );
      if (response.status === 200) {
        // message.success("You have successfully logged in!");
        return { success: true, user: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },
  getWalletList: async (page = 1, pageSize = 10, filters = {}) => {
    // Check if the search input is an email
    const { search } = filters; // Only 'search' is needed now

    const query = new URLSearchParams({
      page,
      page_size: pageSize,
      ...(search && { search }), // Add search if it exists
    }).toString();

    try {
      const response = await axiosInstance.get(`/wallet/v2/all/?${query}`);
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      if (error.response && error.response.status === 500) {
        message.error("Internal Server Error");
      }
      await catchError(error);
      return { success: false };
    }
  },

  getPaymentModes: async () => {
    try {
      const response = await axiosInstance.get("/api/v2/payment-modes/");
      if (response.status === 200) {
        // message.success("You have successfully logged in!");
        return { success: true, user: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },

  getTransactioncCsv: async () => {
    try {
      const response = await axiosInstance.get("/transactions/v2/all-transactions/?download=true/");
      if (response.status === 200) {
        // message.success("You have successfully logged in!");
        return { success: true, user: response.data };
      }
      return { success: false };
    } catch (error) {
      console.log(error , 'err')
      await catchError(error);
      return { success: false };
    }
  },
  // getTransactioncCsv: async () => {
  //   try {
  //     const response = await axiosInstance.get(`/transactions/v2/all-transactions/?download=true`);
  //     if (response.status === 200) {
  //       // message.success("You have successfully logged in!");
  //       return { success: true, user: response.data };
  //     }
  //     return { success: false };
  //   } catch (error) {
  //     await catchError(error);
  //     return { success: false };
  //   }
  // },
  getTransactionPaymentModes: async () => {
    try {
      const response = await axiosInstance.get(
        `/api/v2/payment-modes/?type=create_transaction/`
      );
      if (response.status === 200) {
        // message.success("You have successfully logged in!");
        return { success: true, user: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },
  walletDetail: async (key, page = 1, pageSize = 10) => {
    try {
      // Ensure page and pageSize are treated as strings in query
      const query = new URLSearchParams({
        page: String(page), // Convert page to string if it's a number
        page_size: String(pageSize), // Convert pageSize to string if it's a number
      }).toString();

      console.log("Generated query string:", query); // Debugging log

      const response = await axiosInstance.get(
        `/wallet/v2/detail/${key}/?${query}`
      );

      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      if (error.response && error.response.status === 500) {
        message.error("Internal Server Error");
      }
      await catchError(error);
      return { success: false };
    }
  },
  getFlDeductionData: async (uid) => {
    try {
      const response = await axiosInstance.get(
        `/task/v2/refund/fl/deduction/${uid}`
      );
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },
  postFlDeductionData: async (uid, payload) => {
    try {
      const response = await axiosInstance.post(
        `/task/v2/refund/fl/deduction/${uid}/`,
        payload
      );
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },
};

export default paymentOperationsService;
