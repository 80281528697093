import React, { useState } from "react";
import {
  Table,
  Pagination,
  Spin,
  Button,
  Input,
  Form,
  Modal,
  Typography,
  message,
  Flex,
  Popover,
  Card,
  Descriptions,
  Empty,
} from "antd";
import moment from "moment";
import { PlusCircleFilled } from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";
import clientService from "../../services/clientService";
import usePermissions from "../../hooks/usePermissions";
const MobileViewReferral = ({
  referrals,
  showModal,
  pagination,
  setPagination,
}) => {
  const { hasPermission } = usePermissions();
  return (
    <>
      {referrals.length === 0 ? (
        <div
          style={{
            textAlign: "center",
            padding: "20px 0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "70vh",
          }}>
          <Empty description="No data" />
        </div>
      ) : (
        <>
          {referrals.map((record) => {
            // Extracting all keys from the referral record
            const recordKeys = Object.keys(record).filter(
              (key) =>
                key !== "refer_id" &&
                key !== "referred_on" &&
                key !== "contact_no" &&
                key !== "status"
            );
            // Ensure Remarks comes last by manually handling it
            const remarksKey = "remarks";
            if (!recordKeys.includes(remarksKey)) {
              recordKeys.push(remarksKey);
            }

            return (
              <Card key={record.refer_id} style={{ marginBottom: 16 }}>
                <Descriptions bordered column={1} size="small">
                  {recordKeys.map((key) => {
                    // Custom rendering for each dynamic field
                    if (key === "client_name") {
                      return (
                        <Descriptions.Item label="Client Name" key={key}>
                          {record[key]}
                          <br />
                          <small style={{ color: "gray" }}>
                            {moment(record.referred_on)
                              .local()
                              .format("DD-MM-YYYY hh:mm a")}
                          </small>
                        </Descriptions.Item>
                      );
                    }

                    if (key === "client_username") {
                      return (
                        <Descriptions.Item label="Client Username" key={key}>
                          {record[key]}
                          <br />
                          <small style={{ color: "gray" }}>
                            {record.contact_no}
                          </small>
                        </Descriptions.Item>
                      );
                    }

                    if (key === "referred_by") {
                      return (
                        <Descriptions.Item label="Referred By" key={key}>
                          {record[key]}
                        </Descriptions.Item>
                      );
                    }

                    if (key === "client_email") {
                      return (
                        <Descriptions.Item label="Client's Email" key={key}>
                          {record[key]}
                        </Descriptions.Item>
                      );
                    }

                    // Handle Remarks specially
                    if (key === "remarks") {
                      return (
                        <Descriptions.Item label="Remarks" key={key}>
                          {
                            record[key] ? (
                              <Popover
                                content={<div>{record[key]}</div>}
                                title="Remarks"
                                trigger="hover">
                                <Button type="link" style={{ padding: 0 }}>
                                  View
                                </Button>
                              </Popover>
                            ) : hasPermission("can_add_referral_remarks") ? (
                              <Button
                                type="link"
                                style={{ padding: 0 }}
                                onClick={() => showModal(record.refer_id)}>
                                <PlusCircleFilled />
                              </Button>
                            ) : (
                              <Button
                                type="link"
                                style={{ padding: 0, opacity: 0.5 }} // Decrease opacity
                                disabled>
                                <PlusCircleFilled />
                              </Button>
                            )
                            // <Button
                            //   type="link"
                            //   style={{ padding: 0 }}
                            //   onClick={() => showModal(record.refer_id)}>
                            //   <PlusCircleFilled />
                            // </Button>
                          }
                        </Descriptions.Item>
                      );
                    }

                    // Default handling for other fields
                    return (
                      <Descriptions.Item
                        label={key.replace("_", " ").toUpperCase()}
                        key={key}>
                        {record[key]}
                      </Descriptions.Item>
                    );
                  })}
                </Descriptions>
              </Card>
            );
          })}
          {/* Pagination Component */}
          <Pagination
            current={pagination.current}
            pageSize={pagination.pageSize}
            total={pagination.total}
            onChange={(page, pageSize) =>
              setPagination({ ...pagination, current: page, pageSize })
            }
            style={{ textAlign: "center", marginTop: 16 }}
          />
        </>
      )}
    </>
  );
};

function Referrals({
  referrals,
  loading,
  pagination,
  setPagination,
  getAllReferrals,
  getClientReferrals,
  viewMode,
  getSupportReferrals,
}) {
  const { hasPermission } = usePermissions();
  const [remarks, setRemarks] = useState(""); // Track remarks per record
  const [isModalVisible, setIsModalVisible] = useState(false); // State to control modal visibility
  const [currentRecordId, setCurrentRecordId] = useState(null); // Track the current record for modal
  const [submittingRemarks, setSubmittingRemarks] = useState(false);
  const { Title } = Typography;
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const handleSaveRemarks = async (recordId) => {
    setSubmittingRemarks(true);
    // Save logic (for now, just update local state)
    // You can replace this with API call to save remarks

    const data = {
      remarks,
    };
    // Close popover after saving
    try {
      const response = await clientService.addReferralRemarks(recordId, data);
      if (response.success) {
        setSubmittingRemarks(false);
        message.success("Remarks added successfully");
        setIsModalVisible(false);
        if (viewMode === "allReferrals") {
          await getAllReferrals();
        } else if (viewMode === "clientReferrals") await getClientReferrals();
        else if (viewMode === "supportReferrals") {
          await getSupportReferrals();
        }
      }
    } catch (error) {
      setSubmittingRemarks(false);
      message.error("Failed to save remarks");
    }
  };

  const showModal = (recordId) => {
    setCurrentRecordId(recordId);
    setIsModalVisible(true);
  };

  const columns = React.useMemo(() => {
    if (referrals && referrals.length > 0) {
      const dynamicColumns = Object.keys(referrals[0])
        .filter(
          (key) =>
            key !== "refer_id" &&
            key !== "referred_on" &&
            key !== "contact_no" &&
            key !== "status" &&
            key !== "remarks" // Exclude remarks for now
        )
        .map((key) => {
          if (key === "client_name") {
            return {
              title: "Client Name",
              dataIndex: "client_name",
              key: "client_name",
              render: (text, record) => (
                <>
                  {text}
                  <br />
                  <small style={{ color: "gray" }}>
                    {moment(record.referred_on)
                      .local()
                      .format("DD-MM-YYYY hh-mm a")}
                  </small>
                </>
              ),
            };
          }

          if (key === "client_username") {
            return {
              title: "Client Username",
              dataIndex: "client_username",
              key: "client_username",
              render: (text, record) => (
                <>
                  {text}
                  <br />
                  <small style={{ color: "gray" }}>{record.contact_no}</small>
                </>
              ),
            };
          }

          if (key === "referred_by") {
            return {
              title: "Referred By",
              dataIndex: "referred_by",
              key: "referred_by",
            };
          }

          if (key === "client_email") {
            return {
              title: "Client's Email",
              dataIndex: "client_email",
              key: "client_email",
            };
          }

          if (key === "status") {
            return {
              title: "Status",
              dataIndex: "status",
              key: "status",
            };
          }

          // Default case for other dynamic columns
          return {
            title: key.replace("_", " ").toUpperCase(),
            dataIndex: key,
            key,
          };
        });
      // Add the Remarks column at the end
      const remarksColumn = {
        title: "Remarks",
        dataIndex: "remarks",
        key: "remarks",
        render: (text, record) => (
          <>
            {text ? (
              <Popover
                content={<div>{text}</div>}
                title="Remarks"
                trigger="hover"
                placement="topLeft">
                <Button type="link" style={{ padding: 0 }}>
                  View
                </Button>
              </Popover>
            ) : hasPermission("can_add_referral_remarks") ? (
              <Button
                type="link"
                style={{ padding: 0 }}
                onClick={() => showModal(record.refer_id)}>
                <PlusCircleFilled />
              </Button>
            ) : (
              <Button
                type="link"
                style={{ padding: 0, opacity: 0.5 }} // Decrease opacity
                disabled>
                <PlusCircleFilled />
              </Button>
            )}
          </>
        ),
      };

      return [...dynamicColumns, remarksColumn]; // Append Remarks column
    }
    return [];
  }, [referrals]);

  // Handle page change in pagination
  const handlePageChange = (page, pageSize) => {
    setPagination({ ...pagination, current: page, pageSize });
  };

  return (
    <div>
      {loading ? (
        <Spin size="large" />
      ) : isMobile ? (
        <MobileViewReferral
          referrals={referrals}
          showModal={showModal}
          pagination={pagination}
          setPagination={setPagination}
        />
      ) : (
        <Table
          dataSource={referrals}
          columns={columns}
          rowKey="refer_id"
          pagination={{
            current: pagination.current,
            pageSize: pagination.pageSize,
            total: pagination.total,
            onChange: (page, pageSize) =>
              setPagination({ ...pagination, current: page, pageSize }),
          }}
        />
      )}
      <Modal
        className="custom-modal2"
        title={
          <Flex justify="space-between" className="custom-modal-header">
            <Title
              level={3}
              style={{
                color: "white",
                margin: 0,
                position: "relative",
                marginLeft: "10px",
              }}>
              Add Remarks
            </Title>
          </Flex>
        }
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        destroyOnClose>
        <Form onFinish={() => handleSaveRemarks(currentRecordId)}>
          <Form.Item
            name="remarks"
            rules={[{ required: true, message: "Please enter remarks" }]}>
            <Input.TextArea
              value={remarks}
              onChange={(e) => setRemarks(e.target.value)}
              placeholder="Enter remarks"
            />
          </Form.Item>
          <Flex align="flex-end" justify="flex-end" style={{ width: "100%" }}>
            <Button
              type="primary"
              htmlType="submit"
              loading={submittingRemarks}>
              Save
            </Button>
          </Flex>
        </Form>
      </Modal>
    </div>
  );
}

export default Referrals;
