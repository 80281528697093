import React from "react";
import { Table } from "antd";

const RefundAnalytics = ({ refundData }) => {
  const columns = [
    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (amount) => `${amount}`,
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={refundData && refundData.length ? refundData : []}
      rowKey="currency"
      pagination={false}
      bordered
      locale={{
        emptyText: refundData && refundData.length ? "No data available for this query" : "No data to display",
      }}
    />
  );
};

export default RefundAnalytics;
