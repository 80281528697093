import React, { useState } from "react";
import { Menu, Modal, Typography } from "antd";
import AssignOwner from "../../components/MyOrderActions/AssignOwner";
import ManageSubscriber from "../../components/MyOrderActions/ManageSubscriber";
import ManageFreelancer from "../../components/MyOrderActions/ManageFreelancer";
import OrderMoveToFreelancerPortal from "../../components/MyOrderActions/OrderMoveToFreelancerPortal";
import OrderMoveToFreelancerAssociates from "../../components/MyOrderActions/OrderMoveToFreelancerAssociates";
import EditCanSendSolutionWithoutPayment from "../../components/MyOrderActions/EditCanSendSolutionWithoutPayment";
import OrderRefundList from "../../components/MyOrderActions/OrderRefundList";
import EditTask from "../../components/MyOrderActions/EditTask";
import EditTaskStatus from "../../components/MyOrderActions/EditTaskStatus";
import Logs from "../../components/MyOrderActions/ManageLogs/Logs";
import AddRefund from "../../components/MyOrderActions/AddRefund";
import SubscriberDeductionsList from "../../components/MyOrderActions/SubscriberDeductionsList";
import CreateSolution from "../../components/MyOrderActions/CreateSolution";
import CreateSolutionParent from "../../components/MyOrderActions/CreateSolutionParent";
import DupliTextParent from "../../components/MyOrderActions/DupliTextParent";
import MarkAsVerified from "../../components/MyOrderActions/MarkAsVerified";
import SendFilesToClient from "./SendFilesToClient";
import MarkAsCompleteFlTask from "../../components/MyOrderActions/MarkAsCompleteFlTask";
import usePermissions from "../../hooks/usePermissions";
import SubscriberDeductions from "../../components/MyOrderActions/SubscriberDeductions";
import ApplyBidComp from "../../components/MyOrderActions/ApplyBidComp";
import EditFlTaskStatus from "../../components/MyOrderActions/EditFlTaskStatus";
import EditClientDeadline from "../../components/MyOrderActions/EditClientDeadline";
import RejectTask from "../../components/MyOrderActions/RejectTask";
import EditInternalTaskStatus from "../../components/MyOrderActions/EditInternalTaskStatus";
import FreelancerBudget from "../../components/MyOrderActions/FreelancerBudget";
import MoveToAccRejPage from "../../components/MyOrderActions/MoveToAccRejPage";
import ZipFilesDownload from "../../components/MyOrderActions/ZipFilesDownlaod";
import AddFeedback from "../../components/MyOrderActions/AddFeedback";
import AddTaskFiles from "../../components/MyOrderActions/AddTaskFiles";
import DeleteTaskFiles from "../../components/MyOrderActions/DeleteTaskFiles";
import MarkAsDone from "../../components/MyOrderActions/MarkAsDoneFeedback";
import MarkAsComplete from "../../components/MyOrderActions/MarkAsComplete";
import CopyTask from "../../components/MyOrderActions/CopyTask";
import AddFeedbackFlOrder from "../../components/MyOrderActions/AddFeedbackFlOrder";
import AddTransactionsComp from "../../components/MyOrderActions/AddTransactions";
import AddQuotation from "../../components/MyOrderActions/AddQuotation";
import EditQuotation from "../../components/MyOrderActions/EditQuotation";

const OrderMenu = ({
  activeOrderTab,
  permissions,
  orderData,
  fetchOrderDetails,
  handleTabChange,
  getSolutionFiles,
  getData,
}) => {
  const { hasPermission } = usePermissions();
  const [selectedComponent, setSelectedComponent] = useState(null);
  const [selectedLabel, setSelectedLabel] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalWidth, setModalWidth] = useState(700);
  const [modalKey, setModalKey] = useState(0);
  const { Title } = Typography;

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  const menuOptions = [
    // ASSIGN SECTION
    {
      key: "1",
      label: "Assign Owner",
      tabs: ["Overview"],
      component: (
        <AssignOwner
          handleModalClose={handleModalClose}
          fetchOrderDetails={fetchOrderDetails}
        />
      ),
      permission: "can_view_order_action_assign_owner",
      style: { width: 550 },
    },

    // SUBSCRIBER SECTION - manage subscriber , manage freelancer , deductions and deduction list
    {
      key: "2",
      label: "Manage Subscriber",
      tabs: ["Overview"],
      component: (
        <ManageSubscriber
          permissions={permissions}
          orderData={orderData}
          fetchOrderDetails={fetchOrderDetails}
        />
      ),
      permission: "can_view_order_action_subscriber_list",
      style: { width: 1200 },
    },
    {
      key: "3",
      label: "Manage Freelancer",
      tabs: ["Overview"],
      component: (
        <ManageFreelancer
          permissions={permissions}
          fetchOrderDetails={fetchOrderDetails}
        />
      ),
      permission: "can_view_order_action_fl_subscriber_list",
      style: { width: 1200 },
    },
    {
      key: "4",
      label: "Subscriber Deductions",
      tabs: ["Overview"],
      component: <SubscriberDeductions handleModalClose={handleModalClose} />,
      permission: "can_view_order_action_subscriber_deduction",
      style: { width: 500 },
    },
    {
      key: "5",
      label: "Subscriber Deductions List",
      tabs: ["Overview"],
      component: <SubscriberDeductionsList />,
      permission: "can_view_order_action_subscriber_deduction_list",
      style: { width: 1200 },
    },

    // TASK SECTION - Apply Bids , Edit Task , Add Task files
    {
      key: "6",
      label: "Apply Bid",
      tabs: ["Overview"],
      component: (
        <ApplyBidComp
          handleModalClose={handleModalClose}
          fetchOrderDetails={fetchOrderDetails}
        />
      ),
      permission: "can_view_order_action_apply_bid",
      style: { width: 500 },
    },
    {
      key: "7",
      label: "Edit Task",
      tabs: ["Overview"],
      component: (
        <EditTask
          handleModalClose={handleModalClose}
          orderData={orderData}
          fetchOrderDetails={fetchOrderDetails}
        />
      ),
      permission: "can_edit_task",
      style: { width: 800, height: 500 },
    },
    {
      key: "8",
      label: "Add Task Files",
      tabs: ["Overview"],
      component: (
        <AddTaskFiles
          handleModalClose={handleModalClose}
          fetchOrderDetails={fetchOrderDetails}
        />
      ),
      permission: "can_view_order_action_add_task_files",
      style: { width: 500 },
    },
    {
      key: "9",
      label: "Copy Task",
      tabs: ["Overview"],
      component: (
        <CopyTask
          handleModalClose={handleModalClose}
          orderData={orderData}
          fetchOrderDetails={fetchOrderDetails}
        />
      ),
      permission: "can_view_order_action_copy_task",
      style: { width: 800 },
    },
    {
      key: "10",
      label: "Delete Files",
      tabs: ["Overview"],
      component: <DeleteTaskFiles fetchOrderDetails={fetchOrderDetails} />,
      permission: "can_view_order_action_delete_files",
      style: { width: 800 },
    },
    {
      key: "11",
      label: "Edit Client Deadline",
      tabs: ["Overview"],
      component: (
        <EditClientDeadline
          orderData={orderData}
          handleModalClose={handleModalClose}
          fetchOrderDetails={fetchOrderDetails}
        />
      ),
      permission: "can_view_order_action_edit_client_deadline",
      style: { width: 500 },
    },
    {
      key: "12",
      label: "Edit Task Status",
      tabs: ["Overview"],
      component: (
        <EditTaskStatus
          orderData={orderData}
          handleModalClose={handleModalClose}
          fetchOrderDetails={fetchOrderDetails}
        />
      ),
      permission: "can_view_order_action_edit_task_status",
      style: { width: 500 },
    },
    {
      key: "13",
      label: "Edit Freelancer Status",
      tabs: ["Overview"],
      component: (
        <EditFlTaskStatus
          orderData={orderData}
          handleModalClose={handleModalClose}
          fetchOrderDetails={fetchOrderDetails}
        />
      ),
      permission: "can_view_order_action_edit_fl_status",
      style: { width: 500 },
    },
    {
      key: "14",
      label: "Reject Task",
      tabs: ["Overview"],
      component: (
        <RejectTask
          handleModalClose={handleModalClose}
          fetchOrderDetails={fetchOrderDetails}
        />
      ),
      permission: "can_view_order_action_reject_task",
      style: { width: 500 },
    },

    // SOLUTION SECTION - Add Solution , Mark As Verified , Mark as Complete FL Task and Send Selected Solution Files to Client
    {
      key: "15",
      label: "Add Solution",
      tabs: ["SolutionFiles"],
      component: (
        <CreateSolutionParent
          permission={permissions}
          setIsModalVisible={setIsModalVisible}
          getSolutionFiles={getSolutionFiles}
        />
      ),
      permission: "can_view_order_action_add_solution",
      style: { width: 800, height: 500 },
    },
    {
      key: "16",
      label: "Mark as Verified",
      tabs: ["SolutionFiles"],
      component: (
        <MarkAsVerified
          permission={permissions}
          setIsModalVisible={setIsModalVisible}
          handleModalClose={handleModalClose}
        />
      ),
      permission: "can_view_order_action_mark_as_verified",
      style: { width: 800, height: 500 },
    },
    orderData?.data?.moved_to_freelancer_portal
      ? [
          {
            key: "17",
            label: "Mark as Complete FL Task",
            tabs: ["SolutionFiles"],
            component: (
              <MarkAsCompleteFlTask
                permission={permissions}
                setIsModalVisible={setIsModalVisible}
                handleModalClose={handleModalClose}
              />
            ),
            permission: "can_view_order_action_mark_as_fl_completed",
            style: { width: 800, height: 500 },
          },
        ]
      : [],
    {
      key: "18",
      label: "Send Selected Solution Files to Client",
      tabs: ["SolutionFiles"],
      component: (
        <SendFilesToClient
          permission={permissions}
          setIsModalVisible={setIsModalVisible}
        />
      ),
      permission: "can_view_order_action_send_solution_files_to_client",
      style: { width: 800, height: 500 },
    },
    {
      key: "19",
      label: "Mark as Complete",
      tabs: ["SolutionFiles"],
      component: (
        <MarkAsComplete
          permission={permissions}
          setIsModalVisible={setIsModalVisible}
          handleModalClose={handleModalClose}
        />
      ),
      permission: "can_view_order_action_mark_as_completed",
      style: { width: 800, height: 500 },
    },

    // ------ //
    {
      key: "20",
      label: "ZIP Files Download",
      tabs: ["Overview"],
      component: (
        <ZipFilesDownload
          orderData={orderData}
          handleModalClose={handleModalClose}
        />
      ),
      permission: "can_view_order_action_zip_files",
    },
    {
      key: "21",
      label: "Add Internal Status",
      tabs: ["Overview"],
      component: (
        <EditInternalTaskStatus
          fetchOrderDetails={fetchOrderDetails}
          handleModalClose={handleModalClose}
          orderData={orderData}
        />
      ),
      permission: "can_view_order_action_internal_status",
      style: { width: 500 },
    },
    {
      key: "22",
      label: "Freelancer Budget",
      tabs: ["Overview"],
      component: (
        <FreelancerBudget
          fetchOrderDetails={fetchOrderDetails}
          handleModalClose={handleModalClose}
        />
      ),
      permission: "can_view_order_action_freelancer_budget",
      style: { width: 500 },
    },
    {
      key: "23",
      label: "Move to Accept Reject Page",
      tabs: ["Overview"],
      component: <MoveToAccRejPage handleModalClose={handleModalClose} />,
      permission: "can_view_order_action_move_to_accept_reject",
      style: { width: 500 },
    },

    // ACTIONS SECTION
    {
      key: "24",
      label: "Order Move to Freelancer Portal",
      tabs: ["Overview"],
      component: (
        <OrderMoveToFreelancerPortal
          orderData={orderData}
          handleModalClose={handleModalClose}
          fetchOrderDetails={fetchOrderDetails}
        />
      ),
      permission: "can_view_order_action_move_to_freelancer_portal",
      style: { width: 550 },
    },
    {
      key: "25",
      label: "Order Move to Freelancer Associates",
      tabs: ["Overview"],
      component: (
        <OrderMoveToFreelancerAssociates
          handleModalClose={handleModalClose}
          fetchOrderDetails={fetchOrderDetails}
        />
      ),
      permission: "can_view_order_action_move_to_freelancer_associate",
      style: { width: 550 },
    },
    {
      key: "26",
      label: "Edit can send solution without Payment",
      tabs: ["Overview"],
      component: (
        <EditCanSendSolutionWithoutPayment
          handleModalClose={handleModalClose}
          fetchOrderDetails={fetchOrderDetails}
        />
      ),
      permission: "can_view_order_action_can_send_solution_without_payment",
      style: { width: 550 },
    },
    {
      key: "27",
      label: "Add Refund",
      tabs: ["Overview"],
      component: (
        <AddRefund
          handleModalClose={handleModalClose}
          fetchOrderDetails={fetchOrderDetails}
        />
      ),
      permission: "can_view_order_action_add_refund",
      style: { width: 800 },
    },
    {
      key: "28",
      label: "Order Refund List",
      tabs: ["Overview"],
      component: <OrderRefundList />,
      permission: "can_view_order_action_order_refund_list",
      style: { width: 1200 },
    },

    // LOGS SECTION
    {
      key: "29",
      label: "Logs",
      tabs: ["Overview"],
      component: <Logs permissions={permissions} />,
      permission: "can_view_order_action_logs",
      style: { width: 1400 },
    },

    // FEEDBACK SECTION
    {
      key: "30",
      label: "Add Feedback",
      tabs: ["Feedback"],
      component: (
        <AddFeedback
          handleTabChange={handleTabChange}
          orderData={orderData}
          handleModalClose={handleModalClose}
        />
      ),
      permission: "can_view_order_action_add_feedback",
      style: { width: 800 },
    },
    {
      key: "31",
      label: "Add Feedback Fl Order",
      tabs: ["Feedback"],
      component: (
        <AddFeedbackFlOrder
          handleTabChange={handleTabChange}
          orderData={orderData}
          handleModalClose={handleModalClose}
          getData={getData}
        />
      ),
      permission: "can_view_order_action_add_feedback_freelancer_order",
      style: { width: 800 },
    },
    // Show Add Transaction if payment has some value
    orderData?.data?.payment && Object.keys(orderData?.data?.payment).length > 0
      ? {
          key: "34",
          label: "Add Transaction",
          tabs: ["Overview"],
          component: (
            <AddTransactionsComp
              orderData={orderData}
              handleModalClose={handleModalClose}
              fetchOrderDetails={fetchOrderDetails}
            />
          ),
          permission: "can_view_order_action_add_transaction",
          style: { width: 800 },
        }
      : null,
    // Show Add Quotation if payment has no value
    !orderData?.data?.payment ||
    Object.keys(orderData?.data?.payment).length === 0
      ? {
          key: "32",
          label: "Add Quotation",
          tabs: ["Overview"],
          component: (
            <AddQuotation
              orderData={orderData}
              handleModalClose={handleModalClose}
              fetchOrderDetails={fetchOrderDetails}
              // getData={getData}
            />
          ),
          permission: "can_view_order_action_add_quotation",
          style: { width: 800 },
        }
      : null,
    // Show Edit Quotation if payment has some value
    orderData?.data?.payment && Object.keys(orderData?.data?.payment).length > 0
      ? {
          key: "33",
          label: "Edit Quotation",
          tabs: ["Overview"],
          component: (
            <EditQuotation
              orderData={orderData}
              handleModalClose={handleModalClose}
              fetchOrderDetails={fetchOrderDetails}
              // getData={getData}
            />
          ),
          permission: "can_view_order_action_edit_quotation",
          style: { width: 800 },
        }
      : null,
  ];

  const handleMenuClick = (option) => {
    setSelectedComponent(option.component);
    setSelectedLabel(option.label);
    setIsModalVisible(true);
    setModalWidth(option?.style?.width);
    setModalKey((prevKey) => prevKey + 1);
  };

  const filteredMenuOptions = menuOptions.filter((option) => {
    const hasPermission =
      !option?.permission || permissions?.includes(option?.permission);
    return option?.tabs?.includes(activeOrderTab) && hasPermission;
  });

  return (
    <>
      <Menu>
        {filteredMenuOptions.map((option) => (
          <Menu.Item key={option.key} onClick={() => handleMenuClick(option)}>
            {option.label}
          </Menu.Item>
        ))}
      </Menu>

      <Modal
        key={modalKey}
        className="custom-modal"
        title={
          <div className="custom-modal-header">
            <Title
              style={{
                color: "white",
                marginLeft: "15px",
                position: "relative",
                fontSize: "clamp(14px, 3vw, 20px)",
                top: "-5px",
                padding: "0px",
              }}
            >
              {selectedLabel}
            </Title>
          </div>
        }
        onCancel={handleModalClose}
        okText="Submit"
        visible={isModalVisible}
        footer={null}
        width={modalWidth}
      >
        <div
          style={{
            maxHeight: "500px",
            overflowX: "hidden",
            overflowY: "auto",
          }}
        >
          {selectedComponent}
        </div>
      </Modal>
    </>
  );
};

export default OrderMenu;
