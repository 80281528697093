// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.expanded-row {
  border: 2px solid #1890ff; /* Use your desired border color */
  background-color: rgb(241 245 249);
  transition: none!important; /* Ensure no transition/animation */
  
}


.scrollable-container {
  overflow-y: auto;
  max-height: 80vh; /* Adjust as needed for mobile */
}

@media (max-width: 768px) { /* Target mobile screens */
  :where(.css-dev-only-do-not-override-1m16aby).ant-picker-dropdown .ant-picker-panel-container {
    width: 80vw!important; /* Set width to 80% of the viewport width */
    margin-left: auto; /* Center horizontally */
    margin-right: auto;
    overflow: auto; /* Enable scrolling for overflow content */

  }
}
`, "",{"version":3,"sources":["webpack://./src/page-components/Leads/Table.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB,EAAE,kCAAkC;EAC7D,kCAAkC;EAClC,0BAA0B,EAAE,mCAAmC;;AAEjE;;;AAGA;EACE,gBAAgB;EAChB,gBAAgB,EAAE,gCAAgC;AACpD;;AAEA,4BAA4B,0BAA0B;EACpD;IACE,qBAAqB,EAAE,2CAA2C;IAClE,iBAAiB,EAAE,wBAAwB;IAC3C,kBAAkB;IAClB,cAAc,EAAE,0CAA0C;;EAE5D;AACF","sourcesContent":[".expanded-row {\n  border: 2px solid #1890ff; /* Use your desired border color */\n  background-color: rgb(241 245 249);\n  transition: none!important; /* Ensure no transition/animation */\n  \n}\n\n\n.scrollable-container {\n  overflow-y: auto;\n  max-height: 80vh; /* Adjust as needed for mobile */\n}\n\n@media (max-width: 768px) { /* Target mobile screens */\n  :where(.css-dev-only-do-not-override-1m16aby).ant-picker-dropdown .ant-picker-panel-container {\n    width: 80vw!important; /* Set width to 80% of the viewport width */\n    margin-left: auto; /* Center horizontally */\n    margin-right: auto;\n    overflow: auto; /* Enable scrolling for overflow content */\n\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
