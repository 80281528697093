import React, { useState, useEffect } from "react";
import { Form, Input, Select, Button, Col, Row, message } from "antd";
import commonService from "../../services/commonService";
import paymentOperationsService from "../../services/paymentOperationsService"; // Import the service

const { Option } = Select;

function DebitToWallet({ closeModal }) {
  const [form] = Form.useForm();
  const [currencyList, setCurrencyList] = useState([]);
  const [loading, setLoading] = useState(false);

  // Handle Form Submit
  const handleSubmit = async (values) => {
    try {
      setLoading(true);

      // Prepare payload for the debitFromWallet function
      const payload = {
        user: values.user,
        currency: values.currency,
        amount: values.debitAmount,
        key: values.assignmentKey,
      };

      // Call debitFromWallet service
      const response = await paymentOperationsService.deibitFromWallteWallet(
        payload
      );

      if (response.success) {
        setLoading(false);

        message.success("Debit from wallet successful!");
        closeModal();
        form.resetFields();
      } else {
        setLoading(false);

        message.error("Failed to debit from wallet.");
      }
    } catch (error) {
      setLoading(false);

      message.error("An error occurred while processing the request.");
    }
  };

  useEffect(() => {
    const fetchCurrencyList = async () => {
      try {
        const response = await commonService.getCurrencyList();
        if (response.success) {
          setCurrencyList(response?.data?.data?.currency);
        } else {
          message.error("Failed to fetch currency list.");
        }
      } catch (error) {
        message.error("An error occurred while fetching currencies.");
      }
    };

    fetchCurrencyList();
  }, []);

  return (
    <>
      <Form
        style={{ width: "100%" }}
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        initialValues={{
          currency: "USD", // Default currency
        }}>
        <Row gutter={[2, 2]} style={{ width: "100%" }}>
          <Col sm={24} md={12}>
            <Form.Item
              name="user"
              label="User"
              rules={[
                { required: true, message: "Please enter the username or email!" },
              ]}>
              <Input placeholder="Enter username or email" />
            </Form.Item>
          </Col>

          <Col sm={24} md={12}>
            <Form.Item
              name="debitAmount"
              label="Debit Amount"
              rules={[
                { required: true, message: "Please enter the debit amount!" },
            
              ]}>
              <Input type="number" placeholder="Enter debit amount" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[2, 2]} style={{ width: "100%" }}>
          <Col sm={24} md={12}>
            <Form.Item
              name="currency"
              label="Currency"
              rules={[
                { required: true, message: "Please select a currency!" },
              ]}>
              <Select placeholder="Select currency">
                {currencyList.map((currency) => (
                  <Option key={currency} value={currency}>
                    {currency}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col sm={24} md={12}>
            <Form.Item
              name="assignmentKey"
              label="Order ID"
              rules={[
                { required: true, message: "Please enter the assignment key!" },
              ]}>
              <Input placeholder="Enter Order ID" />
            </Form.Item>
          </Col>
        </Row>

        <Row justify="end" align="bottom" style={{ width: "100%" }}>
          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ marginRight: 8 }}>
              Submit
            </Button>
            <Button onClick={() => form.resetFields()}>Reset</Button>
          </Form.Item>
        </Row>
      </Form>
    </>
  );
}

export default DebitToWallet;
