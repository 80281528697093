import React, { useEffect, useState } from "react";
import NotificationList from "../../page-components/Notification/NotificationsList";
import NotificationsTabs from "../../page-components/Notification/NotificationTabs";
import { Flex } from "antd";
import notificationsService from "../../services/notificationService";
const initialNotifications = [
  {
    uid: "b1d1c94a-44ef-4d7b-8981-6e4f04c06c21",
    verb: "Refund has been created for order id K6LXDAG46U",
    timestamp: "2025-01-22T10:00:00Z",
    level: "urgent",
    unread: true,
    data: {
      preview: "Refund of $50 processed",
      file_count: 0,
      redirection: "REFUND",
      uid: "12345",
      order_key: "K6LXDAG46U",
      type: "REFUND",
    },
  },
  {
    uid: "df5c16b4-6d3f-4f35-b8e6-4a9c08358a5e",
    verb: "An internal comment received on order K6LXDAG46U",
    timestamp: "2025-01-22T10:10:00Z",
    level: "urgent",
    unread: true,
    data: {
      preview: "Customer requested expedited shipping",
      file_count: 2,
      redirection: "COMMENT",
      uid: "12346",
      order_key: "K6LXDAG46U",
      type: "Comments",
    },
  },
  {
    uid: "e9c3a487-9b8d-43bc-bde1-30f67f702c58",
    verb: "You are assigned as owner on order id K6LXDAG46U",
    timestamp: "2025-01-22T10:20:00Z",
    level: "urgent",
    unread: true,
    data: {
      preview: "Order requires review",
      file_count: 0,
      redirection: "TASK_DETAIL",
      uid: "12347",
      order_key: "K6LXDAG46U",
      type: "Comments",
    },
  },
  {
    uid: "a6d34c1f-14c1-431b-909e-9c5bb9b5a809",
    verb: "Refund has been created for order id K6LXDAG46U",
    timestamp: "2025-01-22T10:30:00Z",
    level: "urgent",
    unread: true,
    data: {
      preview: "Refund of $30 processed",
      file_count: 1,
      redirection: "REFUND",
      uid: "12348",
      order_key: "K6LXDAG46U",
      type: "Refund",
    },
  },
  {
    uid: "c8d6e9bb-d23a-414f-b762-59c39177dd7f",
    verb: "An internal comment received on order K6LXDAG46U",
    timestamp: "2025-01-22T10:40:00Z",
    level: "urgent",
    unread: true,
    data: {
      preview:
        "We appreciate your interest in this product. Unfortunately, it is currently out of stock due to high demand or supply chain limitations. We are actively working to replenish our inventory, and we encourage you to check back regularly for updates. In the meantime, feel free to explore similar items in our catalog or contact our support team for personalized assistance in finding an alternative. Thank you for your patience and understanding as we work to make this product available again soon.",
      file_count: 1,
      redirection: "COMMENT",
      uid: "12349",
      order_key: "K6LXDAG46U",
      type: "Comments",
    },
  },
  {
    uid: "f2e29b23-8a3d-4f96-91c9-92b7ecb00d90",
    verb: "You are assigned as owner on order id K6LXDAG46U",
    timestamp: "2025-01-22T10:50:00Z",
    level: "urgent",
    unread: true,
    data: {
      preview: "High-priority customer",
      file_count: 0,
      redirection: "TASK_DETAIL",
      uid: "12350",
      order_key: "K6LXDAG46U",
      type: "",
    },
  },
  {
    uid: "d1b24f92-06ab-4cc0-92ae-44b8a1b11022",
    verb: "Refund has been created for order id K6LXDAG46U",
    timestamp: "2025-01-22T11:00:00Z",
    level: "urgent",
    unread: true,
    data: {
      preview: "Refund of $75 processed",
      file_count: 3,
      redirection: "REFUND",
      uid: "12351",
      order_key: "K6LXDAG46U",
      type: "Refund",
    },
  },
  {
    uid: "bbf94073-63e3-4c2b-8b61-b8b1a2a481cb",
    verb: "An internal comment received on order K6LXDAG46U",
    timestamp: "2025-01-22T11:10:00Z",
    level: "urgent",
    unread: true,
    data: {
      preview: "Delay in shipment",
      file_count: 0,
      redirection: "COMMENT",
      uid: "12352",
      order_key: "K6LXDAG46U",
      type: "Comments",
    },
  },
  {
    uid: "0c8b9a4e-ec4c-4d95-9c93-b3e08cfef902",
    verb: "You are assigned as owner on order id K6LXDAG46U",
    timestamp: "2025-01-22T11:20:00Z",
    level: "urgent",
    unread: true,
    data: {
      preview: "Order flagged for review",
      file_count: 0,
      redirection: "TASK_DETAIL",
      uid: "12353",
      order_key: "K6LXDAG46U",
      type: "Comments",
    },
  },
  {
    uid: "3f9b2a84-f82f-4d07-8e61-8c376d63d28a",
    verb: "Refund has been created for order id K6LXDAG46U",
    timestamp: "2025-01-22T11:30:00Z",
    level: "urgent",
    unread: true,
    data: {
      preview: "Refund of $100 processed",
      file_count: 0,
      redirection: "REFUND",
      uid: "12354",
      order_key: "K6LXDAG46U",
      type: "Comments",
    },
  },
];

// Add other notifications here

const Notification = () => {
  const [activeTab, setActiveTab] = useState("all"); // Added "all" as a default tab

  const [showUnread, setShowUnread] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [notifications, setNotifications] = useState(initialNotifications);

  // useEffect(() => {
  //   const getNotifications = async () => {
  //     try {
  //       const response = await notificationsService.getNotificationsList();
  //     if (response.success) {
  //         const fetchedNotifications = response.data.data.results.map((notif) => ({
  //           verb: notif.verb,
  //           uid: notif.uid,
  //           unread: notif.unread,
  //           timestamp: notif.timestamp,
  //           type: notif.level, // Map 'level' to 'type' for consistency
  //           order_key: notif.task, // Assuming `task` represents an order key
  //         }));
  //         setNotifications(fetchedNotifications);
  //       }
  //     } catch (error) {
  //       console.error("Failed to fetch notifications:", error);
  //     }
  //   };

  //   getNotifications();
  // }, []);
  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  const handleMarkAsRead = (id) => {
    setNotifications((prev) =>
      prev.map((notif) =>
        notif.uid === id ? { ...notif, unread: false } : notif
      )
    );
  };

  const handleShowUnreadToggle = () => {
    setShowUnread(!showUnread);
  };
  const handleNotificationClick = (notification) => {
    const { uid } = notification;
    const { type, order_key } = notification.data;
    console.log(type, "type");
    if (type === "Comments" || type === "Feedback" || type === "Solution") {
      window.location.href = `/order-management/my-orders/${order_key}?type=${type}`;
    } else {
      // Default fallback redirection
      window.location.href = `/notifications/${uid}`;
    }
  };

  // const handleNotificationClick = (notification) => {
  //   const { uid,  } = notification;
  //   const { type, order_key } = notification.data;

  //   if (type === "Comments") {
  //     // Redirect to comment route
  //     window.location.href = `/order-management/my-orders/${order_key}?type=${type}`;
  //   } else if (type === "Feedback") {
  //     // Redirect to a reminder-specific page
  //     window.location.href = `/order-management/my-orders/${order_key}?type=${type}`;
  //   } else if (type === "Solution") {
  //     window.location.href = `/order-management/my-orders/${order_key}?type=${type}`;
  //     // Redirect to a reminder-specific page
  //     window.location.href = `/reminders/${uid}`;
  //   } else {
  //     // Default redirection
  //     // window.location.href = `/notifications/${uid}`;
  //   }
  // };

  const filteredNotifications = notifications.filter((notif) => {
    if (activeTab === "all") return showUnread ? notif.unread : true;
    return (
      notif.type.toLowerCase() === activeTab &&
      (showUnread ? notif.unread : true)
    );
  });

  return (
    <Flex
      vertical
      align="flex-start"
      justify="flex-start"
      style={{
        width: "100%",
        margin: "0 auto",
        padding: "20px",

        background: "white",
      }}>
      <NotificationsTabs
        activeTab={activeTab}
        onTabChange={handleTabChange}
        onShowUnreadToggle={handleShowUnreadToggle}
        showUnread={showUnread}
      />
      <NotificationList
        notifications={filteredNotifications}
        onMarkAsRead={handleMarkAsRead}
        onNotificationClick={handleNotificationClick} // Pass down click handler
      />
    </Flex>
  );
};

export default Notification;
