// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ` .bdm-user > .ant-select-selector{
    max-height: 35px!important;
  overflow-y: scroll!important;
}`, "",{"version":3,"sources":["webpack://./src/pages/Bdm Analytics/bdm.css"],"names":[],"mappings":"CAAC;IACG,0BAA0B;EAC5B,4BAA4B;AAC9B","sourcesContent":[" .bdm-user > .ant-select-selector{\n    max-height: 35px!important;\n  overflow-y: scroll!important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
