import React, { useEffect, useState } from "react";
import { Input, Button, Select, DatePicker, Spin, message } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import useIsMobile from "../../hooks/useIsMobile";
import paymentOperationsService from "../../services/paymentOperationsService";
import orderService from "../../services/OrderLists";

const { Option } = Select;

const SearchFilters = ({
  onSearch,
  pagination,
  loadOrders,
  statusList,
  filtersList,
  fetchData,
  setTransactions,
  onCancel,
  paymentModes,
  isloading
}) => {
  const isMobile = useIsMobile();

  // const [paymentModes, setPaymentModes] = useState([]);
  // const [loading, setIsLoading] = useState(false);
  // const initialFilters = filtersList.reduce((acc, filter) => {
  //   acc[filter] = "";
  //   return acc;
  // }, {});

  // useEffect(() => {
  //   const fetchPaymentModes = async () => {
  //     try {
  //       setIsLoading(true);
  //       const response = await paymentOperationsService.getPaymentModes();
  //       console.log("Full Response:", response);

  //       if (response?.success && Array.isArray(response?.user?.data)) {
  //         const modes = response.user.data.map((mode) => ({
  //           value: mode.key,
  //           label: mode.value,
  //         }));
  //         console.log("Transformed Payment Modes:", modes);
  //         setPaymentModes(modes);
  //       } else {
  //         console.error("Invalid response structure:", response);
  //       }
  //       setIsLoading(false);
  //     } catch (error) {
  //       console.error("Error fetching payment modes:", error);
  //       setIsLoading(false);
  //     }
  //   };

  //   fetchPaymentModes();
  // }, []);

  // const initialFilters = filtersList.reduce((acc, filter) => {
  //   acc[filter] = filter === "moved_to_fl" ? undefined : "";
  //   return acc;
  // }, {});

  //   const formattedFilters = Object.keys(filters).reduce((acc, key) => {
  //     if (
  //       filters[key] &&
  //       (key === "deadline_hard" ||
  //         key === "allotted_deadline" ||
  //         key === "client_deadline" ||
  //         filters[key]._isAMomentObject)
  //     ) {
  //       acc[key] = dayjs(filters[key]).format("YYYY-MM-DD");
  //     } else {
  //       acc[key] = filters[key];
  //     }
  //     return acc;
  //   }, {});

  //   onSearch(formattedFilters);
  // };

  const initialFilters = Array.isArray(filtersList)
    ? filtersList.reduce((acc, filter) => {
        acc[filter] = ""; // Default value for each filter
        return acc;
      }, {})
    : {};

  console.log(initialFilters, "initial filters");
  const [filters, setFilters] = useState(initialFilters);

  // const handleSearch = () => {
  //   const formattedFilters = {};

  //   console.log(filters, "@@@@@@@");
  //   // Iterate through filters and only add those with a value
  //   Object.keys(filters).forEach((key) => {
  //     if (filters[key]) {
  //       formattedFilters[key] = filters[key];
  //     }
  //   });

  //   console.log("Formatted Filters:", formattedFilters);
  //   onSearch(formattedFilters);
  // };

  const handleFilterChange = (key, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));
  };
  const handleSearch = () => {
    const formattedStartDate = filters.start_date
      ? dayjs(filters.start_date).format("YYYY-MM-DD") // Formats as ISO string
      : null;

    const formattedCreated = filters.created
      ? dayjs(filters.created).format("YYYY-MM-DD") // Formats as ISO string
      : null;

    const formattedEndDate = filters.end_date
      ? dayjs(filters.end_date).format("YYYY-MM-DD") // Formats as ISO string
      : null;

    onSearch({
      ...filters,

      created: formattedCreated,
      start_date: formattedStartDate,
      end_date: formattedEndDate,
    });
  };
  const handleCancel = async () => {
    setFilters({
      
    });
    await onCancel();
  };
  // const handleCancel = async () => {
    
  //   // Reset all filters to the initial state
  //   const resetFilters = filtersList?.reduce((acc, filter) => {
  //     acc[filter] = ""; // Reset filter value to empty string
  //     return acc;
  //   }, {});

  //   setFilters(resetFilters); // Set the filters to initial reset state
  //   console.log(resetFilters , 'resetfil');
  //   await onCancel(); // Fetch data with the reset filters
  //   if (resetFilters) {
  //     message.success("resetr")
  //     await fetchData();
  //   }
  // };

  const renderFilter = (filter) => {
    // Default filter configuration
    const defaultConfig = {
      component: Input,
      props: {
        placeholder: `Filter by ${filter
          .split("_")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ")}`,
        style: { width: isMobile ? "80vw" : 200 },
        value: filters[filter],
        onChange: (e) => handleFilterChange(filter, e.target.value),
      },
    };

    // Special case configurations
    const filterConfigs = {
      payment_status: {
        component: Select,
        props: {
          placeholder: "Payment Status",
          style: {
            width: isMobile ? "80vw" : 200,
            textAlign: "left",
            position: "relative",
            // left: isMobile ? "0" : "50px",
          },
          value: filters.payment_status || undefined,
          onChange: (value) => handleFilterChange("payment_status", value),
        },
        options: [
          { value: "pending", label: "Pending" },
          { value: "completed", label: "Completed" },
          { value: "failed", label: "Failed" },
        ],
      },
      refund_mode: {
        component: Select,
        props: {
          placeholder: "Filter By Refund Mode",
          style: {
            width: isMobile ? "80vw" : 200,
            textAlign: "left",
            position: "relative",
            // left: isMobile ? "0" : "50px",
          },
          value: filters.refund_mode || undefined,
          onChange: (value) => handleFilterChange("refund_mode", value),
        },
        options: [
          { value: "Wallet", label: "Wallet" },
          { value: "MoneyBack", label: "MoneyBack" },
        ],
      },
      action_taken: {
        component: Select,
        props: {
          placeholder: "Filter By Action Taken",
          style: {
            width: isMobile ? "80vw" : 200,
            textAlign: "left",
            position: "relative",
            // left: isMobile ? "0" : "50px",
          },
          value: filters.action_taken || undefined,
          onChange: (value) => handleFilterChange("action_taken", value),
        },
        options: [
          { value: "approved", label: "Approved" },
          { value: "rejected", label: "Rejected" },
          { value: "pending", label: "Pending" },
        ],
      },
      payment_mode: {
        component: Select,
        props: {
          placeholder: "Payment Modes",
          style: {
            width: isMobile ? "80vw" : 200,
            textAlign: "left",
            position: "relative",
            // left: isMobile ? "0" : "50px",
          },
          value: filters.payment_mode || undefined,
          onChange: (value) => handleFilterChange("payment_mode", value),
          showSearch: true,
          optionFilterProp: "children",
          dropdownRender: (menu) => (
            <div>
              {paymentModes.length > 0 ? (
                menu
              ) : (
                <div style={{ padding: "8px" }}>No Payment Modes Available</div>
              )}
            </div>
          ),
        },
        options: paymentModes.map((mode) => ({
          value: mode.value,
          label: mode.label,
        })),
      },

      status: {
        component: Select,
        props: {
          placeholder: "Status",
          style: {
            width: isMobile ? "80vw" : 200,
            textAlign: "left",
          },
          value: filters.status || undefined,
          onChange: (value) => handleFilterChange("status", value),
          showSearch: true, // Enable search
          optionFilterProp: "children", // Filter options by their label
        },
        options: [
          { value: "pending", label: "Pending" },
          { value: "rejected", label: "Rejected" },
          { value: "accepted", label: "Accepted" },
        ],
      },

      created: {
        component: DatePicker,
        props: {
          style: { width: isMobile ? "80vw" : 200 },
          placeholder: "Filter by Created",
          value: filters.created,
          onChange: (value) => handleFilterChange("created", value),
        },
      },
      start_date: {
        component: DatePicker,
        props: {
          style: { width: isMobile ? "80vw" : 200 },
          placeholder: "Filter by Start Date",
          value: filters.start_date,
          onChange: (value) => handleFilterChange("start_date", value),
        },
      },
      end_date: {
        component: DatePicker,
        props: {
          style: { width: isMobile ? "80vw" : 200 },
          placeholder: "Filter by End Date",
          value: filters.end_date,
          onChange: (value) => handleFilterChange("end_date", value),
        },
      },
    };

    // Get the configuration for this filter, or use default
    const config = filterConfigs[filter] || defaultConfig;
    const Component = config.component;

    // For Select components with options
    if (config.options) {
      return (
        <Component {...config.props}>
          {config.options.map(({ value, label }) => (
            <Option key={value} value={value}>
              {label}
            </Option>
          ))}
        </Component>
      );
    }

    // For other components
    return <Component {...config.props} />;
  };

  return (
    <div style={{ width: "100%" }}>
      <div
        style={{ background: "#eceff1", padding: "20px", borderRadius: "8px" }}>
        {isloading ? (
          <Spin />
        ) : (
          <div
            style={{
              marginBottom: 16,
              display: "flex",
              flexWrap: "wrap",
              gap: "28px",
              // justifyContent: "space-between",
              alignItems: "flex-end",
              width: "100%",
            }}>
            {filtersList?.map((filter) => (
              <div key={filter} style={{}}>
                {renderFilter(filter)}
                {console.log(filtersList, "filters list afetr update")}
                {console.log(filter, "filte list afetr update")}
              </div>
            ))}

            <div
              style={{
                display: "flex",
                gap: "20px",
                justifyContent: "flex-end",
                alignContent: "flex-end",
                alignItems: "flex-end",
                position: "relative",
                right: isMobile ? "0" : "10px",
                flexDirection: isMobile ? "column" : "row",
                width: "100%",
              }}>
              <Button
                type="primary"
                style={{
                  width: isMobile ? "80vw" : 140,
                  background: "#040724",
                }}
                icon={<SearchOutlined />}
                onClick={handleSearch}>
                Search
              </Button>
              <Button
                style={{ width: isMobile ? "80vw" : 140 }}
                type="default"
                onClick={handleCancel}>
                Reset
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchFilters;
