import React, { useState } from "react";
import {
  Table,
  Pagination,
  Spin,
  Button,
  Input,
  Form,
  Modal,
  Typography,
  message,
  Popover,
  Card,
  Descriptions,
} from "antd";
import moment from "moment";
import { PlusCircleFilled, EditOutlined } from "@ant-design/icons";
import clientService from "../../services/clientService";
import { useMediaQuery } from "react-responsive";
import usePermissions from "../../hooks/usePermissions";
function MobileViewAgentList({ agents, showModal, pagination, setPagination }) {
  const { hasPermission } = usePermissions();
  return (
    <div>
      {agents.map((agent) => (
        <Card
          key={agent.refer_id}
          style={{ marginBottom: "1rem" }}
          actions={[
            hasPermission("can_update_become_agent_remarks") && (
              <Button
                type="link"
                icon={<EditOutlined />}
                onClick={() => showModal(agent)}>
                Edit Remarks
              </Button>
            ),
          ]}>
          <Descriptions bordered>
            <Descriptions.Item label="Client Username">
              {agent.full_name}
            </Descriptions.Item>
            <Descriptions.Item label="Client Username">
              {agent.username}
            </Descriptions.Item>
            <Descriptions.Item label="Contact">
              {agent.contact_no}
            </Descriptions.Item>
            <Descriptions.Item label="Contact">
              {agent?.email}
            </Descriptions.Item>
            {/* <Descriptions.Item label="Referred By">
              {agent.referred_by}
            </Descriptions.Item> */}
            {/* <Descriptions.Item label="Status">{agent.status}</Descriptions.Item> */}
            <Descriptions.Item label="Remarks">
              {agent.remarks || "No remarks available"}
            </Descriptions.Item>
            <Descriptions.Item label="Requested At">
              {moment(agent.become_agent_requested_at)
                .local()
                .format("DD-MM-YYYY hh:mm A")}
            </Descriptions.Item>
          </Descriptions>
        </Card>
      ))}
      <Pagination
        current={pagination.current}
        pageSize={pagination.pageSize}
        total={pagination.total}
        onChange={(page, pageSize) =>
          setPagination({ ...pagination, current: page, pageSize })
        }
        style={{ textAlign: "center", marginTop: 16 }}
      />
    </div>
  );
}
function AgentsList({ agents, loading, pagination, setPagination, getAgents }) {
  const { hasPermission } = usePermissions();
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const [remarks, setRemarks] = useState(""); // Track remarks per record
  const [isModalVisible, setIsModalVisible] = useState(false); // State to control modal visibility
  const [currentRecord, setCurrentRecord] = useState(null); // Track the current record for modal
  const [submittingRemarks, setSubmittingRemarks] = useState(false);
  const { Title } = Typography;

  const handleSaveRemarks = async () => {
    if (!currentRecord) return;
    setSubmittingRemarks(true);

    try {
      const data = { remarks, uid: currentRecord.uid };
      const response = await clientService.updateAgentRemarks(data);
      if (response.success) {
        message.success("Remarks updated successfully");
        setIsModalVisible(false);
        setRemarks(""); // Clear remarks
        await getAgents();
      }
    } catch (error) {
      message.error("Failed to update remarks");
    } finally {
      setSubmittingRemarks(false);
      setIsModalVisible(false);
    }
  };

  const showModal = (record) => {
    setCurrentRecord(record);
    setRemarks(record.remarks || ""); // Pre-fill with existing remarks if available
    setIsModalVisible(true);
  };

  const columns = React.useMemo(() => {
    if (agents && agents.length > 0) {
      // Initialize columns based on the agent's keys
      const baseColumns = [
        ...Object.keys(agents[0])
          .filter((key) => key !== "uid" && key !== "become_agent_requested_at")
          .map((key) => {
            if (key === "full_name") {
              return {
                title: "Full Name",
                dataIndex: "client_name",
                key: "client_name",
                render: (text, record) => (
                  <>
                    {record.full_name}
                    <br />
                    <small style={{ color: "gray" }}>
                      {moment(record.become_agent_requested_at)
                        .local()
                        .format("DD-MM-YYYY hh-mm a")}
                    </small>
                  </>
                ),
              };
            }

            if (key === "client_username") {
              return {
                title: "Client Username",
                dataIndex: "client_username",
                key: "client_username",
                render: (text, record) => (
                  <>
                    {text}
                    <br />
                    <small style={{ color: "gray" }}>{record.contact_no}</small>
                  </>
                ),
              };
            }

            if (key === "referred_by") {
              return {
                title: "Referred By",
                dataIndex: "referred_by",
                key: "referred_by",
              };
            }

            if (key === "status") {
              return {
                title: "Status",
                dataIndex: "status",
                key: "status",
              };
            }

            if (key === "remarks") {
              return {
                title: "Remarks",
                dataIndex: key,
                key,
                render: (text) => (
                  <Popover
                    content={<div>{text || "No remarks available"}</div>}
                    title="Remarks"
                    trigger="hover"
                    placement="topLeft">
                    <Button type="link" style={{ padding: 0 }}>
                      View
                    </Button>
                  </Popover>
                ),
              };
            }

            return {
              title: key.replace("_", " ").toUpperCase(),
              dataIndex: key,
              key,
            };
          }),
      ];

      // Conditionally add the Edit Remark column if permission is granted
      if (hasPermission("can_update_become_agent_remarks")) {
        baseColumns.push({
          title: "Edit Remark",
          key: "edit_remark",
          render: (_, record) => (
            <Button
              type="link"
              icon={<EditOutlined />}
              onClick={() => showModal(record)}
            />
          ),
        });
      }

      return baseColumns;
    }
    return [];
  }, [agents, hasPermission]);

  const handlePageChange = (page, pageSize) => {
    setPagination({ ...pagination, current: page, pageSize });
  };

  return (
    <div>
      {loading ? (
        <Spin size="large" />
      ) : isMobile ? (
        <MobileViewAgentList
          agents={agents}
          showModal={showModal}
          pagination={pagination}
          setPagination={setPagination}
        />
      ) : (
        <Table
          dataSource={agents}
          columns={columns}
          rowKey="refer_id"
          pagination={{
            current: pagination.current,
            pageSize: pagination.pageSize,
            total: pagination.total,
            onChange: (page, pageSize) =>
              setPagination({ ...pagination, current: page, pageSize }),
          }}
        />
      )}
      {/* Modal for editing remarks */}
      <Modal
        className="custom-modal2"
        title={
          <div
            className="custom-modal-header"
            style={{ display: "flex", justifyContent: "space-between" }}>
            <Title
              level={3}
              style={{
                color: "white",
                margin: 0,
                position: "relative",
                marginLeft: "10px",
              }}>
              Edit Remarks
            </Title>
          </div>
        }
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={[
          <Button
            key="cancel"
            onClick={() => setIsModalVisible(false)}
            disabled={submittingRemarks}>
            Cancel
          </Button>,
          <Button
            key="save"
            type="primary"
            onClick={handleSaveRemarks}
            loading={submittingRemarks}>
            Save
          </Button>,
        ]}
        destroyOnClose>
        <Form>
          <Form.Item>
            <Input.TextArea
              value={remarks}
              onChange={(e) => setRemarks(e.target.value)}
              placeholder="Enter remarks"
              rows={4}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default AgentsList;
