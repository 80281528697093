import axios from "axios";

import { message } from "antd"; // Assuming you're using Ant Design for notifications
import store from "../redux/store";

const API_BASE_URL = "https://app.ozassignments.com/marketing/api/contact-new-list/";
const API_KEY = "6c99e184e8b6dabf611ccf9db27db46e";
const TYPE_CHOICES_API_URL = "https://app.ozassignments.com/marketing/api/type-choices/";


// Function to fetch type choices
export const getTypeChoices = async () => {
  
  const state = store.getState();
  const user = state.auth.user;

  const config = {
    headers: {

      ...(user?.access_token && { Authorization: `Bearer ${user.access_token}` }),
    },
  };

  try {
    const response = await axios.get(TYPE_CHOICES_API_URL, config);

    if (response.status === 200) {
      return { success: true, data: response.data };
    }

    message.error("Failed to fetch type choices.");
    return { success: false };
  } catch (error) {
    if (error.response?.status === 500) {
      message.error("Internal Server Error");
    } else if (error.response?.status === 401) {
      message.error("Unauthorized. Please log in again.");
    } else {
      message.error("An error occurred while fetching type choices.");
    }
    console.error("API Error:", error);
    return { success: false, error };
  }
};
export const getContactRequests = async (page = 1, pageSize = 10, filters = {}) => {
  const state = store.getState();
  const user = state.auth.user;
  const { start_date, end_date  , type , site} = filters;
    const query = new URLSearchParams({
      page,
      page_size: pageSize,
      ...(start_date && { start_date }),
      ...(end_date && { end_date }),
      ...(type && { type }),
      ...(site && { site }),
    }).toString();
  // Extract filters


  // Configure headers
  const config = {
    headers: {
      "X-API-KEY": API_KEY,
    },
  };

  if (user && user.access_token) {
    config.headers.Authorization = `Bearer ${user.access_token}`;
  }

  try {
    const response = await axios.get(`${API_BASE_URL}?${query}`, config);

    if (response.status === 200) {
      return { success: true, data: response.data };
    }

    return { success: false };
  } catch (error) {
    if (error.response && error.response.status === 500) {
      message.error("Internal Server Error");
    } else {
      message.error("An error occurred while fetching data.");
    }
    console.error("API Error:", error);
    return { success: false, error };
  }
};
export const updateRemarks = async (contactId, remarks) => {
    const state = store.getState();
    const user = state.auth.user;
  
    const url = `https://app.ozassignments.com/marketing/api/add-contact-remarks/${contactId}/`;
  
    const config = {
      headers: {
        "X-API-KEY": API_KEY,
        ...(user?.access_token && { Authorization: `Bearer ${user.access_token}` }),
      },
    };
  
    // const formData = new FormData();
    // formData.append("remarks", remarks);
  
    try {
      const response = await axios.post(url, remarks, config);
  
      if (response.status === 200) {
        // message.success("Remarks updated successfully.");
        return { success: true, data: response.data };
      }
  
      message.error("Failed to update remarks.");
      return { success: false };
    } catch (error) {
      if (error.response?.status === 500) {
        message.error("Internal Server Error");
      } else if (error.response?.status === 401) {
        message.error("Unauthorized. Please log in again.");
      } else {
        message.error("An error occurred while updating remarks.");
      }
      console.error("API Error:", error);
      return { success: false, error };
    }
  };


 